/**
  申し込み用フォーム[Easier]コンポーネント
*/
<template>
  <div id="formEasier" class="form_group">
    <Section class="form_wrap form_list">
      <div class="rap1_boder" id="">
        <Section><h1 class="wrap_title pd_7">Easier</h1></Section>
        <Column class="pd3_parts formEasier">
          <label>動画撮影</label>
          <p class="-attention">※有の場合は安井大侑さん・瀬川文彦さんをメンションつけてください。</p>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="easier_should_movie" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="easier_should_movie" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>プラン</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="easier_plan" value="6ヶ月">
                <span class="">6ヶ月</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="easier_plan" value="12ヶ月">
                <span class="">12ヶ月</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="easier_plan" value="24ヶ月">
                <span class="">24ヶ月</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>ブランドページ</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="Brand_page_production_easier" id="Brand_page_production_yes_easier" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="Brand_page_production_easier" id="Brand_page_production_no_easier" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>PRページ制作代行数</label>
          <p class="-attention">今回作成する数を記入してください。追加ではないので注意</p>
          <Flex class="display_yoko">
            <input type="number" inputmode="numeric" class="required form_txt" placeholder="半角数字で入力" id="PR_pages_produced_easier" @input="(event) => { if (event.target.value < 0) event.target.value = 0 }">
            <span class="chara_bottom">本</span>
          </Flex>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>MEO対策オプション</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="MEO_measures_easier" id="MEO_measures_yes_easier" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="MEO_measures_easier" id="MEO_measures_no_easier" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column id="word_number_easier_column" class="pd3_parts formEasier">
          <label>MEO対策ワード数</label>
          <input type="number" inputmode="numeric" class="required form_txt" placeholder="半角数字で入力" id="word_number_easier" @input="(event) => { if (event.target.value < 1) event.target.value = 1 }">
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>有料広告運用代行サービス</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="advertising_substitution_easier" id="advertising_substitution_yes_easier" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="advertising_substitution_easier" id="advertising_substitution_no_easier" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formEasier" id="paid_advertising_expenses_column_easier">
          <label>有料広告費</label>
          <p class="-attention">※税込でお願いします。</p>
          <Flex class="display_yoko">
            <input type="number" inputmode="numeric" class="required form_txt" placeholder="半角数字で入力" id="paid_advertising_expenses_easier" @input="(event) => { if (event.target.value < 0) event.target.value = 0 }">
            <span class="chara_bottom">円</span>
          </Flex>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label >業界</label>
          <div class="c-select">
            <select name="industry_names_easier" id="industry_names_easier" class="required form_select">
              <option value="" disabled selected>--業種 選択--</option>
            </select>
          </div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>撮影有無</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="shoot_yesNo_easier" id="shoot_yes_easier" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="shoot_yesNo_easier" id="shoot_no_easier" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column id="none_shoot_reason_column" class="pd3_parts formEasier">
          <label>撮影無し理由</label>
          <div><textarea id="none_shoot_reason" class="required form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>契約動機</label>
          <label class="c-choice"><input name="contractReason_easier" class="required" type="checkbox" value="慢性的な集客難の解消"><span>慢性的な集客難の解消</span></label>
          <label class="c-choice"><input name="contractReason_easier" type="checkbox" value="オフライン集客の強化"><span>オフライン集客の強化</span></label>
          <label class="c-choice"><input name="contractReason_easier" type="checkbox" value="オンライン集客の強化"><span>オンライン集客の強化</span></label>
          <label class="c-choice"><input name="contractReason_easier" type="checkbox" value="エリアマーケティングの強化"><span>エリアマーケティングの強化</span></label>
          <label class="c-choice"><input name="contractReason_easier" type="checkbox" value="新規顧客獲得の強化"><span>新規顧客獲得の強化</span></label>
          <label class="c-choice"><input name="contractReason_easier" type="checkbox" value="リピート率が悪く顧客が定着がしないため強化"><span>リピート率が悪く顧客が定着がしないため強化</span></label>
          <label class="c-choice"><input name="contractReason_easier" type="checkbox" value="知名度向上"><span>知名度向上</span></label>
          <label class="c-choice"><input name="contractReason_easier" type="checkbox" value="競合との差別化・ブランドの独自性の確立"><span>競合との差別化・ブランドの独自性の確立</span></label>
          <label class="c-choice"><input name="contractReason_easier" type="checkbox" value="口コミ・レビューの増加"><span>口コミ・レビューの増加</span></label>
          <label class="c-choice"><input name="contractReason_easier" type="checkbox" value="SNSの強化"><span>SNSの強化</span></label>
          <label class="c-choice"><input name="contractReason_easier" type="checkbox" value="現状契約している他社サービスの効果が悪く、見直していきたい"><span>現状契約している他社サービスの効果が悪く、見直していきたい</span></label>
          <label class="c-choice"><input name="contractReason_easier" type="checkbox" value="集客費用のコストパフォーマンスを改善したい"><span>集客費用のコストパフォーマンスを改善したい</span></label>
          <label class="c-choice"><input name="contractReason_easier" type="checkbox" value="集客の専門知識・ノウハウがなく、コンサルしてほしい"><span>集客の専門知識・ノウハウがなく、コンサルしてほしい</span></label>
          <label class="c-choice"><input name="contractReason_easier" type="checkbox" value="新規オープン・リニューアル"><span>新規オープン・リニューアル</span></label>
          <label class="c-choice"><input name="contractReason_easier" type="checkbox" value="期間限定の集客を強化"><span>期間限定の集客を強化</span></label>
          <label class="c-choice"><input name="contractReason_easier" type="checkbox" value="その他"><span>その他</span></label>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>契約動機</label>
          <p class="-attention">（上記選択では共有に足りない内容がある場合や<br>　その他選択をした場合に共有ください）</p>
          <div><textarea id="contract_reason_easier_other" class="form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>顧客人柄</label>
          <p class="-attention">カバーしなければいけない要素を確認させていただきたいです。<br>担当のアサインの際に活用します。</p>
          <label class="c-choice"><input name="persnality_easier" class="required" type="checkbox" value="集客リテラシー高い"><span>集客リテラシー高い</span></label>
          <label class="c-choice"><input name="persnality_easier" type="checkbox" value="集客リテラシー低い"><span>集客リテラシー低い</span></label>
          <label class="c-choice"><input name="persnality_easier" type="checkbox" value="機械操作が苦手"><span>機械操作が苦手</span></label>
          <label class="c-choice"><input name="persnality_easier" type="checkbox" value="非協力的"><span>非協力的</span></label>
          <label class="c-choice"><input name="persnality_easier" type="checkbox" value="こだわり強い"><span>こだわり強い</span></label>
          <label class="c-choice"><input name="persnality_easier" type="checkbox" value="こだわりなし＆任せたい"><span>こだわりなし＆任せたい</span></label>
          <label class="c-choice"><input name="persnality_easier" type="checkbox" value="こだわりなし＆教えてほしい"><span>こだわりなし＆教えてほしい</span></label>
          <label class="c-choice"><input name="persnality_easier" type="checkbox" value="プライド高い・相手を馬鹿にする"><span>プライド高い・相手を馬鹿にする</span></label>
          <label class="c-choice"><input name="persnality_easier" type="checkbox" value="極端な考え方をする・思い込み激しい"><span>極端な考え方をする・思い込み激しい</span></label>
          <label class="c-choice"><input name="persnality_easier" type="checkbox" value="反応薄い"><span>反応薄い</span></label>
          <label class="c-choice"><input name="persnality_easier" type="checkbox" value="高齢・前の話を忘れてしまう"><span>高齢・前の話を忘れてしまう</span></label>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>お客様との会話内容</label>
          <p class="-attention">こういう話題してもらった方がアイスブレイクになるなどがあれば教えてほしいです！<br>
            （例）車好きの代表です。外車の話をしたらすごく盛り上がりました。<br>　　　リテラシーが高いので知見提供し続けたり業界あるある話をして信用を得ると<br>　　　本音を引き出しやすくなりました。など
          </p>
          <div><textarea id="conv_easier" class="required form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>Easierの制作物で載せる内容について何かお客様とお約束したり、<br>お約束していなくても提案して温度感があがったことはありますか？</label>
          <label class="c-choice"><input name="promise_insertion_easier" type="checkbox" value="訴求方針について"><span>訴求方針について</span></label>
          <label class="c-choice"><input name="promise_insertion_easier" type="checkbox" value="運用方針について"><span>運用方針について</span></label>
          <label class="c-choice"><input name="promise_insertion_easier" type="checkbox" value="掲載メディアについて"><span>掲載メディアについて</span></label>
          <label class="c-choice"><input name="promise_insertion_easier" type="checkbox" value="写真や動画について"><span>写真や動画について</span></label>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>上記の詳細</label>
          <div><textarea id="promise_insertion_easier_detail" class="form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>Easierのサービス内容について何かお客様とお約束したり、<br>お約束していなくても提案して温度感があがったことはありますか？</label>
          <label class="c-choice"><input name="promise_service_easier" type="checkbox" value="リリース後の修正について"><span>リリース後の修正について</span></label>
          <label class="c-choice"><input name="promise_service_easier" type="checkbox" value="撮影について"><span>撮影について</span></label>
          <label class="c-choice"><input name="promise_service_easier" type="checkbox" value="運用サポートについて"><span>運用サポートについて</span></label>
          <label class="c-choice"><input name="promise_service_easier" type="checkbox" value="掲載時期について"><span>掲載時期について</span></label>
          <label class="c-choice"><input name="promise_service_easier" type="checkbox" value="キャンペーンについて"><span>キャンペーンについて</span></label>
          <label class="c-choice"><input name="promise_service_easier" type="checkbox" value="集客効果事例について"><span>集客効果事例について</span></label>
          <label class="c-choice"><input name="promise_service_easier" type="checkbox" value="コンテンツマーケティングについて"><span>コンテンツマーケティングについて</span></label>
          <label class="c-choice"><input name="promise_service_easier" type="checkbox" value="追加費用について"><span>追加費用について</span></label>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>上記の詳細</label>
          <div><textarea id="promise_service_easier_detail" class="form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>掲載希望日時（ヒアアポ～の期間・撮影期間を伝えた上で） </label>
          <input type="date" id="date_publication_easier" class="required form_txt">
        </Column>
        <Column class="pd3_parts formEasier">
          <label>他媒体効果などヒアリングできたものがあれば教えて欲しいです</label>
          <div><textarea id="background_customers_easier" class="form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>その他顧客希望</label>
          <div><textarea id="sharing_operators_easier" class="form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>テストマーケのMEO無料運用サービス</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="test_service_easier_yesNo" id="test_service_easier_yes" value="有り">
                <span>有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="test_service_easier_yesNo" id="test_service_easier_no" value="無し">
                <span>無し</span>
              </label>
            </li>
          </ul>
        </Column>
      </div>
    </Section>
  </div>
</template>


<script>
import { defineComponent } from 'vue'

// コンポーネントを読み込む
import Variables from '../../../molecules/variables.vue';
import Choices from '../../../molecules/choices.vue';
import Box from '../../../molecules/box.vue';
import Section from '../../../molecules/section.vue';
import Column from '../../../molecules/column.vue';
import Group from '../../../molecules/group.vue';
import Flex from '../../../molecules/flex.vue';
import Block from '../../../molecules/block.vue';

import Description from '../../../atoms/description.vue';
import Label from '../../../atoms/label.vue';
import Input from '../../../atoms/input.vue';
import Select from '../../../atoms/select.vue';
import Textarea from '../../../atoms/textarea.vue';
import Button from '../../../atoms/button.vue';
import IconAdd from '../../../atoms/icon-add.vue';
import IconRemove from '../../../atoms/icon-remove.vue';

export default defineComponent({
  name: 'formApplicationEasier',

  components: {
    Variables,
    Choices,
    Box,
    Section,
    Column,
    Group,
    Flex,
    Block,

    Description,
    Label,
    Input,
    Select,
    Textarea,
    Button,
    IconAdd,
    IconRemove,
  },

  methods: {
  },

  mounted() {
  },

  setup(_, context) {
    return {}
  }

})
</script>
