/**
  申し込み用フォーム[連絡先情報]コンポーネント
*/
<template>
<div id="formContactInfo" class="form_group">
  <Section id="is_contact_info" class="form_wrap form_list">
    <div class="rap1_boder" id="wrap3">
      <Section><h1 class="wrap_title pd_7">連絡先情報</h1></Section>
      <div class="formAddress">
        <Column class="pd3_parts formAddress">
          <label>主要連絡先</label>
          <p class="-attention">この法人とやりとりする際にどの方とのやりとりが必要になるか教えてください。</p>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" name="mainContact" value="代表のみ" class="required">
                <span class="">代表のみ</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" name="mainContact" value="担当" class="required">
                <span class="">担当</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" name="mainContact" value="特に言及なし" class="required">
                <span class="">特に言及なし</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts">
          <label>社名</label>
          <p class="-attention">契約書に記載の法人名で記載をお願いします。</p>
          <input type="text" id="contactComName" class="required form_txt">
        </Column>
        <Column class="pd3_parts">
          <label>本社住所</label>
          <p class="-attention">契約書に記載の住所で記載をお願いします。</p>
          <input type="text" id="companyAddress" class="required form_txt">
        </Column>
        <Column class="pd3_parts formAddress">
          <label>代表取締役者名</label>
          <p class="-attention">この法人の代表取締役の名前をフルネームで記載をお願いします。</p>
          <input type="text" id="contactNameCEO" class="required form_txt">
        </Column>
        <Column class="pd3_parts">
          <label>代表取締役者名（カナ）</label>
          <p class="-attention">この法人の代表取締役の名前の読み仮名をカタカナで記載をお願いします。</p>
          <input type="text" id="contactNameCEOCana" class="required form_txt">
        </Column>
        <Column class="pd3_parts formAddress">
          <label>代表メールアドレス</label>
          <p class="-attention">この法人の代表取締役とやりとりをする際のメールアドレスの記載をお願いします。</p>
          <input type="text" id="contactMailCEO" class="required form_txt">
        </Column>
        <Column class="pd3_parts formAddress">
          <label>代表電話番号（固定）</label>
          <input type="text" id="contactTelNumCEO" class="required form_txt">
        </Column>
        <Column class="pd3_parts formAddress">
          <label>代表電話番号（携帯）</label>
          <input type="text" id="contactTelephoneCEO" class="required form_txt">
        </Column>
        <Column class="pd3_parts formAddress">
          <label>WEB打ち合わせ可否（代表）</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" name="canOnlineMtgCEO_yesNo" value="可能" class="required">
                <span class="">可能</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" name="canOnlineMtgCEO_yesNo" value="不可能" class="required">
                <span class="">不可能</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" name="canOnlineMtgCEO_yesNo" value="不明" class="required">
                <span class="">不明</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formAddress">
          <label>代表連絡先情報備考</label>
          <p class="-attention">この法人の代表取締役とやりとりをする際に<br>CC追加など特筆する内容があれば記載をお願いします。</p>
          <input type="text" id="specialMention" class="required form_txt">
        </Column>
        <Column id="contactStaff">
          <Column class="pd3_parts formAddress">
            <label>担当者名</label>
            <input type="text" id="contactNameStaff" class="contactStaff form_txt">
          </Column>
          <Column class="pd3_parts formAddress">
            <label>担当者名（カナ）</label>
            <input type="text" id="contactNameStaffCana" class="contactStaff form_txt">
          </Column>
          <Column class="pd3_parts formAddress">
            <label>担当メールアドレス</label>
            <p class="-attention">この法人の担当とやりとりをする際のメールアドレスの記載をお願いします。</p>
            <input type="text" id="contactMailStaff" class="contactStaff form_txt">
          </Column>
          <Column class="pd3_parts formAddress">
            <label>担当電話番号（固定）</label>
            <input type="text" id="contactTelNumStaff" class="contactStaff form_txt">
          </Column>
          <Column class="pd3_parts formAddress">
            <label>担当電話番号（携帯）</label>
            <input type="text" id="contactTelephoneStaff" class="contactStaff form_txt">
          </Column>
          <Column class="pd3_parts formAddress">
            <label>WEB打ち合わせ可否（担当）</label>
            <ul class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="canOnlineMtgStaff_yesNo" value="可能" class="contactStaff_radio">
                  <span class="canOnlineMtgStaffSpan">可能</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="canOnlineMtgStaff_yesNo" value="不可能" class="contactStaff_radio">
                  <span class="canOnlineMtgStaffSpan">不可能</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="canOnlineMtgStaff_yesNo" value="不明" class="contactStaff_radio">
                  <span class="canOnlineMtgStaffSpan">不明</span>
                </label>
              </li>
            </ul>
          </Column>
          <Column class="pd3_parts formAddress">
            <label>担当者の商談同席有無</label>
            <ul class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="canMtgStaff_yesNo" value="同席していた" class="contactStaff_radio">
                  <span class="canMtgStaffSpan">同席していた</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="canMtgStaff_yesNo" value="同席していない" class="contactStaff_radio">
                  <span class="canMtgStaffSpan">同席していない</span>
                </label>
              </li>
            </ul>
          </Column>
          <Column class="pd3_parts formAddress">
            <label>担当連絡先情報備考</label>
            <input type="text" id="contactStaffRemarks" class="contactStaff form_txt">
          </Column>
        </Column>
        <Column class="pd3_parts formAddress progress_display">
          <label>進行可否</label>
          <p class="-attention">※「可」を選択した場合は、進行スタート依頼の投稿は不要です<br>※進行開始日を調整したい場合は、不可を選択ください</p>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" name="progress_yesNo" id="progress_yes" value="可能" class="required">
                <span class="">可能</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" name="progress_yesNo" id="progress_no" value="不可" class="required">
                <span class="">不可</span>
              </label>
            </li>
          </ul>
        </Column>
      </div>
    </div>
  </Section>
</div>
</template>


<script>
import { defineComponent } from 'vue'

// コンポーネントを読み込む
import Variables from '../../../molecules/variables.vue';
import Choices from '../../../molecules/choices.vue';
import Box from '../../../molecules/box.vue';
import Section from '../../../molecules/section.vue';
import Column from '../../../molecules/column.vue';
import Group from '../../../molecules/group.vue';
import Flex from '../../../molecules/flex.vue';
import Block from '../../../molecules/block.vue';

import Description from '../../../atoms/description.vue';
import Label from '../../../atoms/label.vue';
import Input from '../../../atoms/input.vue';
import Select from '../../../atoms/select.vue';
import Textarea from '../../../atoms/textarea.vue';
import Button from '../../../atoms/button.vue';
import IconAdd from '../../../atoms/icon-add.vue';
import IconRemove from '../../../atoms/icon-remove.vue';

export default defineComponent({
  name: 'formApplicationContactInfo',

  components: {
    Variables,
    Choices,
    Box,
    Section,
    Column,
    Group,
    Flex,
    Block,

    Description,
    Label,
    Input,
    Select,
    Textarea,
    Button,
    IconAdd,
    IconRemove,
  },

  methods: {
  },

  mounted() {
  },

  setup(_, context) {
    return {}
  }

})
</script>
