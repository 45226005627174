/*
  アポ獲得報告のフォーム用のデータ取得
*/
import formDataStore from "../../formDataStore"
import RequestBody from "../../requestBody.class"

//シートのダミーデータ
//import sheetData from "./dummySheetData"

export default class {
  constructor(obj) {
    this.formTypeForRequestBody = 'apGain'

    this.parentObj = obj
    this.$ = this.parentObj.parentObj.$
    this.postID = obj.postID
    this.isProd = this.parentObj.isProd

    this.params = obj.params
    this.abortController = new AbortController()
    this.slackChannelID = this.postID.split("_")[0]
    this.demo = this.isProd ? "" : "Demo"
    this.key = formDataStore.state.apis[`loginKey${this.demo}`]
    
    // フォーム情報
    this.thisFormParams = this.parentObj.parentObj.vueObj
    .thisFormParams
    //this.groupURL = this.thisFormParams[1] // talknoteグループURL
    //this.groupID = this.groupURL.split('feed/')[1].split('/')[0] // 

    // 各種API
    this.spreadsheetInsertApi = formDataStore.state.apis[`spreadsheet${this.demo}`]
    this.spreadsheetUpdateApi = formDataStore.state.apis[`spreadsheet${this.demo}`].replace('insert', 'update')
    this.spreadsheetGetApi = formDataStore.state.apis[`spreadsheet${this.demo}`].replace('insert', 'get')
    this.DBInsertApi = formDataStore.state.apis[`dbInsert${this.demo}`]
    this.DBUpdateApi = formDataStore.state.apis[`dbUpdate${this.demo}`]

    this.delPhoneNumberID = []
    this.delOtherPhoneNumberID = []

    this.requestFunc = new RequestBody.requestBody()
    this.nowTime = this.requestFunc.nowTime()
    this.createdAt = this.requestFunc.nowTime('created_at')

    // スプレッドシート用
    this.spreadData = this.requestFunc.setSpreadData(this.formTypeForRequestBody, this.isProd)
    this.spreadData.id = this.spreadData.spreadsheetID
    delete this.spreadData.spreadsheetID
    delete this.spreadData.is_prod
   
  }

  /**
   * データ取得
   * 
      ▼スプレッドシート対象カラム
      11 電話番号
      13 URL 
      55 全文（代表者名をスクレイピングで取得）
      
   */
  async getData() {
    // DBから取得　フォームに適用するのはこのデータ
    this.DBData = await this.getDBData()

    if(this.DBData.length == 0) {
      return false
    }

    this.subjectsID = this.DBData[0].id
    
    // シートから取得　内容を
    await this.getSheetData()

    this.phoneNumberData = await this.getPhoneNumber()
    
    if(this.DBData.length == 0) return false

    return true

  }

  /**
   * アポ獲得報告で編集対象のデータをDBから取得 
   */
  async getDBData() {
  
    const query = `SELECT s.id AS id, s.name AS name, s.postcode AS postcode, s.company_address AS company_address, an.hooks_id AS hooks_id, an.suggestions_id AS suggestions_id, s.sub_cates_id AS sub_cates_id, c.id AS categories_id, s.post_id AS post_id FROM subjects s INNER JOIN ap_negotiations an ON s.id = an.subjects_id LEFT JOIN sub_cates sc ON sc.id = s.sub_cates_id LEFT JOIN categories c ON sc.categories_id = c.id  WHERE s.post_id = '${this.postID}' AND s.deleted_at IS NULL`

    this.params.main_data = {
      tables:["subjects", "ap_negotiations", "sub_cates", "categories"],
      query: query
    }

    return await fetch(formDataStore.state.apis[`dbSelect${this.demo}`], {
      signal: this.abortController.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.params)
    })
    .then(response => response.json())
    .then(response => {
      return response || false
    })
  }

  /**
   * アポ獲得報告で編集対象のデータをスプレッドシートから取得 
   * API出来たら修正
   */
  async getSheetData() {   
    /*const columns = [
      "created_at",
      "updated_at",
      "投稿ID",
      "AP名",
      "アポ種別",
      "使用リスト",
      "アポ獲得日",
      "案件名",
      "カテゴリ",
      "業種",
      "所在地",
      "連絡先電話番号",
      "HP有無",
      "URL",
      "掘り起こし可否",
      "元担当者名",
      "アポ日程",
      "開始時間",
      "終了時間",
      "アポ確保時間",
      "提案金額",
      "提案内容",
      "フック",
      "商談形式",
      "商談住所",
      "最寄り駅",
      "バス",
      "使用デバイス",
      "アウトレット理由",
      "ネック_費用",
      "ネック_時間",
      "ネック_タイミング",
      "ネック_相談者",
      "ネック_保証",
      "ネック_お試し",
      "ネック_データ",
      "ネック_キャパ",
      "ネック_副業",
      "ネック_商談認識",
      "ネック_即決",
      "ネック_web",
      "ネック_事業継承",
      "ネック_支払い方法",
      "ネック_すんなり",
      "ネック_その他",
      "group_id",
      "group_title",
      "group_name",
      "案件ID",
      "user_id",
      "新規見込み",
      "初回見込み日時",
      "所属エリア",
      "所属（課/部）",
      "所属（組）",
      "全文",
      "その他共有事項",
      "代電ID",
      "郵便番号",
      "転送フラグ",
      "その他電話番号",
      "削除日時"
    ]*/

    this.insertSheetData = {}

    this.getSheetDataQuery = {
      "spread": this.spreadData,
      "target_lines": [
        {"column": '投稿ID', "value": this.postID},
        {"column": '削除日時', "value": ''},
      ],
    }
    
    await fetch(this.spreadsheetGetApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        "Content-Type" : "application/json",
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.getSheetDataQuery)
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.length > 0) {
        this.insertSheetDataTmp = res[0]
        console.log('シートデータ取得成功', this.insertSheetDataTmp )

        for(let key in this.insertSheetDataTmp) {
          this.insertSheetData[key] = {"id": "", "val": this.insertSheetDataTmp[key]}
        }
      } else {
        alert('該当するシートデータがありません')
        console.log('該当するシートデータがありません')
        return false
      }
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

    return this.insertSheetData
  }

  /**
   * 複数ある電話番号データ取得 
   */
  async getPhoneNumber() {
   
    const query = `SELECT * FROM subject_phone_numbers WHERE subjects_id = '${this.subjectsID}' AND deleted_at IS NULL`

    this.params.main_data = {
      tables:["subject_phone_numbers"],
      query: query
    }

    return await fetch(formDataStore.state.apis[`dbSelect${this.demo}`], {
      signal: this.abortController.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.params)
    })
    .then(response => response.json())
    .then(response => {
      const fromSheet = this.insertSheetData['連絡先電話番号'].val.split(',')
      const fromSheetOther = this.insertSheetData['その他電話番号'].val.split(',')

    response.forEach((obj, i) => {
      if(i < fromSheet.length) {
        obj.phone_number = fromSheet[i]
      } else {
        obj.other_phone_number = fromSheetOther[i - fromSheet.length]
      }
      return obj
    })

      return response || false
    })
  }

   /**
   * フォームにデータ適用 
   */
  applyDataToForm() {
    const $ = this.$
    this.editableItems = this.parentObj.parentObj.vueObj.editableItems
    /* 編集対象項目
      'company_name', // 案件名
      'ceo_name', // 代表者名 
      'postcode',
      'address_all',
      'tel',
      'hp_url', // データが存在する場合は url_exists の 「有」にチェック
      'hook',
      'suggestion',
      'category',
      'sub_cate'

      DB
        [
            {
                "id": "AP413830_1689924765131",
                "name": "test",
                "negotiation_date": "2023-07-28",
                "postcode": "0000000",
                "company_address": "埼玉県さいたま市",
                "hooks_id": 3,
                "suggestions_id": 3,
                "sub_cates_id": 269,
                "categories_id": 53,
                "post_id": "413830_tn-post-661efef8"
            }
        ]

      
    */
    // 案件名
    this.editableItems.companyName = this.DBData[0].name

    // 代表者名
    const ceoName = this.insertSheetData['全文'].val.match(/代表者名：([^\\\r\\\n]+)/)
    if(ceoName) this.editableItems.ceoName = ceoName[1]

    // 郵便番号
    this.editableItems.postcode = this.DBData[0].postcode

    // 住所
    this.editableItems.address = this.DBData[0].company_address
    console.log(this.phoneNumberData)
    // 電話番号
    const countWithoutOtherPhoneNumber = this.phoneNumberData.filter(data => !data.hasOwnProperty('other_phone_number')).length

    if(countWithoutOtherPhoneNumber > 0) {
      for(let i = 1, n = countWithoutOtherPhoneNumber; i < n; i++) {
        $('#f_tel .btn_add').click()
      }

      this.phoneNumberData.forEach((tel, i) => {
        $('#f_tel .tel').eq(i).attr('data-id', tel.id).val(tel.phone_number)      
      })

      this.getDeletePhoneNumberAction()
    }

    const countOtherPhoneNumber = this.phoneNumberData.filter(data => data.hasOwnProperty('other_phone_number'))
    if(countOtherPhoneNumber.length > 0) {
      for(let i = 1, n = countOtherPhoneNumber.length; i < n; i++) {
        $('#f_other_tel .btn_add').click()
      }

      countOtherPhoneNumber.forEach((tel, i) => {
        if (i == 0) {
          $('#f_other_tel .other_tel_input').eq(i).attr('data-id', tel.id).val(tel.other_phone_number)
        } else {
          $('#f_other_tel .other_tel').eq(i-1).attr('data-id', tel.id).val(tel.other_phone_number)
        }
      })

      this.getDeleteOtherPhoneNumberAction()
    }

    // URL
    let urls = this.insertSheetData['URL'].val
    if(urls) {
      $('[name="url_exists"][value="有"]').prop('checked', true).click()

      urls = urls.split(',')

      urls.forEach((url, i) => {
        if(i == 0) {
          $('#f_hp_url [name="hp_url"]').val(url)
        }

        if(i > 0) {
          $('.copyEle [name="hp_url"]').parent().clone(true).css('display', 'flex').appendTo($('#f_hp_url')).find('[name="hp_url"]').val(url)
        }
      })
    } else {
      $('[name="url_exists"][value="無"]').prop('checked', true).click()
    }

    // フック
    document.querySelector(`[name="hook"][data-id="${this.DBData[0].hooks_id}"]`).click()
    new Promise(() => {})

    // 処理のタイミングを合わせる
    const intervalTime = 50
    setTimeout(() => {
      // 提案内容
      document.querySelector(`[name="suggestion"][data-id="${this.DBData[0].suggestions_id}"]`).click()

      if(this.insertSheetData['提案内容'].val.indexOf('/助成金') > -1) {
        document.querySelector(`[name="suggestion"][data-id="5"]`).click()
      }

      // 名乗り
      document.querySelector(`[name="giving_name"][value="${this.insertSheetData['名乗り'].val}"]`).click()

      setTimeout(() => {
        // カテゴリ
        try {
          document.querySelector(`[name="category"] [data-id="${this.DBData[0].categories_id}"]`).selected = true
        } catch {
          document.querySelector(`[name="category"] [data-id="999"]`).selected = true
        }
        // カテゴリプルダウンに紐づけられたイベント発火
        let changeEvent = new Event('change')
        document.querySelector(`[name="category"]`).dispatchEvent(changeEvent)

        setTimeout(() => {
          // 業種
          try {
            document.querySelector(`[name="sub_cate"] [data-id="${this.DBData[0].sub_cates_id}"]`).selected = true
          } catch {
            document.querySelector(`[name="sub_cate"] [value="その他"]`).selected = true
          }

          this.checkRequired()

        }, intervalTime)
      }, intervalTime)
    }, intervalTime)


  }

  /**
   * 削除した電話番号取得 
   */
  getDeletePhoneNumberAction() {
    const $ = this.$
    $(document).off('click.telDel').on('click.telDel', '#f_tel .btn_del', (e) => {
      const id = $(e.currentTarget).parent().parent().find('.tel').data('id')
      this.delPhoneNumberID.push(id)
    })
  }

  /**
   * 削除したその他電話番号取得
   */
  getDeleteOtherPhoneNumberAction() {
      const $ = this.$
      $(document).off('click.telDel').on('click.telDel', '#f_other_tel .btn_del', (e) => {
        const id = $(e.currentTarget).parent().parent().find('.other_tel').data('id')
        this.delOtherPhoneNumberID.push(id)
      })
    }

  /**
   * HPのURL取得 
   */
  getURLFromForm() {
    let hpUrl = []
    const elms = document.querySelectorAll('[name="hp_url"]')
    elms.forEach((elm) => {
      if(elm.value) hpUrl.push(elm.value)
    })
    return hpUrl.join(',')
  }

  /**
   * クエリ構築
   */
  buildQuery() {
    /*DB
    [
        {
            "id": "AP413830_1689924765131",
            "name": "test",
            "negotiation_date": "2023-07-28",
            "postcode": "0000000",
            "company_address": "埼玉県さいたま市",
            "hooks_id": 3,
            "suggestions_id": 3,
            "sub_cates_id": 269,
            "categories_id": 53,
            "post_id": "413830_tn-post-661efef8"
        }
    ]*/


    let urls = this.getURLFromForm()
    let hooks_id = this.parentObj.checkedVal(document.getElementsByName('hook'), 'id')
    let suggestions_id = this.parentObj.checkedVal(document.getElementsByName('suggestion'), 'id')
    suggestions_id = suggestions_id == 35? 3: suggestions_id

    const check_sub_cates_id = this.parentObj.getOptionsDataset('sub_cate', 'id') ? this.parentObj.getOptionsDataset('sub_cate', 'id') : 9999

    // DB用
    let queryObj = [
      {
        "table_name": "subjects",
        "non_sub_query": { 
          "set": {
            // 案件名
            "name": this.editableItems.companyName,
            // 会社住所
            "company_address": this.editableItems.address,
            // 郵便番号
            "postcode": this.editableItems.postcode,
            // category
            //"categories_id": this.parentObj.getOptionsDataset('category', 'id'),
            // sub_cate
            "sub_cates_id": check_sub_cates_id,
            // HPの有無
            "is_exist_hp": urls.length > 0? 1: 0
          },
          "where": {
            "post_id": this.postID
          }
        },
        "sub_query": {
        },
        "support_data": this.params.support_data,
        "authC": this.params.authC
      }, 
      {
        "table_name": "ap_negotiations",
        "non_sub_query": { 
          "set": {
            // hook
            "hooks_id": hooks_id,
            // suggestion
            "suggestions_id": suggestions_id,
          },
          "where": {
            "post_id": this.postID
          }
        },
        "sub_query": {
        },
        "support_data": this.params.support_data,
        "authC": this.params.authC,
      }, 
    ]

    // 電話番号の各種クエリ
    const phoneNumberQuery = this.buildPhoneNumberQuery()
    const otherPhoneNumberQuery = this.buildPhoneNumberQuery("other")

    queryObj = [...queryObj, ...phoneNumberQuery.update, ...otherPhoneNumberQuery.update]

    // シート用
    const category = document.querySelector(`[name="category"]`).value
    const subCate = document.querySelector(`[name="sub_cate"]`).value
    const suggestion = this.parentObj.checkedVal(document.getElementsByName('suggestion'))
    const hook = document.querySelector('input[name="hook"]:checked').value
    const checkedElement = document.querySelector(`[name="giving_name"]:checked`)
    const givingName = checkedElement ? checkedElement.value : ""

    this.insertSheetData['created_at'].val = this.createdAt

    {
      let apType = this.insertSheetData['アポ種別'].val
      const hookType = this.parentObj.checkedVal(document.getElementsByName('hook'), 'type')
      
      let afterText = apType.match(/関.+|オンライン.?/)
      afterText = afterText && afterText.length > 0? afterText[0]: ''
      const beforeText = suggestion == hookType? hookType: hookType + suggestion

      this.insertSheetData['アポ種別'].val = beforeText + afterText
    }
    
    this.insertSheetData['案件名'].val = this.editableItems.companyName

    this.insertSheetData['カテゴリ'].val = category

    this.insertSheetData['業種'].val = subCate

    this.insertSheetData['所在地'].val = this.editableItems.address

    this.insertSheetData['連絡先電話番号'].val = phoneNumberQuery.sheet

    this.insertSheetData['その他電話番号'].val = otherPhoneNumberQuery.sheet

    this.insertSheetData['HP有無'].val = (!urls)? '無': '' 

    this.insertSheetData['URL'].val = urls

    this.insertSheetData['提案内容'].val = suggestion

    this.insertSheetData['フック'].val = hook

    this.insertSheetData['名乗り'].val = givingName

    {
      let calText = this.insertSheetData['全文'].val

      let calTextAry = calText.split('\n')
      let calTextTmp = calTextAry[1].replace(/\[[^\]]+\]/, `[${formDataStore.state.version}]`)
      calText = calText.replace(calTextAry[1], calTextTmp)

      calText = calText.replace(/【[^\\\n]+?アポ】/, `【${this.insertSheetData['アポ種別'].val}アポ】`)

      calText = calText.replace(/案件名：[^\\\n]+/, `案件名：${this.editableItems.companyName}`)

      calText = calText.replace(/代表者名：[^\\\n]+/, `代表者名：${this.editableItems.ceoName}`)

      calText = calText.replace(/カテゴリ：[^\\\n]+/, `カテゴリ：${category}`)

      calText = calText.replace(/業種：[^\\\n]+/, `業種：${subCate}`)

      calText = calText.replace(/郵便番号：[^\\\n]+/, `郵便番号：${this.editableItems.postcode}`)

      calText = calText.replace(/所在地：[^\\\n]+/, `所在地：${this.editableItems.address}`)

      calText = calText.replace(/連絡先電話番号：[^\\\n]+/, `連絡先電話番号：${phoneNumberQuery.sheet}`)

      calText = calText.replace(/その他電話番号：[^\\\n]+/, `その他電話番号：${otherPhoneNumberQuery.sheet}`)

      calText = calText.replace(/HPのURL：[^\\\n]+/, `HPのURL：${urls}`)

      calText = calText.replace(/フック：[^\\\n]+/, `フック：${hook}`)

      calText = calText.replace(/提案内容：[^\\\n]+/, `提案内容：${suggestion}`)
      
      this.insertSheetData['全文'].val = calText
    }

    this.insertSheetData['郵便番号'].val = this.editableItems.postcode

    // シート格納用にクエリ生成
    const sheetData = this.requestFunc.createBody(this.formTypeForRequestBody, this.isProd, [this.insertSheetData]) 

    this.query = {
      toDBUpdate: queryObj,
      toDBInsert: phoneNumberQuery.insert,
      toSheetUpdate: {
        'spread': sheetData.spread,
        'target_lines': [
          {"column": "投稿ID", "value": this.postID}
        ],
        "update_cells": [
          {"column": "削除日時", "value": this.createdAt},
        ]
      },
      toSheetInsert: sheetData 

    }

    return this.query
  }

  /*
   post_idからap_negotiation_id取得
  */
   async getApNegotiationAtPostID() {
    // 案件ID検索開始
    this.params.main_data = {
      tables: ["ap_negotiations"],
      query: `SELECT id FROM ap_negotiations WHERE post_id = '${this.postID}'`
    }

    this.apNegotiationsID = await fetch(formDataStore.state.apis[`dbSelect${this.demo}`], {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.params)
    })
    .then(response => response.json())
    .then(response => {
      return response.length > 0? response[0].id: false 
    })


    // ap_negotiations_necks チェック
    this.params.main_data = {
      tables: ["ap_negotiations_necks"],
      query: `SELECT id FROM ap_negotiations_necks WHERE ap_negotiations_id = '${this.apNegotiationsID}'`
    }

    this.flagApNegotiationsNecks = await fetch(formDataStore.state.apis[`dbSelect${this.demo}`], {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.params)
    })
    .then(response => response.json())
    .then(response => {
      return response.length > 0? response[0].id: false 
    })


    // ap_negotiations_outlets チェック
    this.params.main_data = {
      tables: ["ap_negotiations_outlets"],
      query: `SELECT id FROM ap_negotiations_outlets WHERE ap_negotiations_id = '${this.apNegotiationsID}'`
    }

    this.flagApNegotiationsOutlets = await fetch(formDataStore.state.apis[`dbSelect${this.demo}`], {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.params)
    })
    .then(response => response.json())
    .then(response => {
      return response.length > 0? response[0].id: false 
    })
    
  }

  /**
   * 電話番号登録用クエリ構築
   */
  buildPhoneNumberQuery(type = "") {

    let queries = {
      update: [],
      insert: [],
      sheet: []
    } 
    
    // 削除
    const deletePhoneNumbers = (ids, queries) => {
      ids.forEach((val) => {
        if(val) {
          let del = {
            "table_name": "subject_phone_numbers",
            "non_sub_query": { 
              "set": {
                "deleted_at": this.createdAt,
              },
              "where": {
                "id": val
              }
            },
            "sub_query": {
            },
            "support_data": this.params.support_data,
            "authC": this.params.authC,
          }

          queries.update.push(del)
        }
      })
    }

    // 削除
    if (type == "" && this.delPhoneNumberID.length > 0) {
      deletePhoneNumbers.call(this, this.delPhoneNumberID, queries);
    }

    if (type == "other" && this.delOtherPhoneNumberID.length > 0) {
      deletePhoneNumbers.call(this, this.delOtherPhoneNumberID, queries);
    }

    // 更新と追加
    const addUpdatePhoneNumber = (elms) => {
      elms.forEach((elm) => {
        if(elm.dataset.id) {
          const query = {
            "table_name": "subject_phone_numbers",
            "non_sub_query": { 
              "set": {
                "phone_number": elm.value.replace(/-/g, ''),
                "created_at": this.createdAt,
              },
              "where": {
                "id": elm.dataset.id
              }
            },
            "sub_query": {
            },
            "support_data": this.params.support_data,
            "authC": this.params.authC,
          }
          queries.update.push(query)

        } else if(elm.value) {

          const query = {
            "table_name": "subject_phone_numbers",
            "form": {
              "non_sub_query": {
                "subjects_id": this.subjectsID,
                "phone_number": elm.value.replace(/-/g, ''),
                "created_at": this.createdAt,
              },
              "sub_query": {
                "users": false,
              }
            },
            "support_data": this.params.support_data,
            "authC": this.params.authC,
          }

          queries.insert.push(query)
        }

        if(elm.value) queries.sheet.push(elm.value)
      })
    }

    if (type == "") {
      let elms = document.querySelectorAll('[name="tel"]')
      addUpdatePhoneNumber(elms)
    }

    if (type == "other") {
      let elms = document.querySelectorAll('[name="other_tel_input"]')
      addUpdatePhoneNumber(elms)
    }

    queries.sheet = queries.sheet.join(',')
    return queries
  }

  /**
   * 論理削除用クエリ構築
   */
  async buildDeleteQuery() {
    // DB用
    const tables = ["subjects", "ap_gains", "ap_negotiations"]
    this.delQueries = []

    await this.getApNegotiationAtPostID()

    tables.forEach((val) => {
      const query = {
        "table_name": val,
        "non_sub_query": { 
          "set": {
            "deleted_at": this.createdAt,
          },
          "where": {
            "post_id": this.postID
          }
        },
        "sub_query": {
        },
        "support_data": this.params.support_data,
        "authC": this.params.authC,
      }

      this.delQueries.push(query)
    })

    // ap_negotiations_necksに登録あれば
    if(this.flagApNegotiationsNecks) {

      let apNegotiationsNecksQuery = {
        "table_name": "ap_negotiations_necks",
        "non_sub_query": { 
          "set": {
            "deleted_at": this.createdAt,
          },
          "where": { "ap_negotiations_id": this.apNegotiationsID },
        },
        "sub_query": {},
        "support_data": this.params.support_data,
        "authC": this.params.authC,
      }
      this.delQueries.push(apNegotiationsNecksQuery)

    }

    // ap_negotiations_outletsに登録あれば
    if(this.flagApNegotiationsOutlets) {

      let apNegotiationsOutletsQuery = {
        "table_name": "ap_negotiations_outlets",
        "non_sub_query": { 
          "set": {
            "deleted_at": this.createdAt,
          },
          "where": { "ap_negotiations_id": this.apNegotiationsID },
        },
        "sub_query": {},
        "support_data": this.params.support_data,
        "authC": this.params.authC,
      }
      this.delQueries.push(apNegotiationsOutletsQuery)
    
    }

    // 電話番号
    if(this.phoneNumberData.length > 0) {
      let delPhoneNumberQuery = {
        "table_name": "subject_phone_numbers",
        "non_sub_query": { 
          "set": {
            "deleted_at": this.createdAt,
          },
          "where": {
            "subjects_id": this.subjectsID
          }
        },
        "sub_query": {
        },
        "support_data": this.params.support_data,
        "authC": this.params.authC,
      }
      this.delQueries.push(delPhoneNumberQuery)
    }

    // Callinの該当する架電ログのSubjectsIDに''をセット
    let delCallinRecordsQuery = {
      "table_name": "callin_records",
      "non_sub_query": { 
        "set": {
          "subjects_id": '',
        },
        "where": {
          "subjects_id": this.subjectsID
        }
      },
      "sub_query": {
      },
      "support_data": this.params.support_data,
      "authC": this.params.authC,
    }
    this.delQueries.push(delCallinRecordsQuery)



    // スプレッドシート用
    this.delSheetQuery = {
      'spread': this.spreadData,
      'target_lines': [
        {"column": "投稿ID", "value": this.postID},
        {"column": "削除日時", "value": ''},
      ],
      "update_cells": [
        {"column": "削除日時", "value": this.createdAt},
      ]
    }


  }

  /*
    DBをアップデート
  */
  async updateDB() {
    return fetch(this.DBUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        "Content-Type" : "application/json",
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toDBUpdate)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'DBアップデート失敗 Discordメッセージ確認'
      }

      console.log('DBアップデート成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }

  /*
    DBに挿入
  */
  async insertDB() {
    if(this.query.toDBInsert.length == 0) return true

    return fetch(this.DBInsertApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        "Content-Type" : "application/json",
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toDBInsert)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'DB追加失敗 Discordメッセージ確認'
      }

      console.log('DB追加成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }

  /*
    シートをアップデート
  */
  async updateSheet() {
    if(this.query.toSheetUpdate.length == 0) return true

    return fetch(this.spreadsheetUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        "Content-Type" : "application/json",
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toSheetUpdate)
    })
    .then((res) => res.text())
    .then((res) => {

      if(res - 0 == 99) {
        throw 'シート更新失敗 Discordメッセージ確認'
      }

      console.log('シート更新成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

  }

  /*
    シートに挿入
  */
  async insertSheet() {
    if(this.query.toSheetInsert.length == 0) return true

    return fetch(this.spreadsheetInsertApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        "Content-Type" : "application/json",
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toSheetInsert)
    })
    .then((res) => res.text())
    .then((res) => {

      if(res - 0 == 99) {
        throw 'シート追加失敗 Discordメッセージ確認'
      }

      console.log('シート追加成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

  }

  /*
    DBのデータを論理削除
  */
  async deleteDBData() {

    return fetch(this.DBUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        "Content-Type" : "application/json",
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.delQueries)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'DBデータ削除日時セット失敗 Discordメッセージ確認'
      }

      console.log('DBデータ削除日時セット成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

  }

  /*
    シートのデータを論理削除
  */
  async deleteSheetData() {
    return fetch(this.spreadsheetUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        "Content-Type" : "application/json",
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.delSheetQuery)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'シートデータ削除日時セット失敗 Discordメッセージ確認'
      }

      console.log('シートデータ削除日時セット成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }

  checkRequired() {
    const _this = this
    const $ = this.$
    const bg_color = '#ffcdd2'
    const color = '#212121'

    //[必須項目判定]
    let editable_items = document.getElementsByClassName("js-editable")

    let required_items = []
    // 各要素に対してquerySelectorAllを実行
    Array.from(editable_items).forEach(item => {
      // item内の.required要素を取得
      let required = item.querySelectorAll(".required");
      // 必要であれば、required_itemsに追加
      required_items.push(...required); // スプレッド構文で配列として追加
    });

    let required_flag = 0
    let required_flags = ""
    let sending_btn = document.getElementById('jsModifyBtn')



    $('#form_ap').off('change input click mousemove').on('change input click mousemove', function() {
      required_flags = ""
      sending_btn.disabled = true

      //デフォinput背景設定
      $('.js-editable .required_color').css({
        'background-color': bg_color,
        'color': color
      })

      //[形式判定]
      var format_flag = ""
      var format_flags = ""
      function format_check (trigger, label_class, label_tag, insertbfr, mode) {
        if($(label_class).length > 0) {
          $(label_class).remove ()
        }

        format_flag = "" //初期化
      
        for(let i = 0; i < trigger.length; i++) {
          
          let chk = trigger.get(i).validationMessage == ""
          if(mode == 'tel' && trigger.get(i).value) {
            chk = trigger.get(i).value.match(/^0[0-9]*-[0-9]*-([0-9]{3}|[0-9]{4})/)
            if (chk) {
              if (trigger.get(i).value.length > 15) {
                trigger.get(i).value = chk[0].substring(0, 15)
              }
            }
          } 

          if(chk) {
            format_flag += "1"
          } else {
            format_flag += "0"
            $(label_tag).insertAfter(insertbfr[i])
          }

        }

        if(format_flag.indexOf('0') == -1) {
          format_flags += "1"
        } else {
          format_flags += "0"
        }

        return format_flags
      }

      //[形式判定：電話番号]
      var notice_text = '※ ハイフンありの半角数字で入力してください。（例）01-234-5678<br>※商談時に接続の可能性が高い番号（代表携帯番号、架電先電話番号、先方よりこちらに電話して欲しいと言われた番号）を記載してください。'
      var trigger = $('.js-editable .required[name="tel"]')
      var label_class = '.error_msg_tel1'
      var label_tag = '<li class="c-variables__item c-info c-notice error_msg_tel1">' + notice_text + '</li>'
      var insertbfr = trigger.parent()
      format_check(trigger,label_class,label_tag,insertbfr, 'tel')

      //[形式判定：その他電話番号]
      var trigger = $('[name="other_tel_input"]')
      var label_class = '.error_msg_other_tel1'
      var label_tag = '<li class="c-variables__item c-info c-notice error_msg_other_tel1">' + notice_text + '</li>'
      var insertbfr = $('[name="other_tel_input"]').parent()
      format_check(trigger,label_class,label_tag,insertbfr, 'tel')

      //[形式判定：案件名]
      var trigger = $('[name="company_name"]')
      var label_class = '.error_msg_company_name'
      var label_tag = '<p class="c-info c-notice error_msg_company_name">※ http形式 or 電話番号形式(xx-xxx-xxx)以外の形式で入力してください。</p>'
      var insertbfr = $('[name="company_name"]').parent()
      format_check(trigger, label_class, label_tag, insertbfr)

      //[形式判定：URL]
      var trigger = $('[name="hp_url"]')
      var label_class = '.error_msg_url'
      var label_tag = '<p class="c-variables__item c-info c-notice error_msg_url">※ "http" or "https"から始まるURL形式で入力してください</p>'
      var insertbfr = $('[name="hp_url"]').parent()
      format_check(trigger, label_class, label_tag, insertbfr)

      //[形式判定：所在地]
      var trigger = $('[name="address_all"]')
      var label_class = '.error_msg_pref'
      var label_tag = '<p class="c-info c-notice error_msg_pref">※ 都道府県名から入力してください</p>'
      var insertbfr = $('[name="address_all"]')
      format_flags += _this.format_check_pref(trigger, label_class, label_tag, insertbfr)

      //[形式判定：郵便番号]
      var trigger = $('#postcode')
      var label_class = '.error_msg_postcode'
      var label_tag = '<p class="c-info c-notice error_msg_postcode">※ 7桁の数字で入力してください。(例)1234567</p>'
      var insertbfr = $('#postcode')
      format_check(trigger, label_class, label_tag, insertbfr)



      //テキストボックス必須項目判定
      for (let i = 0; i < required_items.length; i++) {
        if(required_items[i].value != "") {
          required_flag = '1'
          required_flags += required_flag
          required_items[i].style.backgroundColor = 'transparent'
        } else {
          required_flag = '0'
          required_flags += required_flag
        }
      }

      //select：必須項目判定
      var category = document.getElementById("category")
      var sub_cate = document.getElementById("sub_cate")
      var pref_name2 = document.getElementById("pref_name2")

      function check_select (select_hensu) {
        if(select_hensu.value != "") {
          required_flag = '1'
          required_flags += required_flag
          select_hensu.style.backgroundColor = 'transparent'
        } else {
          required_flag = '0'
          required_flags += required_flag
        }

        return required_flags
      }

      check_select(category)
      check_select(sub_cate)

      if(pref_name2.required) {
        check_select(pref_name2)
        var trigger = $('[name="pref_name"]')
        var label_class = '.error_msg_pref'
        var label_tag = '<p class="c-info c-notice error_msg_pref">※ 都道府県名から入力してください</p>'
        var insertbfr = $('[name="pref_name"]')
        _this.format_check_pref(trigger,label_class,label_tag,insertbfr)
      }

      var url_exists = $('[name="url_exists"]')
      var hook = $('[name="hook"]')
      var givingName = $('[name="giving_name"]')

      var check_flags = ""

      function check_radio (radio_hensu) {
        //全項目チェックされていなければ0、一つでもチェックされていたら1
        check_flags = "" //初期化
        for(let i = 0; i < radio_hensu.length; i++) {
          //(1)チェック項目でそれぞれチェックされているか確認
          if(radio_hensu[i].checked) {
            required_flag = '1'
            check_flags += required_flag
          } else {
            required_flag = '0'
            check_flags += required_flag
          }
        }

        //(1)の結果、"1"を含んでいたら、チェックされた時の処理
        if(check_flags.indexOf('1') != -1) { //"1"を含む => true
          required_flag = '1'
          radio_hensu.next('span').css('backgroundColor','transparent')
          required_flags += required_flag
        } else {
          required_flag = '0'
          required_flags += required_flag
        }

        return required_flags
      }

      check_radio(url_exists)
      check_radio(hook)

      if(givingName[0] && givingName[0].required) check_radio(givingName)


      //チェックボックス
      ////////必須に設定する項目////////
      var suggestion = $('[name="suggestion"]')
      ///////////////////////////////

      // required_flags追加
      function isChecked_checkbox(checkbox_hensu) {
        var checkbox_flags = ""

        for(let i = 0; i < checkbox_hensu.length; i++) {
          if(checkbox_hensu[i].checked) {
            var flag = "1"
            checkbox_flags += flag
          } else {
            var flag = "0"
            checkbox_flags += flag
          }
        }

        if(checkbox_flags.indexOf('1') != -1) {
          required_flag = '1'
          checkbox_hensu.next('span').css('backgroundColor','transparent')
          required_flags += required_flag
        } else {
          required_flag = '0'
          required_flags += required_flag
        }

        return required_flags
      }

      isChecked_checkbox(suggestion)

      //console.log(required_flags,format_flags);

      if(required_flags.indexOf('0')== -1 && format_flags.indexOf('0')== -1) { //0を含んでいない場合に反映ボタン有効化
        sending_btn.disabled = false
        sending_btn.classList.remove('disabled')
        sending_btn.classList.add('pulse')
      } else { //ボタン無効化
        sending_btn.disabled = true
        sending_btn.classList.add('disabled')
        sending_btn.classList.remove('pulse')
      }
    })
  }

    /*
  * バリデーション - 適切に都道府県名が入力されているかチェックする関数
  * @params {Obj} チェックするタグのセレクタ
  * @params {Str} エラーメッセージのクラス名
  * @params {Str} エラーメッセージのHTML要素
  * @params {Obj} エラーメッセージを入れるセレクタ
  **/
  format_check_pref (trigger, label_class, label_tag, insertbfr) {
    const $ = this.$

    let format_flag = "",
    format_flags = ""
    const pref_list = ["青森", "岩手", "宮城", "秋田", "山形", "福島", "茨城", "栃木", "群馬", "埼玉", "千葉", "神奈川", "新潟", "富山", "石川", "福井", "山梨", "長野", "岐阜", "静岡", "愛知", "三重", "滋賀", "兵庫", "奈良", "和歌山", "鳥取", "島根", "岡山", "広島", "山口", "徳島", "香川", "愛媛", "高知", "福岡", "佐賀", "長崎", "熊本", "大分", "宮崎", "鹿児島", "沖縄"]

    if(trigger.next('.c-notice').length > 0) {
      trigger.next('.c-notice').remove()
    }

    for(let i = 0; i < trigger.length; i++ ) {
      if(trigger.get(i).validationMessage == "" && (trigger[i].value.startsWith("北海道") || trigger[i].value.startsWith("東京都") || trigger[i].value.startsWith("大阪府") || trigger[i].value.startsWith("京都府"))) {
        format_flag += "1"
      } else if(trigger.get(i).validationMessage == "") {
        let pref_ken = trigger[i].value.split('県')

        if(pref_list.find((v) => v === pref_ken[0]) == undefined) {
          format_flag += "0"
          $(label_tag).insertAfter(insertbfr[i])
        } else {
          format_flag += "1"
        }

      } else {
        format_flag += "0"
        $(label_tag).insertAfter(insertbfr[i])
      }
    }

    if(format_flag.indexOf('0') == -1) {
      format_flags += "1"
    } else {
      format_flags += "0"
    }
    return format_flags
  }
}
