//関数まとめ
class requestBody {

  createBody(groupName, isProd, insertDataRecordCnt) {
    const mailAddress = "sg-post-spread-20241127@sungrove-db-dev.iam.gserviceaccount.com"
    const password = "W!z|a~.x.T~Tzca8CBCm" //スプレッドAPI用パスワード
    const spread = this.setSpreadData(groupName, isProd)
    const timestamp = this.nowTime("timestamp")

    let sheetData = {
                      "posted_group": {
                        "id": "",
                        "name": groupName
                      },
                      "spread": {
                        "id": spread.spreadsheetID,
                        "sheet_name": spread.sheet_name
                      },
                      "timestamp": timestamp,
                      "insert_data": insertDataRecordCnt,
                      "authC": {
                        "password": password,
                        "mail": mailAddress
                      },
                      "support_data":{
                        "is_prod": isProd
                      }
                    }

    return sheetData
  }

  setSpreadData(groupName, isProd) {
    return this.spreadDesignation(groupName, isProd)
  }

  spreadDesignation(groupName, isProd) {

    let spreadsheetAry = {}
    let sheet_name = ""

    switch (groupName) {

    case "apGain": //アポ獲得
      spreadsheetAry.staging = "1zQHTirxrdjoqHdNNcmoqG_UXStCAFdmUV_J3o-ENuTc"
      spreadsheetAry.test = "1xsCue6Nnwme_Lw70zN8qQgA0Pg62YFL_1kkFFsRNi9c"
      sheet_name = "シート1"
      break

    case "reScheduleCancel": //リスキャン
      spreadsheetAry.staging = "12PsepBtV73xfnDvwjcWflRYnX04f_eLf7vkl3lSMWnE"
      spreadsheetAry.test = "1Yxc034rs22fwYCXJKRU-_Bq6gt0dkAhpef9IxAmIgoU"
      sheet_name = "マスタデータ"
      break

    case "saihou": //再訪
      spreadsheetAry.staging = "1o25NXtAYELMzQDbNj09yrIaSVPmIMCFWfoXQvq85TB0"
      spreadsheetAry.test = "1PqJ-zn2gz7Yd5qhXM6MeEbyqAl4bNBSMR3i5xr0_Tdo"
      sheet_name = "master"
      break

    case "apoReport": //商談報告
      spreadsheetAry.staging = "18vl-CwEIA9YmhQTZUMsitLmorvTtBPdlVqQzdY8cIzM"
      spreadsheetAry.test = "1DAW8XOj1T0R-NShrmSVC0429C7-VP2D30-mYC1UpQLo"
      sheet_name = "マスタデータ"
      break

    case "juchuumaeKaitou": //受注前回答
      spreadsheetAry.staging = "1kQ_TSAl9y2DGWiD7KBBDdOF8frhbYV2u5HxLqhwvqU4"
      spreadsheetAry.test = "1GvWMR1fhziVVepLP8yKkC8l3VbQRZTXLQ2Kiu6JGZr8"
      sheet_name = "受注前回答シート1"
      break

    case "applications": //申込書
      spreadsheetAry.staging = "1LJOPRuGD0CGHWhA-fjDDjXVCGfoIbwPDA-Xl4ASTcxQ"
      spreadsheetAry.test = "1BVwpqlxlAUcVRIxo3jJ-xVsGTDD_xoqJarMXr0fUo6g"
      sheet_name = "master"
      break

    case "requestShift": //戦略室への要望
      spreadsheetAry.staging = "1l46e3EP71BiI3luAj6V8Q9IuEVc4czLDEFAAxM3hRE0"
      spreadsheetAry.test = "1DJP_6YORtg-gS8jssqnZ_zxte342POQ3_MBd9t7Sl1k"
      sheet_name = "マスタデータ"
      break
    }

    let spreadsheetID = ""
  
    if (!isProd) {  
      spreadsheetID = spreadsheetAry.test  //テスト用スプレッド
      console.log("テスト用スプレッドシートに格納します")
    } else {
      spreadsheetID = spreadsheetAry.staging //本番スプレッド
    }

    const spread = {spreadsheetID,isProd,sheet_name}

    return spread
  }

  spreadDesignationCalLink(isToday, isProd) {

    let spreadsheetObj = {}
    let sheetNameObj = {}

    spreadsheetObj.staging = "14ZME68pBsPU4_fmyof6x2MzhdqhL8ixF09FbDlCuoBE"
    spreadsheetObj.test = "1Qw9sDgdcraSoNouib9iiBaS6-Vh7t7tYPv1_H21HJq4"

    if (isToday) {
      sheetNameObj.staging = "作成一覧_当アポ"
      sheetNameObj.test = "[テスト]作成一覧_当アポ"
    } else {
      sheetNameObj.staging = "作成一覧_当アポ以外"
      sheetNameObj.test = "[テスト]作成一覧_当アポ以外"
    } 
    
    let spreadsheetID = ""
    //let isProd = ""
    let sheetName = ""
  
    if (!isProd) {  
      spreadsheetID = spreadsheetObj.test  //テスト用スプレッド
      sheetName = sheetNameObj.test
      console.log("テスト用スプレッドシートに格納します")
    } else {
      spreadsheetID = spreadsheetObj.staging //本番スプレッド
      sheetName = sheetNameObj.staging
    }

    const spread = {spreadsheetID,isProd,sheetName}

    return spread
  }

  spreadDesignationRescan(isProd, mode="calLink") {

    let spreadsheetObj = {}
    let sheetName = ""
    let spreadsheetID = ""

    if (mode == "calLink") {
      spreadsheetObj.staging = "1mib31gQzxUf7Iza70jrv_tytxviKaGuxYedyMQPPZvk"
      spreadsheetObj.test = "1fJmMKLM-H-M1mPtQcN5wV_iwumZbLbPsLdAbNFOvA84"
      sheetName = "実行一覧"
    } else if (mode == "failuredList") {
      spreadsheetObj.staging = "1XzP7yqx_p3AmFYBR1TDczlxropQ3ohu1Zg7gixjgZlk"
      spreadsheetObj.test = "1fJmMKLM-H-M1mPtQcN5wV_iwumZbLbPsLdAbNFOvA84"
      sheetName = "未反映リスト"
    } else {
      console.log("モードが正しく指定されていません")
      return ""
    }

    if (!isProd) {  
      spreadsheetID = spreadsheetObj.test  //テスト用スプレッド
      console.log("テスト用スプレッドシートに格納します")
    } else {
      spreadsheetID = spreadsheetObj.staging //本番スプレッド
    }

    const spread = {spreadsheetID,isProd,sheetName}

    return spread
  }

  nowTime(timeType) {
    const date = new Date()
    const format = 'yyyy/MM/dd HH:mm:ss'
    const options = { timeZone: 'Asia/Tokyo', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }
    const formatter = new Intl.DateTimeFormat('ja-JP', options)
    const formattedDate = formatter.format(date)
    const [yyyy, MM, dd, HH, mm, ss] = formattedDate.split(/[\/:\s]/)
    const timestamp = [yyyy, MM, dd, HH, mm, ss].join("")

    if(timeType == "timestamp") {
      return timestamp
    }

    const replacements = { yyyy, MM, dd, HH, mm, ss }
    const NowTime = format.replace(/yyyy|MM|dd|HH|mm|ss/g, match => replacements[match])

    if(timeType == "created_at") {
      const created_at = NowTime.replace(/\//g, "-");
      return created_at
    }
    
    return NowTime

  }

  statusType(insertDataObj) {

    const statusObject = {
      "リスケ": 1,
      "キャンセル": 2,
      "前不": 3,
      "復活": 4,
      "前確OK": 5,
      "前確不要": 6,
    }

    const changePointObject = {
      "商談日": 1,
      "商談時間": 2,
      "商談形式": 3,
      "商談住所": 4,
      "未定": 5,
      "なし": 6
    }

    const changeTypeObject = {
      "オンライン": 1,
      "来社": 2,
      "訪問": 3,
      "カフェ": 3
    }

    let statusNumber = statusObject[insertDataObj.status]
    let changeArea = insertDataObj.changearea

    const changePointArray = insertDataObj.changePoint.split('/')
    const changetype = changeTypeObject[insertDataObj.changetype]

    if (changeArea && changeArea.length >= 2) {
      changeArea = changeArea.slice(0, -2);
    }
    
    const changeArray = []

    for (let i = 0; i < changePointArray.length; i++) {

      let section1 = ""
      let section2 = ""
      let changed_points = changePointObject[changePointArray[i]]

      switch (changed_points) {

        case 1:
          section1 = insertDataObj.changeDate
          break

        case 2:
          section1 = insertDataObj.changestart
          section2 = insertDataObj.changelast
          break

        case 3:
          section1 = changetype
          if (section1 == 2) {
            section2 = changeArea
          }
          if (section1 == 3) {
            section2 = insertDataObj.changeadd
          }
          break

        case 4:
          section1 = 3
          section2 = insertDataObj.changeadd
          break

        case 5: //リスケの未定はキャンセル扱い
          statusNumber = 2
          break

      }

      let changeObject = {"id": changed_points, 
                          "section1": section1, 
                          "section2": section2}

      changeArray.push(changeObject)

    }

    const subjectStatus = {statusNumber, changeArray}

    return subjectStatus

  }

  setBelongDate (storeData) {
    const office_id = (storeData.office_id == "None") ? "" : storeData.office_id
    const userSection = (storeData.section_name == "None") ? "" : storeData.section_name
    const userTeamName = (storeData.team_name == "None") ? "" : storeData.team_name
    const userLabelInfosId = (storeData.label_infos_ids == "None") ? "" : storeData.label_infos_ids
    const FS_TeamValue = "1"
    const IS_TeamValue = "2"
    let FS_UserTeam = ""
    let IS_UserTeam = ""

    let userTeamArray = ""
    if (userTeamName && userTeamName !== "NULL" && userTeamName != "None") {
      userTeamArray = userTeamName.split(",")
    }

    if (userLabelInfosId && userLabelInfosId != "NULL" && userLabelInfosId != "None") {
      const labelInfoArray = userLabelInfosId.split(",")

      if (labelInfoArray.includes(FS_TeamValue)) {
        const index = labelInfoArray.indexOf(FS_TeamValue)
        FS_UserTeam = userTeamArray[index]
      }

      if (labelInfoArray.includes(IS_TeamValue)) {
        const index = labelInfoArray.indexOf(IS_TeamValue)
        IS_UserTeam = userTeamArray[index]
      }
    }

    const officeData = new OfficeDatas()
    const userOffice = officeData.getUserOfficeName(office_id)

    const belongData = {userOffice, userSection, FS_UserTeam, IS_UserTeam}

    return belongData

  }

  meo_words (ary) {
    let result = ''
    for(let i in ary) {
      console.log(ary[i])
      result += `${ary[i].word}/${ary[i].km}km,`
    }
    return result.slice(0, -1)
  }

  calColorNameList (colorID) {
    const cal_color_name_list = {
      "0": "", //デフォルト
      "1": "ラベンダー",
      "2": "セージ",
      "3": "ブドウ",
      "4": "フラミンゴ",
      "5": "バナナ",
      "6": "ミカン",
      "7": "ピーコック",
      "8": "グラファイト",
      "9": "ブルーベリー",
      "10": "バジル",
      "11": "トマト"
    }
    const colorName = cal_color_name_list[colorID]
    return colorName
  }

  formattedDateTime (dateTime) {
    const dateObject = new Date(dateTime)
    const formattedDate = `${dateObject.getFullYear()}/${(dateObject.getMonth() + 1).toString().padStart(2, '0')}/${dateObject.getDate().toString().padStart(2, '0')} ${dateObject.getHours()}:${dateObject.getMinutes().toString().padStart(2, '0')}:${dateObject.getSeconds().toString().padStart(2, '0')}`
    return formattedDate
  }

  createCalFailureApplyMessage(messageURL) {
    const message = 
    `URL：${messageURL}
    
    ◎sgPost投稿の場合
    カレンダー反映されていません。
    再投稿指示、お願いします。

    ◎手動投稿の場合
    ALLリスキャンで正しいフォーマット形式で再投稿お願いします。
    `
    
    return message
  }

  createCalTimeOutMessage(messageURL) {
    const message = 
    `URL：${messageURL}
    
    投稿時にタイムアウトエラーが発生しました。
    カレンダー、カラムの反映ができていない場合があります。
    未反映の場合は再投稿指示、お願いします。
    `
    
    return message
  }

  createNegoCalFailureApplyMessage(messageURL) {
    const message = 
    `URL：${messageURL}
    
    ◎sgPost投稿の場合
    商談済みカレンダーへの変更が反映されていません。
    カラムの反映があればカレンダーを商談済みカレンダーへ手動修正
    カラムも未反映の場合は再投稿指示、お願いします。

    ◎手動投稿の場合
    ALLリスキャンで正しいフォーマット形式で再投稿お願いします。
    `
    
    return message
  }

  fetchWithTimeout(url, options = {}, timeout = 10000) {
    const controller = new AbortController()
    const { signal } = controller
    const timeoutId = setTimeout(() => controller.abort(), timeout)

    return fetch(url, { ...options, signal })
      .finally(() => clearTimeout(timeoutId))
  }

  apiRequestExecution (apiURL, requestBody, key) {
    return this.fetchWithTimeout(apiURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'X-Cal-St-Api': key
      },
      body: JSON.stringify(requestBody)
    }, 30000)
  }

}

import OfficeDatas  from "./officeData"

export default {
  requestBody
}