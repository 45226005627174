class apoReport {
  constructor(vueObj, $, func, moment) {

    try {
      // Vueインスタンスを取得
      this.vueObj = vueObj

      this.store = vueObj.store

      this.isProd = this.vueObj.isProd

      // jQueryインスタンスを取得
      this.$ = $

      // フォーム関連関数を取得
      this.func = func

      // momentインスタンスを取得
      this.moment = moment

      // propsを取得
      //this.props = this.vueObj.getProps()
      this.thisFormParams = vueObj.thisFormParams

      // 投稿先slackチャンネル情報
      this.slackChannel = vueObj.slackChannel
      this.demo = this.isProd ? "" : "Demo"
      this.key = this.store.state.apis[`loginKey${this.demo}`]

      // 案件ID検索とか理由カテゴリ取得するAPI
      this.getDataApiURL = this.store.state.apis.getDataApiURL

      // slack チャンネル URL
      this.groupURL = this.slackChannel.workSpaceURL + this.slackChannel.id 

      // カレンダー未反映通知slackチャンネルID
      this.calendarFailureApplySlackChannelID = this.isProd? this.store.state.apis.calendarFailureApplySlackChannelID: this.store.state.apis.demoForwardChannelID
    
      //案件ID検索するAPI
      this.searchSubjectsIDApiURL = this.store.state.apis.searchSubjectsIDApiURL

      // フォームタイトル
      this.groupTitle = this.thisFormParams[0].replace(/\n/gi,"").replace(/\t/gi,"")

      // DBを操作するAPI
      this.dbApiURL = this.store.state.apis[`dbApiURL${this.demo}`]


      // talknoteの投稿先アドレス
      // this.talknoteGroupAddress = this.thisFormParams[2].replace(/\n/gi,"").replace(/\t/gi,"")

      // 変数定義
      this.CHECKED_COLOR = "teal lighten-2"
      this.NOW_URL = location.href
      this.inputType = ''
      this.inputComName = ''
      this.inputMtgDate = ''
      this.inputMtgDay = ''
      this.inputMtgEndTime = ''
      this.inputMtgStartTime = ''
      this.inputDuration = ''
      this.inputCeoName = ''
      this.inputMtgPerson = ''
      this.inputGender = ''
      this.inputSuggestion = ''
      this.inputSearchAPName = ''
      this.inputEvaluation = ''
      this.inputResult = ''
      this.inputContactDeadline = ''
      this.inputContactDeadlineDay = ''
      this.inputSalesPrice = ''
      this.inputPayStatus = ''
      this.inputPaid = ''
      this.inputBalance = ''
      this.inputBalanceDeadline = ''
      this.inputBalanceDeadlineDay = ''
      this.inputpaymentMethod = ''
      this.inputEvaluationReason = ''
      this.inputDetail = ''
      this.inputMtgDetail = ''
      this.inputFBGood = ''
      this.inputFBMore = ''
      this.inputCostomerEmail = ''
      this.inputSubjectId = ''
      this.inputRemark = ''
      this.inputRemarks = ''
      this.inputResultId = ''
      this.inputSuggestionIds = ''
      this.inputMtgPersonId = ''
      this.inputRecord = ''
      this.holidays = ''
      this.searchFlagAP = true
      this.searchFlagPasser = true

      // [残額期日]
      this.today = moment()
      this.today = this.today.format('YYYY-MM-DD')

      this.dataIDs = {
        user_sg_id: '',
        negotiation_result: '',
        suggestion_id: '',
        positions_id: '',
        gender_id: '',
        payment_status_id: '',
        ap_evaluation: ''
      }

      // 初期化
      this.init()

    } catch(e) {
      console.error(e.stack, e.message)

      let text = []

      if(!this.vueObj) {
        text.push('Vueインスタンスが取得できませんでした。')
      }

      if(!this.$) {
        text.push('jQueryが取得できませんでした。')
      }

      if(text.length == 0) {
        alert('予期せぬエラーが発生しました。')
      } else {
        alert(text.join('\n'))
      }

      return false
    }

  }

  init() {
    const $ = this.$;

    $('#BalanceDeadline, #ContactDeadline').attr('min', this.today)

    $('.autoG,.gotAP').css('display', 'none')

    $('.is_evaluation_F, .possible_reason_order_form, .possible_reason_failure_form, .possible_reason_pursuit_form, .dedicated_question_easier').hide()

    this.actionInit()

    $(".test_marketing_authority, .test_marketing_authority_agent, .result_order, .result_failure, .result_order_failure").hide()

  }

  getCategory() {
    const searchApiURL = this.dbApiURL + 'db/select'
    this.abortController = new AbortController()
    const params = {
      authC: {
        password: "W!z|a~.x.T~Tzca8CBCm",
        mail: this.userEmail
      },
      main_data: {
        tables:["users"],
        query: `SELECT name FROM categories WHERE deleted_at IS NULL`
      },
      support_data: {
        is_prod: this.isProd
      }
    }
    fetch(searchApiURL, {
      signal: this.abortController.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(params)
    })
    .then(response => response.json())
    .then(response => {
      const options = [...new Set(response.map(item => item.name))].map(value => ({ value, text: value }))
      const $select = $("#sub_cate")
      options.forEach(option => {
        $select.append($("<option>", {
          value: option.value,
          text: option.text
        }))
      })
    })
  }

  actionInit() {
    const that = this
    const $ = this.$
    const func = this.func
    const moment = this.moment
    const officeData = new OfficeDatas()
    const formHolidayFunc = new formHoliday.formHoliday()
    formHolidayFunc.getHolidayList()
    .then(data => {
      this.holidays = data
    })

    this.getCategory()

    /****************************************
     * アクション定義
    *****************************************/

    /* 
      ▼案件IDフォーマット
      AP[グループID]_[投稿ミリ秒]
      TW[グループID]_[投稿ミリ秒]
      OT[グループID]_[投稿ミリ秒]
      SL[グループID][T or O]_[投稿ミリ秒]
      └ 投稿者の所属エリアにより、T(Tokyo)orO(Osaka)で決まる
    */

    // 案件ID検索
    $('#SubjectId').off('input.search').on('input.search', function(e) {
      let _this = $(this)

      const subject_id = _this.val()
      let textCount = 22

      if(subject_id.indexOf('_') == 13) {
        textCount = 26
      }

      let before = _this.data('subject_id')

      if(subject_id.indexOf('sungrove') == 0) {
        textCount = 11
      } else {
        $('#wrap2, .autoG, .gotAP, .form_wrap, .form_wrap_pursuit_guest').slideUp()
      }

      if(subject_id.length < textCount || (before && before != subject_id) || e.originalEvent.inputType.indexOf("delete") == 0) {
        $('#status_comment').text('')
        _this.data('subject_id', '')
        before = ""

        if($('.form_wrap').eq(0).css('display') == 'block') {
          $('.auto').prop('readonly', true);
          that.formClearFlag = 1
          _this.trigger('input.clear')
          _this.val(subject_id)
        }

        return false
      }

      

      const result = subject_id.match(/(^[A-Z]{2}[0-9A-Z]{11}?_[0-9]{13}$|^[A-Z]{2}[0-9]{6}[A-Z]?_[0-9]{13}$|^sungrove[123])/)
      
      if(!result) {
        if(subject_id.indexOf('sungrove') > -1) {
          $('#SubjectId').val(subject_id)
        } 

        //alert('案件IDを正しく入力してください。')

        //$('#SubjectId').val('')

        return false
      }

      const post_prefix = subject_id.slice(0, 2)
      if ((post_prefix == "YD" || post_prefix == "KS") && that.store.state.userData.test_marketing_authority != 1) {
        alert('テストマーケのアクセス権限がありません！')
        return false
      }
      
      // 入力されたら
      if($('#status_comment').text().indexOf('検索中') == -1) {
        $('.events').remove()

        if(subject_id.startsWith('sungrove') == false) {      

          const reg1 = /^[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]|[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]$/
          const reg2 = /[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]/g
          $('#SubjectId').attr('readonly', true)
          $('#MtgStartTime, #MtgEndTime, #MtgDate').attr('readonly', true)

          if(reg1.test(subject_id)) subject_id = subject_id.replace(reg2, "")

          $('#btn').addClass('disabled').removeClass('pulse')
          $('.auto').val('')
          $('#status_comment').text('検索中...🔎 少々お待ちください😣').css('color', '#757575')
          // Loading表示
          $('#loading_form').css('display', 'block')

          // 案件ID検索APIに投げる
          console.log(new Date())

          // 案件ID検索APIに投げるGAS API URL
          //let searchApiURL = that.getDataApiURL + '?type=searchSubjectId&SubjectId=' + subject_id
          let searchApiURL = that.store.state.apis.searchSubjectsIDApiURL + subject_id
          //let searchApiURL = that.searchSubjectsIDApiURL + subject_id

          that.formClearFlag = 1
          _this.trigger('input.clear')
          _this.val(subject_id)

          // Loading表示
          that.vueObj.viewLoader('検索中')
          
          // 案件ID検索開始
          fetch(searchApiURL)
          .then(response => response.json())
          .then(response => {

             // Loading非表示
            that.vueObj.viewLoader()
            let responsePrevious = response.filter(item => item.description && item.description.indexOf("掘り起こし元ID：" + subject_id) !== -1)
            let newID = ""
            if (responsePrevious.length > 0) {
              newID = responsePrevious[0].description.split("案件ID：")[1].split("\n")[0]
              newID = "掘り起こし後のID候補：" + newID
              alert("掘り起こし済みIDです！ IDを確認してください！")
              response = []
            } else {
              response = response.filter(item => item.description && item.description.indexOf("案件ID：" + subject_id) !== -1)
            }
            console.log(response)

            const openAction = () => {
              $('.form_wrap').slideDown()
              $('select').css('display', 'block')
              $('#SubjectId').attr('readonly', false).data('subject_id', subject_id)
              $('#loading_form').css('display', 'none')
              $('#status_comment').text('')
              $('#MtgDate, #MtgStartTime, #MtgEndTime, #ComName').val('')
            }

            let result_CalID = func.checkCalID(response)

            if(result_CalID !== 99) {
              that.del_flag = 1
              that.result_del = response.splice(result_CalID, 1)
              that.del_cal_id = that.result_del[0].cal_id
              that.del_evnet_id = that.result_del[0].event_id
              that.del_desc = that.result_del[0].description
            }

            if(response.length == 1) {
              openAction()

              $('.comInfo, .mtg, .reasons, .mtgDetail').slideDown()
              $('#status_comment').text('対象の案件を見つけました👀')

              let resTitle = response[0].title
              let exchangeEle = ''
              that.inputCalName = response[0].cal_name
              that.resCalId = response[0].cal_id
              that.resEventId = response[0].event_id
              that.resMtgAdd = response[0].location
              that.description = response[0].description

              if (resTitle.includes('🎦')) {
                $('.is_hearing_cessation').show()
                $('.is_hearing_no_meet').hide()
                $('.is_hearing_cessation_no_meet').hide()
              } else {
                $('.is_hearing_cessation').hide()
                $('.is_hearing_no_meet').show()
                $('.is_hearing_cessation_no_meet').hide()
              }

              let searchAPName = resTitle.match(/【([^】]*)】/)
              searchAPName = searchAPName ? searchAPName[1] : ""
              that.inputSearchAPName = searchAPName.replace('🔰', '')
              $('#searchAPName').val(that.inputSearchAPName)
              $('#searchAPName').trigger('input')

              const regSaihou = /\[.*再.*\]/
              const errorText = func.titlePattern(resTitle, regSaihou)
              if (errorText) {
                $('#status_comment').html(errorText).css({'color': 'red', 'font-size': '16px'})
              }

              const isIncludeSaihouCal = officeData.checkIncludeSaihouCal(that.resCalId)
              const isRescanCal = officeData.checkRescanCal(that.resCalId)
              const isNegotiationCal = officeData.checkNegotiationCal(that.resCalId)
              const isRescanOrNegotiation = isRescanCal || isNegotiationCal

              if(resTitle.indexOf('///*') !== -1 || resTitle.indexOf('*///') !== -1) {
                exchangeEle = func.v2Title(response[0])
              } else if (isIncludeSaihouCal || (isRescanOrNegotiation && regSaihou.test(resTitle))) {
                exchangeEle = func.saihou(response[0])
                that.inputSaihou = '(再訪)'
              } else if(resTitle.indexOf('///') !== -1) {
                exchangeEle = func.v1Title(response[0])
              }
              
              // 項目自動入力
              let date = exchangeEle.mtg_date.split('（')[0].replace(/\//g, '-')
              let start = exchangeEle.mtg_date.split('）')[1].split('〜')[0]
              let end = exchangeEle.mtg_date.split('）')[1].split('〜')[1]
              $('#MtgDate').val(date)
              $('#MtgStartTime').val(start)
              $('#MtgEndTime').val(end)
              $('#ComName').val(exchangeEle.com_name)
              $('#searchAPName').val(exchangeEle.ap_name)
              $('#MtgDate, #ComName').attr('readonly', true)
              $('#MtgStartTime, #MtgEndTime').attr('readonly', false)

              let startIdName = 'MtgStartTime' 
              let endIdName = 'MtgEndTime'
              func.startEndTime(startIdName, endIdName)

              if(exchangeEle.ap_type == '再訪') {
                $('#typeRevisit').prop('checked', true)
                $('#wrapEvaluationReason, #wrapFB').slideUp(500)
              } else {
                $('#typeNew').prop('checked', true)
                $('#wrapEvaluationReason, #wrapFB').slideDown(500)
                $('#FB, #Detail, .chip, #FBGood, #FBMore, #evaluation_F, #chargeName, input[name="product"]').addClass('required') 
                $(".previous_result_form").hide()
              }

              const post_prefix = subject_id.slice(0, 2)
              if (post_prefix == "YD" || post_prefix == "KS") {
                $('.test_marketing_authority_agent').show()
              }

            } else if(response.length >= 1) {
              openAction()
              that.responseResult = response

              $('#status_comment').text('複数のカレンダーが見つかりました。該当カレンダーのタイトルを選択してください。').css('color', '#29b6f6')
              $('#SubjectId').attr('readonly', false)
              func.createSubjectList(response)

              const post_prefix = subject_id.slice(0, 2)
              if (post_prefix == "YD" || post_prefix == "KS") {
                $('.test_marketing_authority_agent').show()
              }

            } else {
              $('#SubjectId').attr('readonly', false)
              $('.auto').attr('placeholder', '')
              $('#status_comment').html('もう一度IDを確認して検索してください。<br>' + newID).css('color', '#e91e63')
            }
          })
          .catch((e) => {
            console.error(e.stack, e.message)
          })

          return true

        } else if(subject_id == 'sungrove123') {
          $('select').css('display', 'block')
          $('.item, .form_wrap').slideDown()
          $('#ComName, #MtgDate, #MtgStartTime, #MtgEndTime').attr('readonly', false)
          $('#status_comment').text('案件IDがない案件の為、手動入力モードに切り替えました📝').css('color', '#e91e63')

          let startIdName = 'MtgStartTime' 
          let endIdName = 'MtgEndTime'
          func.startEndTime(startIdName, endIdName)
          $('.is_hearing_cessation').hide()
          $('.is_hearing_no_meet').hide()
          $('.is_hearing_cessation_no_meet').show()
        }
      } 
    })

    // [案件IDクリア]
    $('#SubjectId').off('input.clear').on('input.clear', function() {
      const subject_id = $('#SubjectId').val()

      if(subject_id == "" || that.formClearFlag == 1) {
        that.formClearFlag = 0
        $('.comInfo, .mtg, .saledInfo, .reasons, .mtgDetail, .form_wrap_pursuit_guest').slideUp()
        $('.events').remove()
        $('#status_comment').text('案件IDを入力すると検索開始します').css('color', '#757575')

        func.clearInput($('input[type="text"], input[type="Number"], input[type="url"], .form_txt'))   
        func.checkOut($('.form_check, .required'))
        func.clearSelect($('.form_select'))

        let fild_email = $('.fild_email').clone(true)
        $('.fild_email_list').empty().append(fild_email.eq(0)) 

      }
    })
   
    // [案件ID検索結果]
    $(document).off('click.ListEvents').on('click.ListEvents', '[name="ListEvents"]', function() {
      $('.comInfo, .mtg, .reasons, .mtgDetail').slideDown()
      const dataAbout = $(this).data('about')
      const dataRes = $(this).data('res')

      that.inputComName = dataAbout.company_name
      that.inputMtgGDate = dataAbout.mtg_date
      that.inputSearchAPName = dataAbout.ap_name
      that.inputApTypeName = dataAbout.ap_type_name.replace('🎦', 'オンライン')

      if (dataRes.mtg_type.includes('オンライン')) {
        $('.is_hearing_cessation').show()
        $('.is_hearing_no_meet').hide()
        $('.is_hearing_cessation_no_meet').hide()
      } else {
        $('.is_hearing_cessation').hide()
        $('.is_hearing_no_meet').show()
        $('.is_hearing_cessation_no_meet').hide()
      }

      that.inputType = dataRes.ap_type
      that.resCalId = dataRes.cal_id
      that.resEventId = dataRes.event_id

      let date = that.inputMtgGDate.split('（')[0].replace(/\//g, '-')
      let start = that.inputMtgGDate.split('）')[1].split('〜')[0]
      let end = that.inputMtgGDate.split('）')[1].split('〜')[1]
      
      $('#ComName').val(that.inputComName)
      $('#MtgDate').val(date)
      $('#MtgStartTime').val(start)
      $('#MtgEndTime').val(end)
      $('#MtgDate, #ComName').attr('readonly', true)
      $('#MtgStartTime, #MtgEndTime').attr('readonly', false)

      if (that.inputSearchAPName == 'undefined') that.inputSearchAPName = ''
      $('#searchAPName').val(that.inputSearchAPName)
      $('#searchAPName').trigger('input')

      let startIdName = 'MtgStartTime' 
      let endIdName = 'MtgEndTime'
      func.startEndTime(startIdName, endIdName)

      if(that.inputType == '再訪') {
        $('#typeRevisit').prop({'checked': true, 'disabled': true})
        $('#typeNew').prop({'disabled': true})
        $('#wrapEvaluationReason, #wrapFB').slideUp(500)
        $('#FB, #Detail, .chip, #FBGood, #FBMore, #evaluation_F, #chargeName, input[name="product"]').removeClass('required') 
        $(".previous_result_form").show()
        $("#previous_result").addClass("required")
      } else if(that.inputType !== '再訪') {
        $('#typeNew').prop({'checked': true, 'disabled': true})
        $('#typeRevisit').prop({'disabled': true})
        $('#wrapEvaluationReason, #wrapFB').slideDown(500)
        $('#FB, #Detail, .chip, #FBGood, #FBMore, #evaluation_F, #chargeName, input[name="product"]').addClass('required') 
        $(".previous_result_form").hide()
        $("#previous_result").val("").removeClass("required")
      }
    })

    // カレンダー
    $('#MtgDate, #ContactDeadline, #BalanceDeadline').off('click.MtgDate').on('click.MtgDate', function() {
      let _this = $(this)
      let date = _this.val()
      let current = date && date.match(/[0-9]{4}\/[0-9]{2}\/[0-9]{2}/)? date: moment().toDate()
      /*
      _this.datepicker("dialog", current, function(date) {
        _this.val(date)
      }, {
        dateFormat : 'yy/mm/dd',
        monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
      })*/
    })

    // 増減ボタン
    $('#form_ap').off("click.form_add").on("click.form_add", ".form_add", function() {
      let clone = $(this).closest('.c-variables__item').clone(true)
      clone.find('.-ok').remove()
      clone.insertAfter($(this).closest('.c-variables__item')).children('.emails').val('')
    });

    $(document).off("click.form_del").on("click.form_del", ".form_del", function() {
      let target = $(this).closest('.c-variables__item');
      if (target.parent().children().length > 1) {
        target.remove();
      }
    });

    // [種別]
    $('#form_ap').off('click.apoType').on('click.apoType', '[name="type"]', function() {
      let typeVal = func.checkedVal($('[name="type"]'))
      $('#Detail, #FB').val('')

      if(typeVal == '新規') {
        $('#wrapEvaluationReason, #wrapFB').show()
        $('#FB, #Detail, .chip, #FBGood, #FBMore, #evaluation_F').addClass('required')
      } else if(typeVal !== '新規') {
        $('#wrapEvaluationReason, #wrapFB').hide()
        $('#FB, #Detail, .chip, #FBGood, #FBMore, #evaluation_F').removeClass('required')
      }
    })

    // [提案内容：アポ評価理由追加]
    $('input#ituzai, input#ec, input#cms').off('click.apoReview').on('click.apoReview', function() {
      const ituzai_dom = $('input#ituzai:checked')
      const ec_dom = $('input#ec:checked')
      const cms_dom = $('input#cms:checked')
      $('#saiyouiyoku_ok, #syuukyaku_ok').removeClass('groupA')

      if(ituzai_dom.length == 1) {
        $('#saiyouiyoku_ok').show(500)
        $('#saiyouiyoku_ok').addClass('groupA')
      } else if(ituzai_dom.length != 1) {
        $('#saiyouiyoku_ok').hide(500)
        $('#saiyouiyoku_ok').removeClass(that.CHECKED_COLOR)
      }

      if(ec_dom.length == 1 || cms_dom.length == 1) {
        $('#syuukyaku_ok').show(500)
        $('#syuukyaku_ok').addClass('groupA')
      } else if(ec_dom.length != 1 && cms_dom.length != 1) {
        $('#syuukyaku_ok').hide(500)
        $('#syuukyaku_ok').removeClass(that.CHECKED_COLOR)
      }
    })

    //[商談結果：表示非表示]
    $('#Result').off('change.result').on('change.result', function() {
      const Result_ele = $('#Result')
      $('#ContactDeadline, #ContactDeadlineDay, #RevisitedStartTime, #PayStatus, #SalesPrice, #Paid, #Balance, #BalanceDeadline, #paymentMethod, #suggestion_price, .result_failure_required, .result_order_failure_required').val('')
      $('.markResult').css('display', 'none')
      $('.markResult').removeClass('markResult')
      $('#ContactDeadline, #PayStatus, #SalesPrice, #Paid, #Balance, #BalanceDeadline, #paymentMethod, .order_reason_required, .result_failure_required, .result_order_failure_required, .annual_income_required').removeClass('required')
      $('#ContactDeadline, .order_reason_required').css('background-color', 'rgb(255, 255, 255)')
      $('.replyDueDate, .test_marketing_authority_agent').show()
      $('.result_order, .result_failure, .result_order_failure').hide()
      $("input[name='order_reason']").prop("checked", false)
      $('#notPaid').slideUp(100)
      $('.final_suggestion_plan_required').addClass('required')

      if(Result_ele.val() == '追客') {
        resetPursuitGuest()
        $('#ContactDeadline, .form_wrap_required').addClass('required')
        $('.order_required, #suggestion_price').removeClass('required')
        if($('[name="is_hearing"][value="できた"]').is(':checked')) {
          $('.result_failure_required, .result_order_failure_required').addClass('required')
          $('.result_failure, .result_order_failure, .form_wrap_pursuit_guest').show()
        } else if ($('[name="is_hearing"][value="できていない"]').is(':checked')) {
          $('.form_wrap_required').removeClass('required')
        } else if ($('[name="is_hearing"][value="不通"]').is(':checked') || $('[name="is_hearing"][value="会えてない"]').is(':checked') || $('[name="is_hearing"][value="不通・会えてない"]').is(':checked')) {
          $('.form_wrap_pursuit_guest').show()
        }
        $('#payInfo, .conditional_question').hide()
      } else if (Result_ele.val() == '受注' || Result_ele.val() == '受注済み決済' || Result_ele.val() == '受注済み打ち合わせ' || Result_ele.val() == '即決') {
        $('#payInfo').show()
        $('#PayStatus, #SalesPrice, #Paid, #Balance, #paymentMethod, .order_required, #suggestion_price').addClass('required')
        $('.saled').slideDown()
        $('.saled').addClass('markResult')
        if ((Result_ele.val() == '即決' || Result_ele.val() == '受注') && $('[name="is_hearing"][value="できた"]').is(':checked')) {
          $('.result_order, .result_order_failure, .annual_income').show()
          $('.order_reason_required, .result_order_failure_required, .annual_income_required').addClass('required')
        }
        resetPursuitGuest()
      } else if (Result_ele.val() == '失注') {
        $('#payInfo').hide()
        $('.order_required, #suggestion_price').removeClass('required')
        if($('[name="is_hearing"][value="できた"]').is(':checked')) {
          $('.result_failure, .result_order_failure').show()
          $('.result_failure_required, .result_order_failure_required').addClass('required')
        }
        resetPursuitGuest()
      } else if (Result_ele.val() == '現場リスケ' || Result_ele.val() == '現場キャンセル') {
        $('#payInfo, .replyDueDate').hide()
        $('.order_required, #suggestion_price').removeClass('required')
        resetPursuitGuest()
      }

      const post_prefix = $('#SubjectId').val().slice(0, 2)
      if (post_prefix != "YD" && post_prefix != "KS") {
        $('.test_marketing_authority, .test_marketing_authority_agent').hide()
        $('.test_marketing_authority_required').removeClass('required')
      }
    })

    function resetPursuitGuest() {
      $(".form_wrap_pursuit_guest").hide()
      $(".form_wrap_required, .issue_required, .wish_settlement_method_required, .confidant_pulldown_required, .other_confidant_required").removeClass("required")
      $("[name='timing']:checked").prop("checked", false)

      const formContainers = document.querySelectorAll('.form_wrap_pursuit_guest')
      formContainers.forEach(container => {
        const selects = container.querySelectorAll('select')
        selects.forEach(select => {
          select.selectedIndex = 0
        })
        const radioButtons = container.querySelectorAll('input[type="radio"]')
        radioButtons.forEach(radio => {
          radio.checked = false
        })
      })
    }

    $('[name="is_hearing"]').change(function() {
      $('#ContactDeadline, #ContactDeadlineDay, #RevisitedStartTime, #PayStatus, #SalesPrice, #Paid, #Balance, #BalanceDeadline, #paymentMethod').val('')
      $('.markResult').css('display', 'none')
      $('.markResult').removeClass('markResult')
      $('#ContactDeadline, #PayStatus, #SalesPrice, #Paid, #Balance, #BalanceDeadline, #paymentMethod').removeClass('required')
      $('#ContactDeadline').css('background-color', 'rgb(255, 255, 255)')
      $('#notPaid').slideUp(100)

      if (this.value == "できた") {
        $('#hearing_display').hide()
        $('.hearing_required').removeClass('required')
        $("[name='hearing_result']:checked").prop("checked", false)
        $('#Result').val('').prop('disabled', false)

      } else if (this.value == "できていない") {
        resetPursuitGuest()
        $('#hearing_display').show()
        $('#payInfo').hide()
        $('.hearing_required').addClass('required')
        $('#Result').prop('disabled', true)
        $('.result_order, .result_order_other, .result_failure, .result_order_failure').hide()
        $('.result_failure_required, .result_order_failure_required').val('').removeClass('required')
        $('.order_reason_required').removeClass('required').css('background-color', 'rgb(255, 255, 255)')

      } else if (this.value == "不通" || this.value == "会えてない" || this.value == "不通・会えてない") {
        $('#hearing_display').hide()
        $('.hearing_required').removeClass('required')
        $("[name='hearing_result']:checked").prop("checked", false)
        $('#Result').val('').prop('disabled', false)
        $('.result_order, .result_order_other, .result_failure, .result_order_failure').hide()
        $('.result_failure_required, .result_order_failure_required').val('').removeClass('required')
        $('.order_reason_required').removeClass('required').css('background-color', 'rgb(255, 255, 255)')
      }
    })

    $('[name="hearing_result"]').change(function() {
      if (this.value == "追客") {
        $('#Result').val("追客")
        $('#revisit_radio_button').show()
        $('#ContactDeadline, .revisit_radio_button_required').addClass("required")
      } else {
        $('#Result').val("失注")
        $('#revisit_radio_button').hide()
        $('#ContactDeadline, .revisit_radio_button_required').removeClass("required")
        $('#ContactDeadline').css('background-color', 'rgb(255, 255, 255)')
      }
      $('#Result').trigger('change')
    })

    $('[name="issue_extraction"]').change(function() {
      if (this.value == "課題抽出あり") {
        $('#issue_radio_button').show()
        $('.issue_required').addClass('required')
      } else {
        $('#issue_radio_button').hide()
        $('.issue_required').removeClass('required')
      }
    })

    $('[name="settlement_method"]').change(function() {
      if (this.value == "提案済み(確定)") {
        $('#wish_settlement_method_radio_button').show()
        $('.wish_settlement_method_required').addClass('required')
      } else {
        $('#wish_settlement_method_radio_button').hide()
        $('.wish_settlement_method_required').removeClass('required')
      }
    })

    $('#consultation_pulldown').change(function() {
      if (this.value == "なし") {
        $('#confidant, #other_confidant_name').val("")
        $('#confidant_pulldown, #other_confidant').hide()
        $('.confidant_pulldown_required, .other_confidant_required').removeClass('required')
      } else {
        $('#confidant_pulldown').show()
        $('.confidant_pulldown_required').addClass('required')
      }
    })

    $('#confidant').change(function() {
      if (this.value == "その他") {
        $('#other_confidant').show()
        $('.other_confidant_required').addClass('required')
      } else {
        $('#other_confidant').hide()
        $('.other_confidant_required').removeClass('required')
      }
    })

    $('[name="revisit"]').change(function() {
      if (this.value == "有り") {
        $('#display_revisit_annotation').show()
      } else {
        $('#display_revisit_annotation').hide()
      }
    })

    // [決済進捗]
    $('#PayStatus').off('change.PayStatus').on('change.PayStatus', function() {
      const Paystatus_ele = $('#PayStatus')
      $('#SalesPrice, #Paid, #Balance').attr('readonly',true)
      $('#SalesPrice, #Paid, #Balance, #paymentMethod').val('')
      $('#notPaid, #autoCalculation').hide()
      $('#notPaid').val('')
      $('#BalanceDeadline, #paymentMethod').removeClass('required')
      $('.formPayment_option').css('display', 'none')

      if(Paystatus_ele.val() == '決済完了') {
        $('#SalesPrice').attr('readonly', false) // 受注金額有効
        $('#BalanceDeadline').val('') //残高期日初期化
        $('#Balance').val('0')
        $('#paymentMethod').val('')
      } else if(Paystatus_ele.val() == '一部決済') {
        // 受注金額・決済済み金額・残高有効
        $('#SalesPrice, #Paid, #Balance').attr('readonly', false)
        $('#notPaid').show()
        $('#BalanceDeadline').addClass('required')
        $('.formPayment_option').css('display', 'block')
        $('#paymentMethod').addClass('required')
      } else if(Paystatus_ele.val() == '未決済') {
        // 受注金額有効
        $('#SalesPrice, #Balance').attr('readonly', false)
        $('#notPaid').show()
        $('#BalanceDeadline').addClass('required')
        $('#Paid').val('0')
        $('.formPayment_option').css('display', 'block')
        $('#paymentMethod').addClass('required')
      }
    })

    // [受注金額・決済済み金額・残高]
    $('#SalesPrice, #Paid, #Balance').off('input.paid').on('input.paid', function() {
      const regNum = /[0-9]+/g
      let PayStatus_ele_input = $('#PayStatus').val()
      let sales_price = $('#SalesPrice').val() //受注金額
      let paid_price = $('#Paid').val()        //決済済み金額
      let balance_price = $('#Balance').val()  //残高
      sales_price = func.hankakusuji(sales_price)
      paid_price = func.hankakusuji(paid_price)
      balance_price = func.hankakusuji(balance_price)

      if (regNum.test(sales_price)) {
        if(PayStatus_ele_input == '決済完了' && sales_price !== "") {
          sales_price = func.commaStr2Num(sales_price)
          $('#Paid').val(sales_price)
          $('#SalesPrice').val(sales_price)
          $('#Balance').val('0')
        } else if(PayStatus_ele_input == '一部決済') {
          if(sales_price !== "") sales_price = func.commaStr2Num(sales_price)
          if(paid_price !== "") paid_price = func.commaStr2Num(paid_price)
          if(balance_price !== "") balance_price = func.commaStr2Num(balance_price)
  
          $('#Paid').val(paid_price)
          $('#SalesPrice').val(sales_price)
          $('#Balance').val(balance_price)
        } else if(PayStatus_ele_input == '未決済' && sales_price != "") {
          sales_price = func.commaStr2Num(sales_price)
          $('#SalesPrice').val(sales_price)
          $('#Balance').val(sales_price)
          $('#Paid').val('0')
        }
      } else {
        alert ('半角数字で入力お願いします。')
        $('#Paid, #SalesPrice, #Balance').val('')
      }
    })

    $('#last_suggestion_price').off('input.last_suggestion_price').on('input.last_suggestion_price', function() {
      const regNum = /[0-9]+/g
      let last_suggestion_price = $('#last_suggestion_price').val()
      last_suggestion_price = func.hankakusuji(last_suggestion_price)
      last_suggestion_price = func.commaStr2Num(last_suggestion_price)
      $('#last_suggestion_price').val(last_suggestion_price)
      if (regNum.test(last_suggestion_price) || last_suggestion_price == "") {
        console.log("問題なし")
      } else {
        alert ('半角数字で入力お願いします。')
        $('#last_suggestion_price').val('')
      }
    })

    // [アポ評価理由]
    $('.reasons_li').off('click.reasons').on('click.reasons', function() {
      const this_dom = $(this)

      if(this_dom.parent().hasClass(that.CHECKED_COLOR)) {
        this_dom.parent().removeClass(that.CHECKED_COLOR)
      } else {
        this_dom.parent().addClass(that.CHECKED_COLOR)
      }
    })

    //[必須項目判定]
    $('#form_ap').off('change.checkRequired input.checkRequired click.checkRequired mousemove.checkRequired').on('change.checkRequired input.checkRequired click.checkRequired mousemove.checkRequired', function() {
      let resultRequiredArray = func.checkRequired()
      let required_NG = 0
      let check_required = 0

      for(let key in resultRequiredArray) {
        resultRequiredArray[key] == 0 ? required_NG = 1 : check_required = 1
      }

      if(check_required == 1 && required_NG == 0) {
        $('#btn').removeClass('disabled').addClass('pulse')
      }

    })

    //[顧客情報:非表示]
    $('#noEmail').off('click.noEmail').on('click.noEmail', function() {
      $('#CostomerEmail1, #CostomerEmail2, #CostomerEmail3').val('')
      if($('#noEmail:checked').length == 1) {
        $('.fild_email').slideUp(500)
      } else {
        $('.fild_email').slideDown(500)
      }
    })

    //[日付制限(遡り防止)]
    $('#ContactDeadline, #BalanceDeadline').change(function() {
      let formated_today = moment().format('YYYY-MM-DD')
      $('#ContactDeadlineCheck').text("")
      $('#BalanceDeadlineCheck').text("")

      if ($('#ContactDeadline').val() < formated_today && $('#ContactDeadline').val() != "") {
        $('#ContactDeadlineCheck').text('※ 本日以降の日付を入力してください').css('color', '#e91e63')
        $('#ContactDeadline').val("")
      }
      
      if ($('#BalanceDeadline').val() < formated_today && $('#BalanceDeadline').val() != "") {
        $('#BalanceDeadlineCheck').text('※ 本日以降の日付を入力してください').css('color', '#e91e63')
        $('#BalanceDeadline').val("")
      }
      
    })

    // [アポ評価条件分岐]
    const checkboxes = document.querySelectorAll('.evaluation_D_checkbox')
    let checkedProduct = []
    $('#start_evaluation').hide()

    // 商材で分岐
    $('input[name="product"]').click(function() {
      $('#start_evaluation').show()
      $('#evaluation_F').addClass('required')
      checkedProduct = $('input[name="product"]:checked').map(function() {
        return $(this).val()
      }).get()

      const index_welbox = checkedProduct.some(value => value === "welbox")
      const index_indeed = checkedProduct.some(value => value === "indeed")
      const element = document.getElementById('decision_11') //商材で分岐する質問の直前の質問

      if (element.value == "該当") {
        $('#last').addClass('required').val("")
        $('.last').show()
        $('#welbox, #indeed').removeClass('required').val("")
        $('.welbox, .indeed').hide()
        
        if (index_welbox) {
          $('#welbox').addClass('required')
          $('.welbox').show()
          $('#last').removeClass('required').val("")
          $('.last').hide()
        }
        if (index_indeed) {
          $('#indeed').addClass('required')
          $('.indeed').show()
          $('#last').removeClass('required').val("")
          $('.last').hide()
        }
      }

    })

    // F評価
    $('#evaluation_F').change(function() {
      if ($(this).val() == '該当') {
        $('.is_evaluation_F').hide()
        $('#evaluation_E').val("")
        $('.removeRequired').removeClass('required')
        checkboxes.forEach(checkbox => {
          checkbox.checked = false
        })
      } else {
        $('.column_evaluation_E').show()
        $('#evaluation_E').addClass('required')
      }
    })

    // E評価
    $('#evaluation_E').change(function() {
      if ($(this).val() == '該当') {
        $('.is_evaluation_E').hide()
        $('#is_evaluation_D').removeClass('required')
        $('.evaluation_D_checkbox').removeClass('required')
        $('.removeRequired').removeClass('required')
        checkboxes.forEach(checkbox => {
          checkbox.checked = false
        })
      } else {
        $('.column_evaluation_D').show()
        $('.is_evaluation_D').show()
        $('#is_evaluation_D').val("")
        $('#is_evaluation_D').addClass('required')
        $('.evaluation_D_checkbox').addClass('required')
      }
    })

    // D評価 該当非該当
    $('#is_evaluation_D').change(function() {
      $('#is_evaluation_D').removeClass('required')
      if ($(this).val() == '該当') {
        $('.column_evaluation_C').hide()
        $('#evaluation_question_1').val("")
        $('.is_evaluation_D').show()
        $('.evaluation_D_checkbox').addClass('required')

      } else {
        checkboxes.forEach(checkbox => {
          checkbox.checked = false
        })
        $('.is_evaluation_D').hide()
        $('.evaluation_question_1').show()
        $('#evaluation_question_1').addClass('required')
      }
    })

    // D評価 チェックボックス
    $('.evaluation_D_checkbox').change(function() {
      const isAnySelected = Array.from(checkboxes).some(checkbox => checkbox.checked)
      if (isAnySelected) {
        $('.column_evaluation_C').hide()
        $('#evaluation_question_1').val("")
        $('.removeRequired').removeClass('required')
        $('#is_evaluation_D').val("該当")
        $('#is_evaluation_D').removeClass('required')
        $('#is_evaluation_D').css('background-color', 'rgb(255, 255, 255)')
      } else {
        $('.evaluation_D_checkbox').addClass('required')
        $('#is_evaluation_D').val("")
        $('#is_evaluation_D').addClass('required')
      }
    })

    // C以上評価
    $('.evaluation_question_C').change(function() {
      const index_welbox = checkedProduct.some(value => value === "welbox")
      const index_indeed = checkedProduct.some(value => value === "indeed")
      that.inputEvaluation = 'C'
      $('.removeRequired').removeClass('required')
      $('.required_color.int_required_color').css('background-color', '')
      const selectedValue = $(this).val()
      const selectId = this.id
      const IDNumber = selectId.match(/\d+/)
      $('#last, #welbox, #indeed').removeClass('required')
      $('.last, .welbox, .indeed').hide()

      // 最終項目の表示切替
      if (selectId == "welbox" || selectId == "indeed") {
        if (index_welbox) { $('.welbox').show() }
        if (index_indeed) { $('.indeed').show() }
        if ($('#welbox').val()) { $('#welbox').addClass('required') }
        if ($('#indeed').val()) { $('#indeed').addClass('required') }
        if (index_welbox && index_indeed) {
          if ($('#welbox').val() == "該当" && $('#indeed').val() == "該当") {
            $('#last').addClass('required').val("")
            $('.last').show()
          }
        } else {
          if (selectedValue === "該当") {
            $('#last').addClass('required').val("")
            $('.last').show()
          }
        }
      }

      // 最後の評価項目による最終ジャッジ
      if (selectId === "last") {
        that.inputEvaluation = "B"
        $('.last').show()
        if (index_welbox) { $('.welbox').show() }
        if (index_indeed) { $('.indeed').show() }
        const is_question_3 = $('#evaluation_question_3').val() == "該当"
        const is_question_10 = $('#evaluation_question_10').val() == "該当"

        if (!is_question_3) { return } // 費用認識が無い場合B評価で確定

        if (selectedValue == "該当") {
          that.inputEvaluation = "S"
        } else if (is_question_10) {
          that.inputEvaluation = "A"
        }

        return
      }

      if (!IDNumber) { return } 

      let selectNumber = parseInt(IDNumber[0], 10)

      // 質問戻って選択した時の為、後の項目を非表示＆リセット
      $('.evaluation_question_C').each(function() {
        const id = this.id
        const matchResult  = id.match(/\d+/)
        if (!matchResult) { return } 
        const currentNumber = parseInt(matchResult[0], 10)
        if (currentNumber >= selectNumber && !selectId.includes("next")) {
          $('#decision_next_' + currentNumber).val("")
          $('.decision_next_' + currentNumber).hide()
        }

        if (currentNumber > selectNumber) {
          $('#evaluation_question_' + currentNumber).val("")
          $('.evaluation_question_' + currentNumber).hide()
          $('#decision_' + currentNumber).val("")
          $('.decision_' + currentNumber).hide()
        }

        if (selectId.includes('evaluation_question') && currentNumber == selectNumber) {
          $('#decision_' + currentNumber).val("")
          $('.decision_' + currentNumber).hide()
        }
      })

      // 各質問回答による次の質問表示切替
      if (selectedValue == "該当") {

        if (selectId == "decision_next_7") { //この質問は該当でも次へ進まない
          return
        }

        if (IDNumber == 11 || selectId == "welbox" || selectId == "indeed") { //選択商材による分岐
          if (index_welbox) {
            $('#welbox').addClass('required').val("")
            $('.welbox').show()
          }
          if (index_indeed) {
            $('#indeed').addClass('required').val("")
            $('.indeed').show()
          }
          if (!index_welbox && !index_indeed) {
            $('#last').addClass('required').val("")
            $('.last').show()
          }
          return
        }

        selectNumber = selectNumber + 1
        $('.evaluation_question_' + selectNumber).show()
        $('#evaluation_question_' + selectNumber).addClass('required')

        if (IDNumber == 3 || IDNumber == 5 || IDNumber == 7 || IDNumber == 10) { // 次の項目がdecisionのid（非該当で即C評価）の場合
          $('#decision_' + selectNumber).addClass('required')
        }

      } else if (selectedValue == "非該当") {

        if (selectId == "decision_next_7") { //この質問は次へ進まない
          that.inputEvaluation = 'D'
          return
        }

        if (selectId.includes('decision')) {
  
          if (selectId == "decision_7") { //この質問はさらに次の分岐へ進む
            $('#decision_next_' + selectNumber).addClass('required')
            $('.decision_next_' + selectNumber).show()
            return
          }

          if (IDNumber == 6) {
            that.inputEvaluation = 'D'
          }

        } else {
          $('#decision_' + selectNumber).addClass('required')
          $('.decision_' + selectNumber).show()
        }

      }
    })

    function searchUser(userName, htmlID, className) {
      if (className == 'ap_name') {
        that.searchFlagAP = true
      } else if (className == 'passer_name') {
        that.searchFlagPasser = true
      }

      $('.msg_loading, .' + className).remove()
      $('<label class="pd5_rl msg_loading">検索中(_ _).｡o○</label>').appendTo("#" + htmlID)

      if(userName == "") {
        $('.msg_loading, .' + className).remove()
        $('.' + className + '_label').hide()
        return false
      }

      $('.' + className + '_label').show()
      that.abortController = new AbortController()

      const params = {
        authC: {
          password: "W!z|a~.x.T~Tzca8CBCm",
          mail: that.userEmail
        },
        main_data: {
          tables:["users"],
          query: `SELECT * FROM users WHERE (first_name LIKE "%${userName}%" OR business_name LIKE "%${userName}%") AND retirement_date IS NULL AND deleted_at IS NULL`
        },
        support_data: {
          is_prod: that.isProd
        }
      }

      const color = '#e91e63'
      const searchApiURL = that.dbApiURL + 'db/select'

      that.vueObj.viewLoader('同行者検索中')

      fetch(searchApiURL, {
        signal: that.abortController.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Cal-St-Api': that.key
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(response => {
        const mes = $('.msg_loading')
        let text = ''
        const res = typeof response == 'String'? response - 0 : response 

        switch(res) {
          case 0: {
            if(response[0].user_id !== '#N/A') {
              mes.remove()
              formFunction.createUserList(response, htmlID, className)
            } else {
              text = '検索できませんでした。検索ワードをもう一度確認してから検索してください。'
            }
            break
          }
          case 1: {
            text = 'アクセスが許可されていません。'
            break
          }
          case 2: {
            text = 'リクエストデータがありません。'
            break
          }
          case 3: {
            text = '認証が通りませんでした。'
            break
          }
          case 4: {
            text = '検索結果が50件を越えています。検索結果を絞るために文字を追加してください。'
            break
          }
          case 99: {
            text = '何らかのエラーが発生しました。'
            break
          }
        }

        if(text) {
          mes.text(text).css('color', color)
          alert(text)
        }

        if(response[0].user_id !== '#N/A') {
          mes.remove()
          formFunction.createUserList(response, htmlID, className)
        }

        that.vueObj.viewLoader()

        return response

      })
      .catch(error => {
        console.log({error})
        that.vueObj.viewLoader()
        if(error.name != "AbortError") {
          $('.msg_loading').text('エラー').css('color', color)
          if (className == 'ap_name') {
            that.searchFlagAP = false
          } else if (className == 'passer_name') {
            that.searchFlagPasser = false
          }
        }
      })
    }

    //担当AP検索
    $('#searchAPName').off('input.searchAP').on('input.searchAP', function(e) {
      $('#ap_retiremented').prop('checked', false)
      const htmlID = "field_ap_name"
      const className = "ap_name"
      let userName = $(this).val()
      if(that.intervalObj) {
        clearInterval(that.intervalObj)
        if(that.abortController && typeof that.abortController.abort == 'function') that.abortController.abort()
        that.abortController = new AbortController()
      }

      that.intervalObj = setTimeout(() => {
        searchUser(userName, htmlID, className)
      }, 700)
    })

    //パサー検索
    $('#searchPasserName').off('input.searchPasser').on('input.searchPasser', function(e) {
      const htmlID = "field_passer_name"
      const className = "passer_name"
      let userName = $(this).val()
      if(that.intervalObj) {
        clearInterval(that.intervalObj)
        if(that.abortController && typeof that.abortController.abort == 'function') that.abortController.abort()
        that.abortController = new AbortController()
      }

      that.intervalObj = setTimeout(() => {
        searchUser(userName, htmlID, className)
      }, 700)
    })

    //担当AP退職済み処理
    $('#ap_retiremented').change(function() {
      if ($(this).prop('checked')) {
        $('#searchAPName').val('').removeClass('required').css('background-color', '')
        $('.msg_loading, .ap_name').remove()
        $('.ap_name_label').hide()
        that.searchFlagAP = true
      } else {
        $('#searchAPName').addClass('required').css('background-color', 'rgb(255, 205, 210)')
      }
    })

    $('[name="type"]').change(function() {
      const type = $("input[name='type']:checked").val()
      if (type == "再訪") {
        $(".previous_result_form").show()
        $("#previous_result").addClass("required")
      } else {
        $(".previous_result_form").hide()
        $("#previous_result").val("").removeClass("required")
      }
    })

    $('[name="Suggestion"]').change(function() {
      const isEasierSuggestion = $("input[name='Suggestion']:checked").get().some(input => input.value === "Easier")

      if (isEasierSuggestion) {
        $('.dedicated_question_easier').show()
        $('.easier_question_required, .easier_radio_required').addClass("required")
      } else {
        $('.dedicated_question_easier').hide()
        $('.easier_question_required').val("").removeClass("required")
        $('.easier_radio_required').removeClass("required")
        $("input[name='crisis_self_assessment']").prop("checked", false)
        $("input[name='gap_common_understanding']").prop("checked", false)
        $("input[name='measure_necessity']").prop("checked", false)
        $("input[name='customer_will_level']").prop("checked", false)
      }
    })

    $("input[name='crisis_self_assessment'], input[name='gap_common_understanding'], input[name='customer_will_level'], input[name='measure_necessity']").on("change", function() {
      $(this).closest("ul").find("input").removeClass("required").siblings("span").css("backgroundColor", "rgb(255, 255, 255)")
    })

    $('#Result').change(function() {
      const result = $(this).val()
      $('.possible_reason_order_form, .possible_reason_failure_form, .possible_reason_pursuit_form').hide()

      if (result == "即決" || result == "受注") {
        $('.possible_reason_order_form').show()
        $('#possible_reason_order, #possible_reason_order_detail').addClass("required")
        $('#possible_reason_failure, #possible_reason_failure_detail, #possible_reason_pursuit').val("").removeClass("required")
      } else if (result == "追客") {
        $('.possible_reason_pursuit_form').show()
        $('#possible_reason_pursuit').addClass("required")
        $('#possible_reason_order, #possible_reason_order_detail, #possible_reason_failure, #possible_reason_failure_detail').val("").removeClass("required")
      } else if (result == "失注") {
        $('.possible_reason_failure_form').show()
        $('#possible_reason_failure, #possible_reason_failure_detail').addClass("required")
        $('#possible_reason_order, #possible_reason_order_detail, #possible_reason_pursuit').val("").removeClass("required")
      } else {
        $('#possible_reason_order, #possible_reason_order_detail, #possible_reason_pursuit, #possible_reason_failure, #possible_reason_failure_detail').val("").removeClass("required")
      }
    })

     //送信用メッセ
    document.getElementById("btn").onclick = function exchange() {
      if (!that.searchFlagAP) {
        alert("担当AP名でエラーが出ています！\n入力内容を確認してください\n担当APが退職済みの場合は退職済みのチェックを入れてください")
        return
      } else if (!that.searchFlagPasser) {
        alert("パサー名でエラーが出ています！\n入力内容を確認してください")
        return
      }

      if($('#btn').hasClass('pulse')) {
        let confirmMsg  = "", 
        confirmPayInfo = ""
        that.inputComName = $.trim($('#ComName').val())
        that.inputMtgDate = $('#MtgDate').val().replace(/(\d{4})-(\d{2})-(\d{2})/, '$1/$2/$3')
        that.inputResult = $('#Result').val()
        that.inputpaymentMethod = $('#paymentMethod').val() == null ? '' : $('#paymentMethod').val()
        $('#PayStatus').val() == null ? that.inputPayStatus = '' : that.inputPayStatus = $('#PayStatus').val()

        var selectedValues = $('.evaluation_D_checkbox:checked').map(function() {
          return this.value
        }).get()

        // メアドチェック
        let mailCheckFlag = false
        $.each($('.emails'), (i, elm) => {
          const email = $('.emails').eq(i)
          const val = email.val()
          if(val && !val.match(/.+?@.+?/)) {
            email.val('')
            mailCheckFlag = true
          }
        })

        // 最終的なアポ評価ランクを取得
        const isAnySelected = Array.from(checkboxes).some(checkbox => checkbox.checked)
        if ($('#evaluation_F').val() == '該当') {
          that.inputEvaluation = 'F'
        } else if ($('#evaluation_E').val() == '該当') {
          that.inputEvaluation = 'E'
        } else if (isAnySelected) {
          that.inputEvaluation = 'D'
        }
    
        if($('#SalesPrice').val() != '') {
          that.inputSalesPrice = $('#SalesPrice').val() +"円"
          that.inputPaid = $('#Paid').val()+"円"
          that.inputBalance = $('#Balance').val()+"円"
          confirmPayInfo = "\n◎入金\n" + "決済進捗：" + that.inputSalesPrice + "\n" + "受注金額：" + that.inputSalesPrice + "\n" + "決済済み金額：" + that.inputPaid + "\n" + "残高：" + that.inputBalance + "\n" + "支払い方法：" + that.inputpaymentMethod
        }
        confirmMsg = "案件名：" + that.inputComName + "\n" + "商談結果：" + that.inputResult + "\n" + confirmPayInfo

        let checkPostFlg = window.confirm('下記内容で間違いないですか？必ず確認してください。\n\n'+confirmMsg)
      
        if(checkPostFlg) {
          // [値取得]
          that.inputGender = func.checkedVal($('[name="gender"]'))
          that.inputSuggestion = func.checkedVal($('[name="Suggestion"]'))
          that.inputMtgPerson =  func.checkedVal($('[name="MtgPerson"]'))
          that.inputContactDeadline = $('#ContactDeadline').val()
          that.inputRevisitedStartTime = $('#RevisitedStartTime').val()
          that.inputType = func.checkedVal($('[name="type"]'))
          that.inputDetail = $('#Detail').val()
          that.inputMtgDetail = $('#MtgDetail').val()
          that.inputFBGood = $('#FBGood').val()
          that.inputFBMore = $('#FBMore').val()
          that.inputCampaign = func.checkedVal($('[name="guide_campaign"]'))
          that.inputSubsidy = func.checkedVal($('[name="guide_subsidy"]'))
          that.inputJobOfferCampaign = func.checkedVal($('[name="guide_job_offer_campaign"]'))
          that.inputLastSuggestionPrice = $('#last_suggestion_price').val() == "" ? "" : $('#last_suggestion_price').val() + "円" 
          that.inputPasserName = $('#passer_name').val()
          that.inputCostomerEmail = func.getInputData($('.emails'))
          that.inputMtgStartTime = $('#MtgStartTime').val()
          that.inputMtgEndTime = $('#MtgEndTime').val()
          if(! that.inputSubjectId) that.inputSubjectId = $('#SubjectId').val()

          that.inputType == '新規' ? that.inputEvaluationReason = that.inputEvaluation : that.inputEvaluationReason = ''

          if(that.inputType == '再訪') that.inputEvaluation = ''
    
          // [整形]
          that.inputCeoName = func.textEscape($.trim($('#CeoName').val()))
          that.inputSearchAPName = func.textEscape($.trim($('#searchAPName').val()))
          that.inputContactDeadline = $('#ContactDeadline').val().replace(/(\d{4})-(\d{2})-(\d{2})/, '$1/$2/$3')
          that.inputBalanceDeadline = $('#BalanceDeadline').val().replace(/(\d{4})-(\d{2})-(\d{2})/, '$1/$2/$3')

          let WeekChars = [ "日", "月", "火", "水", "木", "金", "土" ]
    
          if(that.inputMtgDate != '') {
            that.inputMtgDay = "（" + WeekChars[new Date(that.inputMtgDate).getDay()]+"）"
          }
  
          if(that.inputContactDeadline != '') {
            that.inputContactDeadlineDay = "（" + WeekChars[new Date(that.inputContactDeadline).getDay()] + "）"
          }
    
          if(that.inputBalanceDeadline != '') {
            that.inputBalanceDeadlineDay = "（" + WeekChars[new Date(that.inputBalanceDeadline).getDay()] + "）"
          }

          that.inputRemark = func.textEscape($('#Remark').val())
          that.inputRecord = $('#record_data').val()

          // [data-id取得]
          let tn_user_id = that.store.state.userData.userID
          that.dataIDs.subject_id = that.inputSubjectId
          that.dataIDs.user_sg_id = that.store.state.userData.id
          that.dataIDs.negotiation_result = $('#Result :selected').data('id')
          that.dataIDs.gender_id = $('[name="gender"]:checked').data('id')
          that.dataIDs.payment_status_id = $('#PayStatus option:selected').data('id')
          that.dataIDs.suggestion_id = func.getDataAttrAry($('[name="Suggestion"]:checked'), 'id')
          that.dataIDs.positions_id = func.getDataAttrAry($('[name="MtgPerson"]:checked'), 'id')
          that.dataIDs.ap_evaluation = that.inputEvaluation

          //[投稿画面バージョン]
          let Extension_version = that.store.state.version

          const post_prefix = that.inputSubjectId.slice(0, 2)

          if (that.isProd) {
            if (post_prefix == "YD" || post_prefix == "KS") {
              that.slackChannel.id = "C088B7JG9JA"
              that.groupURL = that.slackChannel.workSpaceURL + that.slackChannel.id
            }
          }

          let group_id = that.slackChannel.id

          // talknote投稿
          ;(async function() {
        
            let tn_user_id = that.store.state.userData.userID
            let postID = func.getPostID(that.inputSubjectId, that.slackChannel.userID, group_id)
            const selectedAp = document.querySelector('input[name="ap_name"]:checked')
            const selectedApData = selectedAp ? selectedAp.getAttribute('data-user') : ""
            const parsedAPData = selectedApData ? JSON.parse(selectedApData) : ""
            const apSlackId = parsedAPData ? "\n担当AP：<@" + parsedAPData.slack_id + ">" : ""
            const retiremented = $('#ap_retiremented').prop('checked') ? "\n担当AP：" : ""
            const selectedPasser = document.querySelector('input[name="passer_name"]:checked')
            const selectedPasserData = selectedPasser ? selectedPasser.getAttribute('data-user') : ""
            const parsedPasserData = selectedPasserData ? JSON.parse(selectedPasserData) : ""
            const passerSlackId = parsedPasserData ? "\nパサー：<@" + parsedPasserData.slack_id + ">" : ""

            let evaluation = ""
            let isRevisit = ""
            let isRevisitText = ""
            let revisitDay = ""
            let revisitDayText = ""
            let confidant = ""
            const result = $("#Result").val()
            let evaluationResult = that.inputResult

            if (result == "追客" || $('[name="hearing_result"]:checked').val()) {
              const negotiationStatus = $('#negotiationStatus').val()
              isRevisit = $("[name='revisit']:checked").val() ? $("[name='revisit']:checked").val() : ""
              isRevisitText = $("[name='revisit']:checked").val() ? "\n再訪設定の有無：" + isRevisit : ""
              if (isRevisit == "有り") {
                revisitDay = $('#ContactDeadline').val()
                revisitDayText = "\n再訪設定日時：" + revisitDay
              }
              evaluation = formFunction.evaluationBranch(negotiationStatus, revisitDay, that.holidays)
              confidant = $('#confidant').val() ? $('#confidant').val() : ""
              confidant = confidant == "その他" ? $('#other_confidant_name').val() : confidant
              evaluationResult = evaluation == "失注B" ? evaluation : that.inputResult + evaluation
            }

            const previousResult = $("#previous_result").val() ? "\r\n前回の商談結果：" + $("#previous_result").val() : ""
            let possibleReason = ""

            if (result == "即決" || result == "受注") {
              possibleReason =  "\r\n考えられる受注要因：" + $("#possible_reason_order").val() +
                                "\r\n考えられる受注要因詳細：" + $("#possible_reason_order_detail").val()
            } else if (result == "追客") {
              possibleReason = "\r\n考えられる追客理由：" + $("#possible_reason_pursuit").val()
            } else if (result == "失注") {
              possibleReason =  "\r\n考えられる失注要因：" + $("#possible_reason_failure").val() +
                                "\r\n考えられる失注要因詳細：" + $("#possible_reason_failure_detail").val()
            }

            let order_product = ""
            let selectedProducts = ""
            if (result == '受注' || result == '受注済み決済' || result == '受注済み打ち合わせ' || result == '即決') {
              selectedProducts = $("input[name='order_product']:checked").map(function() {
                return $(this).val()
              }).get()
              selectedProducts = selectedProducts.join("/")
              order_product = "\r\n受注商材：" + selectedProducts +
                              "\r\n提案金額：" + $("#suggestion_price").val() + "円"
            }

            let orderReasons = $("input[name='order_reason']:checked").map(function() {
              return $(this).val()
            }).get()

            let finalSuggestionPlan = ""
            let resultFailureText = ""
            if (post_prefix == "YD" || post_prefix == "KS") {
              finalSuggestionPlan = "\r\n最終提案プラン：" + $('[name="final_suggestion_plan"]:checked').val()
              if ($('[name="is_hearing"]:checked').val() == "できた") {
                if ($('#Result').val() == "即決" || $('#Result').val() == "受注") {
                  resultFailureText = "\r\n受注理由：" + orderReasons +
                  "\r\n顧客の欲しい求人の年収帯：" + $('#incomeRange').val() +
                  "\r\n事前準備：" + $('#advanceInformation').val()
                } else if ($('#Result').val() == "追客" || $('#Result').val() == "失注") {
                  resultFailureText = "\r\n受注に必要な要素：" + $('#receiveOrderElements').val() +
                  "\r\n顧客の欲しい求人の年収帯：" + $('#incomeRange').val() +
                  "\r\n事前準備：" + $('#advanceInformation').val()
                }
              }
            }

            let allText = {}
            allText.part01 = "◎案件情報 " + "[" + Extension_version + "]" +
            "\r\n案件ID：" + that.inputSubjectId +
            "\r\n案件名：" + that.inputComName +
            "\r\n代表者名：" + that.inputCeoName +
            "\r\nメールアドレス：" + that.inputCostomerEmail +
            apSlackId + retiremented + passerSlackId +
            "\r\n" +
            "\r\n◎商談情報" +
            "\r\n種別：" + that.inputType +
            "\r\n商談日時：" + that.inputMtgDate + that.inputMtgDay + that.inputMtgStartTime + "〜" + that.inputMtgEndTime +
            previousResult +
            "\r\n商談結果：" + evaluationResult + isRevisitText + revisitDayText +
            resultFailureText +
            "\r\n回答期日：" + that.inputContactDeadline + that.inputContactDeadlineDay +
            "\r\n" +
            "\r\n提案内容：" + that.inputSuggestion +
            "\r\n商談相手：" + that.inputMtgPerson +
            "\r\n業種：" + $("#sub_cate").val() +
            possibleReason +
            "\r\n" +
            "\r\n◎入金" +
            "\r\n決済進捗：" + that.inputPayStatus +
            "\r\n受注金額：" + that.inputSalesPrice +
            "\r\n決済済み金額：" + that.inputPaid +
            "\r\n残額：" + that.inputBalance +
            "\r\n残額期日：" + that.inputBalanceDeadline + that.inputBalanceDeadlineDay +
            "\r\n支払い方法：" + that.inputpaymentMethod +
            order_product +
            "\r\n" +
            "\r\n◎アポ評価" +
            "\r\n評価結果：" + that.inputEvaluation +
            // "\r\n理由："+inputEvaluationReason+
            "\r\n詳細：" + that.inputDetail +
            "\r\n\r\n" + "・他アポインターにも参考にしてほしいこと"+
            "\r\n" + that.inputFBGood +
            "\r\n" + "・改善点" +
            "\r\n" + that.inputFBMore +
            "\r\n" +
            "\r\n紹介キャンペーンの案内実施有無：" + that.inputCampaign +
            "\r\n助成金の案内の有無：" + that.inputSubsidy +
            "\r\n求人ボックスキャンペーンの案内の有無：" + that.inputJobOfferCampaign +
            finalSuggestionPlan +
            "\r\n最終提案金額：" + that.inputLastSuggestionPrice +
            "\r\nパサー名前：" + that.inputPasserName +
            "\r\n"

            allText.part02 = "\r\n◎商談内容" +
            "\r\n" + that.inputMtgDetail +
            "\r\n◎備考" +
            "\r\n" + that.inputRemark +
            "\r\n◎音源データ" +
            "\r\n" + that.inputRecord +
            '\r\n' 

            allText.part03 = '-----------------------------' +
            '\r\n' + 'POST ID：' + postID +
            '\r\n' + 'こちらのIDは投稿画面からの投稿IDです。'

            allText.part04 = "\r\n案件名：" + (that.inputSubjectId) +
            "\r\n代表者名：" + (func.textEscape(that.inputCeoName)) +
            "\r\nメアド：" + (func.textEscape(that.inputCostomerEmail)) +
            "\r\n相手性別：" + (that.inputGender) +

            "\r\n決済権：" + ($('#settlement_rights_radio_button').val() ? $('#settlement_rights_radio_button').val() : "") +
            "\r\nタイミング：" + ($("[name='timing']:checked").val() ? $("[name='timing']:checked").val() : "") +
            "\r\n商談進捗：" + ($('#negotiationStatus').val() ? $('#negotiationStatus').val() : "") +
            "\r\n費用懸念：" + ($("[name='cost_concern']:checked").val() ? $("[name='cost_concern']:checked").val() : "") +
            "\r\n課題抽出：" + ($("[name='issue_extraction']:checked").val() ? $("[name='issue_extraction']:checked").val() : "") +
            "\r\n課題：" + ($('#issue').val() ? $('#issue').val() : "") +
            "\r\n決済方法：" + ($("[name='settlement_method']:checked").val() ? $("[name='settlement_method']:checked").val() : "") +
            "\r\n希望決済方法：" + ($("[name='wish_settlement_method']:checked").val() ? $("[name='wish_settlement_method']:checked").val() : "") +
            "\r\n相談：" + ($('#consultation_pulldown').val() ? $('#consultation_pulldown').val() : "") +
            "\r\n相談相手：" + confidant +
            "\r\n追客評価：" + evaluation +
            "\r\n\r\n"

            allText.part05 = "\r\n◎代表属性" +
            "\r\n理解力の5段階評価基準：" + ($("#understand_evaluation").val() || "") +
            "\r\n協力性の5段階評価基準：" + ($("#cooperativeness_evaluation").val() || "") +
            "\r\nコミュニケーションスタイル1：" + ($("input[name='communication_style_speak_or_listen']:checked").val() || "") +
            "\r\nコミュニケーションスタイル2：" + ($("input[name='communication_style_logic_or_emotion']:checked").val() || "") +
            "\r\nコミュニケーションスタイル3：" + ($("input[name='communication_style_simple_or_detail']:checked").val() || "") +
            "\r\n意思決定特性1：" + ($("input[name='decision_style_careful_or_prompt']:checked").val() || "") +
            "\r\n意思決定特性2：" + ($("input[name='decision_style_proactive_or_dependence']:checked").val() || "") +
            "\r\n意思決定特性3：" + ($("input[name='decision_style_data_or_sense']:checked").val() || "") +
            "\r\nビジネス姿勢1：" + ($("input[name='Business_posture_risk_avoid_or_challenge']:checked").val() || "") +
            "\r\nビジネス姿勢2：" + ($("input[name='Business_posture_cost_or_value']:checked").val() || "") +
            "\r\nビジネス姿勢3：" + ($("input[name='Business_posture_short_or_long']:checked").val() || "") +
            "\r\n\r\n"

            allText.part06 = ""
            const isEasierSuggestion = $("input[name='Suggestion']:checked").get().some(input => input.value === "Easier")
            if (isEasierSuggestion) {
              allText.part06 = "\r\n◎Easier専用設問" +
              "\r\n先方に親近感を抱かせるために行ったトーク：" + ($("#talk_building_rapport").val() || "") +
              "\r\n危機感を抱かせるために行ったトーク：" + ($("#talk_creating_urgency").val() || "") +
              "\r\n危機感の共有に関する自己評価指標：" + ($("input[name='crisis_self_assessment']:checked").val() || "") +
              "\r\n抽出した過去現在の集客状況：" + ($("#extracted_past_present_situation").val() || "") +
              "\r\n抽出した未来像：" + ($("#extracted_future_vision").val() || "") +
              "\r\n設定したギャップ：" + ($("#identified_gap").val() || "") +
              "\r\nギャップを設定した理由：" + ($("#reason_for_gap").val() || "") +
              "\r\nギャップが発生している原因：" + ($("#cause_of_gap").val() || "") +
              "\r\nギャップや原因についてお客様との共通認識：" + ($("input[name='gap_common_understanding']:checked").val() || "") +
              "\r\n原因解決に向け先方が導入すべき対策：" + ($("#recommended_marketing_measures").val() || "") +
              "\r\n対策実施による期待効果：" + ($("#expected_marketing_effect").val() || "") +
              "\r\n設定した原因に対して直近で施策を行う必要性：" + ($("input[name='measure_necessity']:checked").val() || "") +
              "\r\n商談時における顧客の意欲レベル：" + ($("input[name='customer_will_level']:checked").val() || "") +
              "\r\n意欲レベル理由：" + ($("#customer_will_level_reason").val() || "") +
              "\r\n\r\n"
            }

            let toSpreadsheet = allText.part01 + allText.part02 + allText.part04 + allText.part05 + allText.part06 + allText.part03 

            let toForwardPost = allText.part01 + allText.part05 + allText.part06 + allText.part03 

            // slack 投稿者にメンション
            const slackMention = `<@${that.slackChannel.userID}>\n`

            let toSlack =  slackMention + "◎案件情報 " + "[" + Extension_version + "]" +
            "\r\n案件ID：" + that.inputSubjectId +
            apSlackId + retiremented + passerSlackId +
            "\r\n" +
            "\r\n◎商談情報" +
            "\r\n種別：" + that.inputType +
            "\r\n商談日時：" + that.inputMtgDate + that.inputMtgDay + that.inputMtgStartTime + "〜" + that.inputMtgEndTime +
            previousResult +
            "\r\n商談結果：" + evaluationResult + isRevisitText + revisitDayText +
            resultFailureText +
            "\r\n回答期日：" + that.inputContactDeadline + that.inputContactDeadlineDay +
            "\r\n" +
            "\r\n提案内容：" + that.inputSuggestion +
            "\r\n商談相手：" + that.inputMtgPerson +
            "\r\n業種：" + $("#sub_cate").val() +
            possibleReason +
            "\r\n" +
            "\r\n◎入金" +
            "\r\n決済進捗：" + that.inputPayStatus +
            "\r\n受注金額：" + that.inputSalesPrice +
            "\r\n決済済み金額：" + that.inputPaid +
            "\r\n残額：" + that.inputBalance +
            "\r\n残額期日：" + that.inputBalanceDeadline + that.inputBalanceDeadlineDay +
            "\r\n支払い方法：" + that.inputpaymentMethod +
            order_product +
            "\r\n" +
            "\r\n◎アポ評価" +
            "\r\n評価結果：" + that.inputEvaluation +
            // "\r\n理由："+inputEvaluationReason+
            "\r\n詳細：" + that.inputDetail +
            "\r\n" + "・他アポインターにも参考にしてほしいこと" +
            "\r\n" + that.inputFBGood +
            "\r\n" + "・改善点" +
            "\r\n" + that.inputFBMore +
            "\r\n" +
            "\r\n紹介キャンペーンの案内実施有無：" + that.inputCampaign +
            "\r\n助成金の案内の有無：" + that.inputSubsidy +
            "\r\n求人ボックスキャンペーンの案内の有無：" + that.inputJobOfferCampaign +
            finalSuggestionPlan +
            "\r\n最終提案金額：" + that.inputLastSuggestionPrice +
            "\r\nパサー名前：" + that.inputPasserName +
            "\r\n" +
            "\r\n◎商談内容" +
            "\r\n" + that.inputMtgDetail +
            "\r\n◎備考" +
            "\r\n" + that.inputRemark +
            '\r\n' + '-----------------------------' +
            '\r\n' + 'POST ID：' + postID +
            '\r\n' + 'こちらのIDは投稿画面からの投稿IDです。'

            const requestFunc = new RequestBody.requestBody()
            const groupName = "apoReport"
            const spreadsheetApi = that.store.state.apis[`spreadsheet${that.demo}`]
            const calUpadteApi = that.store.state.apis[`calUpdate${that.demo}`]+ "/negotiation"
            const DBInsertApi = that.store.state.apis[`dbInsert${that.demo}`]
            const kintoneInsertApi = that.store.state.apis[`kintoneInsert${that.demo}`]
            const appID = that.isProd ? 292 : 78 // キントーンのアプリID
            const NowTime = requestFunc.nowTime()
            const created_at = requestFunc.nowTime("created_at") 

            const userData = that.store.state.userData
            if (userData.BN !== "" && userData.BN !== null && userData.BN !== "NULL" && userData.BN !== "None") {
              var fullname = userData.BN + "（" + userData.firstName + "） " + userData.lastName
            } else {
              var fullname = userData.firstName + " " + userData.lastName
            }
            const belongData = requestFunc.setBelongDate(userData)


            /*********************************************************
             * 投稿開始
             * *******************************************************/
            // Loading表示
            let mes = '投稿中'
            if(mailCheckFlag) mes +=  '<br>不正なメールアドレスが入力されていましたが投稿では無視されます'
            that.vueObj.viewLoader(mes)

            // slack投稿
            const data = {
              "channel": that.slackChannel.id, 
              "username": fullname,
              "text":  toSlack, 
              "token": that.slackChannel.token,
              "icon_url": that.slackChannel.iconURL
            }

            const response = await fetch(that.slackChannel.postURL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: `token=${encodeURIComponent(data.token)}&channel=${encodeURIComponent(data.channel)}&username=${encodeURIComponent(data.username)}&text=${encodeURIComponent(data.text)}&icon_url=${data.icon_url}`
            })
            .then(function (response) {
              return response.json()
            }).then(function (response) {
      
              if(response.message == "not current version") {
                alert(`tnPostのバージョンが違うようです。\n更新します。`);
                location.reload()
                postResult = false
                return false
              } else {
                
                console.log('slack投稿完了', response)
      
                return response
              }
      
            })
            .catch((e) => {
              console.error(e.stack, e.message)
            })         

            const messageURL = `${that.groupURL}/p${response.ts.split('.').join('')}`


            // 転送チャンネルに投稿
            const postParam = {
              slackChannel: that.slackChannel,
              messageURL: messageURL,
              postUser: fullname,
              mention: '',
              text: `${fullname}さんからの投稿です。\n\n` + toForwardPost,
              channelID: that.slackChannel.forwardID01
            }
            new ForwardPost(postParam).post()


            let insertDataRecordCnt = [{
                                      "投稿日時":{"id": "", "val": NowTime},
                                      "全文":{"id": "", "val": func.textEscape(toSpreadsheet)},
                                      "投稿URL":{"id": "", "val": messageURL},
                                      "ユーザーID":{"id": "", "val": tn_user_id},
                                      "投稿者名":{"id": "", "val": fullname},
                                      "所属エリア":{"id": "", "val": belongData.userOffice},
                                      "所属（課/部）":{"id": "", "val": belongData.userSection},
                                      "所属（組）":{"id": "", "val": belongData.FS_UserTeam},
                                      "案件ID":{"id": "", "val": that.inputSubjectId},
                                      "投稿ID":{"id": "", "val": postID},
                                      "案件名":{"id": "", "val": func.textEscape(that.inputComName)},
                                      "代表者名":{"id": "", "val": func.textEscape(that.inputCeoName)},
                                      "メアド":{"id": "", "val": func.textEscape(that.inputCostomerEmail)},
                                      "商談日":{"id": "", "val": that.inputMtgDate},
                                      "開始時間":{"id": "", "val": that.inputMtgStartTime},
                                      "終了時間":{"id": "", "val": that.inputMtgEndTime},
                                      "商談結果":{"id": "", "val": func.textEscape(that.inputResult)},
                                      "追客期日":{"id": "", "val": that.inputContactDeadline},
                                      "提案内容":{"id": "", "val": that.inputSuggestion},
                                      "商談相手":{"id": "", "val": that.inputMtgPerson},
                                      "相手性別":{"id": "", "val": that.inputGender},
                                      "決済進捗":{"id": "", "val": that.inputPayStatus},
                                      "受注金額":{"id": "", "val": that.inputSalesPrice},
                                      "決済済み金額":{"id": "", "val": that.inputPaid},
                                      "残高":{"id": "", "val": that.inputBalance},
                                      "残高期日":{"id": "", "val": that.inputBalanceDeadline},
                                      "支払い方法":{"id": "", "val": that.inputpaymentMethod},
                                      "アポ評価 - 結果":{"id": "", "val": func.textEscape(that.inputEvaluation)},
                                      "アポ評価 - 理由":{"id": "", "val": func.textEscape(that.inputEvaluationReason)},
                                      "アポ評価 - 詳細":{"id": "", "val": func.textEscape(that.inputDetail)},
                                      "FB - Good":{"id": "", "val": func.textEscape(that.inputFBGood)},
                                      "FB - Bad":{"id": "", "val": func.textEscape(that.inputFBMore)},
                                      "商談内容":{"id": "", "val": func.textEscape(that.inputMtgDetail)},
                                      "キャンペーン案内有無":{"id": "", "val": that.inputCampaign},
                                      "助成金の案内有無":{"id": "", "val": that.inputSubsidy},
                                      "求人ボックスキャンペーン案内有無":{"id": "", "val": that.inputJobOfferCampaign},
                                      "最終提案金額":{"id": "", "val": that.inputLastSuggestionPrice},
                                      "パサー名前":{"id": "", "val": that.inputPasserName},
                                      "種別":{"id": "", "val": that.inputType},
                                      "音源":{"id": "", "val": that.inputRecord},
                                      "所属（組）IS":{"id": "", "val": belongData.IS_UserTeam},
                                      "再訪設定の有無":{"id": "", "val": isRevisit},
                                      "再訪設定日付":{"id": "", "val": revisitDay},
                                      "決済権":{"id": "", "val": $('#settlement_rights_radio_button').val() ? $('#settlement_rights_radio_button').val() : ""},
                                      "タイミング":{"id": "", "val": $("[name='timing']:checked").val() ? $("[name='timing']:checked").val() : ""},
                                      "商談進捗":{"id": "", "val": $('#negotiationStatus').val() ? $('#negotiationStatus').val() : ""},
                                      "費用懸念":{"id": "", "val": $("[name='cost_concern']:checked").val() ? $("[name='cost_concern']:checked").val() : ""},
                                      "課題抽出":{"id": "", "val": $("[name='issue_extraction']:checked").val() ? $("[name='issue_extraction']:checked").val() : ""},
                                      "課題":{"id": "", "val": $('#issue').val() ? $('#issue').val() : ""},
                                      "決済方法":{"id": "", "val": $("[name='settlement_method']:checked").val() ? $("[name='settlement_method']:checked").val() : ""},
                                      "希望決済方法":{"id": "", "val": $("[name='wish_settlement_method']:checked").val() ? $("[name='wish_settlement_method']:checked").val() : ""},
                                      "相談":{"id": "", "val": $('#consultation_pulldown').val() ? $('#consultation_pulldown').val() : ""},
                                      "相談相手":{"id": "", "val": confidant},
                                      "追客評価":{"id": "", "val": evaluation},
                                      "前回の商談結果":{"id": "", "val": $("#previous_result").val() || ""},
                                      "業種":{"id": "", "val": $("#sub_cate").val() || ""},
                                      "受注商材":{"id": "", "val": selectedProducts},
                                      "提案金額":{"id": "", "val": $("#suggestion_price").val() ? $("#suggestion_price").val() + "円" : ""},
                                      "理解力の5段階評価基準":{"id": "", "val": $("#understand_evaluation").val() || ""},
                                      "協力性の5段階評価基準":{"id": "", "val": $("#cooperativeness_evaluation").val() || ""},
                                      "コミュニケーションスタイル1":{"id": "", "val": $("input[name='communication_style_speak_or_listen']:checked").val() || ""},
                                      "コミュニケーションスタイル2":{"id": "", "val": $("input[name='communication_style_logic_or_emotion']:checked").val() || ""},
                                      "コミュニケーションスタイル3":{"id": "", "val": $("input[name='communication_style_simple_or_detail']:checked").val() || ""},
                                      "意思決定特性1":{"id": "", "val": $("input[name='decision_style_careful_or_prompt']:checked").val() || ""},
                                      "意思決定特性2":{"id": "", "val": $("input[name='decision_style_proactive_or_dependence']:checked").val() || ""},
                                      "意思決定特性3":{"id": "", "val": $("input[name='decision_style_data_or_sense']:checked").val() || ""},
                                      "ビジネス姿勢1":{"id": "", "val": $("input[name='Business_posture_risk_avoid_or_challenge']:checked").val() || ""},
                                      "ビジネス姿勢2":{"id": "", "val": $("input[name='Business_posture_cost_or_value']:checked").val() || ""},
                                      "ビジネス姿勢3":{"id": "", "val": $("input[name='Business_posture_short_or_long']:checked").val() || ""},
                                      "先方に親近感を抱かせるために行ったトーク":{"id": "", "val": $("#talk_building_rapport").val() || ""},
                                      "危機感を抱かせるために行ったトーク":{"id": "", "val": $("#talk_creating_urgency").val() || ""},
                                      "危機感の共有に関する自己評価指標":{"id": "", "val": $("input[name='crisis_self_assessment']:checked").val() || ""},
                                      "抽出した過去現在の集客状況":{"id": "", "val": $("#extracted_past_present_situation").val() || ""},
                                      "抽出した未来像":{"id": "", "val": $("#extracted_future_vision").val() || ""},
                                      "設定したギャップ":{"id": "", "val": $("#identified_gap").val() || ""},
                                      "ギャップを設定した理由":{"id": "", "val": $("#reason_for_gap").val() || ""},
                                      "ギャップが発生している原因":{"id": "", "val": $("#cause_of_gap").val() || ""},
                                      "ギャップや原因についてお客様との共通認識":{"id": "", "val": $("input[name='gap_common_understanding']:checked").val() || ""},
                                      "原因解決に向け先方が導入すべき対策":{"id": "", "val": $("#recommended_marketing_measures").val() || ""},
                                      "対策実施による期待効果":{"id": "", "val": $("#expected_marketing_effect").val() || ""},
                                      "設定した原因に対して直近で施策を行う必要性":{"id": "", "val": $("input[name='measure_necessity']:checked").val() || ""},
                                      "商談時における顧客の意欲レベル":{"id": "", "val": $("input[name='customer_will_level']:checked").val() || ""},
                                      "意欲レベル理由":{"id": "", "val": $("#customer_will_level_reason").val() || ""},
                                      "考えられる受注要因":{"id": "", "val": $("#possible_reason_order").val() || ""},
                                      "考えられる受注要因詳細":{"id": "", "val": $("#possible_reason_order_detail").val() || ""},
                                      "考えられる失注要因":{"id": "", "val": $("#possible_reason_failure").val() || ""},
                                      "考えられる失注要因詳細":{"id": "", "val": $("#possible_reason_failure_detail").val() || ""},
                                      "考えられる追客理由":{"id": "", "val": $("#possible_reason_pursuit").val() || ""},
                                      "テストマーケ_受注理由":{"id": "", "val": orderReasons ? orderReasons.join(',') : ""},
                                      "テストマーケ_受注に必要な要素":{"id": "", "val": $('#receiveOrderElements').val() ? $('#receiveOrderElements').val() : ""},
                                      "テストマーケ_顧客の欲しい求人の年収帯":{"id": "", "val": $('#incomeRange').val() ? $('#incomeRange').val() : ""},
                                      "テストマーケ_事前準備":{"id": "", "val": $('#advanceInformation').val() ? $('#advanceInformation').val() : ""},
                                      "テストマーケ_最終提案プラン":{"id": "", "val": $('[name="final_suggestion_plan"]:checked').val() ? $('[name="final_suggestion_plan"]:checked').val() : ""},
                                      }]

            let sheetData = requestFunc.createBody(groupName, that.isProd, insertDataRecordCnt)
            
            const selectResult = document.getElementById("Result")
            const negotiationResultsId = selectResult.options[selectResult.selectedIndex].dataset.id
            const selectGender = document.querySelectorAll('input[type="radio"][name="gender"]')
            let genderId = ''
            selectGender.forEach((radioButton) => {
              if (radioButton.checked) {
                genderId = radioButton.dataset.id;
              }
            })
            const selectMtgPerson = document.querySelectorAll('input[type="checkbox"][name="MtgPerson"]:checked')
            const mtgPersonID = []

            selectMtgPerson.forEach((checkbox) => {
              mtgPersonID.push(checkbox.dataset.id)
            })

            const selectSuggestion = document.querySelectorAll('input[type="checkbox"][name="Suggestion"]:checked')
            const suggestionIds = []
            selectSuggestion.forEach((checkbox) => {
              suggestionIds.push(checkbox.dataset.id)
            })

            let calData = {
              "sg_data": {
                          "is_prod": that.isProd,
                          "post_url": "",
                          "group_id": that.slackChannel.id,
                          "user_id": userData.id,
                          "created_at": created_at,
                          },
              "event":   {
                          "id": that.resEventId, 
                          "calendar_id": that.resCalId,
                          "nego_result": func.textEscape(that.inputResult)
                          },
            }

            const isRescanCal = officeData.checkRescanCal(that.resCalId)

            if (that.description && that.description.includes("当日キャンセル中") && isRescanCal) {
              calData = null

            } else if (that.responseResult) {
              const apGainDate = that.inputMtgDate.replace(/\//g, '-')

              if (that.inputType == "新規") {
                that.responseResult.forEach((value) => {
                  const isIncludeSaihouCal = value.title.includes("[再")
                  let startTime = value.starttime.replace(/\//g, '-')
                  if (isIncludeSaihouCal && startTime.includes(apGainDate)) {
                    that.resCalId = value.cal_id
                    that.resEventId = value.event_id
                    that.description = value.description
                  }
                })

                const isRescanCal = officeData.checkRescanCal(that.resCalId)
                if (that.description && that.description.includes("当日キャンセル中") && isRescanCal) {
                  calData = null
                } else {
                  calData.event.id = that.resEventId
                  calData.event.calendar_id = that.resCalId
                  if (func.textEscape(that.inputResult) == "現場リスケ" || func.textEscape(that.inputResult) == "現場キャンセル") {
                    calData.event.nego_result = ""
                  }
                }

              } else {
                if (that.responseResult.some(value => value.event_id == that.resEventId && value.description.includes("当日キャンセル中"))) {
                  calData = null
                }
              }
            }

            const apEvaluationsIds = { 
              "S": 8,
              "A": 1,
              "B": 2,
              "C": 4,
              "D": 5,
              "E": 6,
              "F": 7,
            }

            let dbData_negotiation_reports = {
              "table_name": "negotiation_reports",
              "form": {
                "non_sub_query": {
                  "post_id": postID,
                  "negotiation_results_id": negotiationResultsId,
                  "gender_id": genderId,
                  "created_at": created_at,
                  "users_id": userData.id,
                  "subjects_id": that.inputSubjectId,
                },
                "sub_query": {
                  "users": true,
                  "payment_statuses_id": 'SELECT id FROM payment_statuses WHERE name = "' + that.inputPayStatus + '"',
                }
              },
              "authC": {
                "password": "W!z|a~.x.T~Tzca8CBCm",
                "mail": "system@sungrove.co.jp"
              },
              "support_data": {
                "users_id": userData.id,
                "is_prod": that.isProd
              }
            }

            if (that.inputContactDeadline) {
              dbData_negotiation_reports.form.non_sub_query.contact_deadline_date = that.inputContactDeadline
            }

            if (that.inputEvaluation !== "") {
              dbData_negotiation_reports.form.non_sub_query.ap_evaluations_id = apEvaluationsIds[that.inputEvaluation]
            }

            let dbData_negotiation_positions = []
            for (let i = 0; i < mtgPersonID.length ; i++) {
              dbData_negotiation_positions[i] = {
                "table_name": "negotiation_positions",
                "form": {
                  "non_sub_query": {
                    "client_positions_id": parseInt(mtgPersonID[i]),
                    "created_at": created_at,
                  },
                  "sub_query": {
                    "users": false,
                    "negotiation_reports_id": 'SELECT MAX(id) FROM negotiation_reports'
                  }
                }
              }
            }

            let dbData_negotiation_suggestions = []
            for (let i = 0; i < suggestionIds.length ; i++) {
              dbData_negotiation_suggestions[i] = {
                "table_name": "negotiation_suggestions",
                "form": {
                  "non_sub_query": {
                    "suggestions_id": parseInt(suggestionIds[i]),
                    "created_at": created_at,
                  },
                  "sub_query": {
                    "users": false,
                    "negotiation_reports_id": 'SELECT MAX(id) FROM negotiation_reports'
                  }
                }
              }
            }

            let teams_ids = ""
            if (userData.teams_id && userData.teams_id != "NULL" && userData.teams_id != "None") {
              teams_ids = userData.teams_id.split(",")
            } else {
              teams_ids = [""]
            }

            let dbData_nego_reports_group_teams = []
            for (let i = 0; i < teams_ids.length ; i++) {
              dbData_nego_reports_group_teams[i] = {
                "table_name": "nego_reports_group_teams",
                "form": {
                  "non_sub_query": {
                    "post_id": postID,
                    "created_at": created_at
                  },
                  "sub_query": {
                    "users": false,
                  }
                },
                "support_data": {
                  "users_id": userData.id,
                  "is_prod": that.isProd
                }
              }
            }
            
            let dbData_array = []
            dbData_array.push(dbData_negotiation_reports)

            for (let i = 0; i < dbData_negotiation_positions.length ; i++) {
              dbData_array.push(dbData_negotiation_positions[i])
            }
            
            for (let i = 0; i < dbData_negotiation_suggestions.length ; i++) {
              dbData_array.push(dbData_negotiation_suggestions[i])
            }

            for (let i = 0; i < teams_ids.length ; i++) {
              if (userData.teams_id && userData.teams_id != "NULL" && userData.teams_id != "None") {
                dbData_nego_reports_group_teams[i].form.non_sub_query.teams_id = parseInt(teams_ids[i])
              }
              dbData_array.push(dbData_nego_reports_group_teams[i])
            }

            const productMap = {
              "welbox": "ウェルボックス",
              "indeed": "イツザイ",
              "web": "web直販",
              "agent": "イツザイエージェント"
            }

            const products = checkedProduct.reduce((acc, value) => {
              if (productMap[value]) {
                acc.push(productMap[value])
              }
              return acc
            }, [])

            const fieldCode = {
              "案件ID": {"value": that.inputSubjectId},
              "案件名": {"value": func.textEscape(that.inputComName)},
              "担当営業": {"value": $('#chargeName').val()},
              "商材_チェックボックス": {"value": products},
              "F評価": {"value": $('#evaluation_F').val()},
              "E評価": {"value": $('#evaluation_E').val()},
              "D評価要件": {"value": selectedValues},
              "商談認識_理解力": {"value": $('#evaluation_question_1').val()},
              "商談認識_概要認識": {"value": $('#decision_1').val()},
              "時間確保_120": {"value": $('#evaluation_question_2').val()},
              "時間確保_90": {"value": $('#decision_2').val()},
              "費用認識_支払い": {"value": $('#evaluation_question_3').val()},
              "費用認識_月額": {"value": $('#decision_3').val()},
              "決裁権": {"value": $('#decision_4').val()},
              "人柄_コミュ": {"value": $('#evaluation_question_5').val()},
              "人柄_反応": {"value": $('#decision_5').val()},
              "商材提案": {"value": $('#decision_6').val()},
              "集客または採用意欲_高": {"value": $('#evaluation_question_7').val()},
              "集客または採用意欲_中": {"value": $('#decision_7').val()},
              "集客または採用意欲_低": {"value": $('#decision_next_7').val()},
              "集客または採用キャパシティ": {"value": $('#decision_8').val()},
              "保証認識": {"value": $('#evaluation_question_9').val()},
              "保証認識払拭": {"value": $('#decision_9').val()},
              "紹介認識": {"value": $('#evaluation_question_10').val()},
              "紹介認識払拭": {"value": $('#decision_10').val()},
              "商談環境": {"value": $('#decision_11').val()},
              "indeed": {"value": $('#indeed').val()},
              "WELBOX": {"value": $('#welbox').val()},
              "テスクロYES": {"value": $('#last').val()},
            }

            const kintoneData = {
              "app": appID,
              "record": {}
            }

            Object.keys(fieldCode).forEach(key => {
              kintoneData.record[key] = fieldCode[key]
            })

            $('#btn').addClass('disabled').removeClass('pulse')

            //スプレッドシート登録へ送信   
            const promises = []
            const spreadRequest = requestFunc.apiRequestExecution(spreadsheetApi, sheetData, that.key)
            const DBRequest = requestFunc.apiRequestExecution(DBInsertApi, dbData_array, that.key)
            promises.push(spreadRequest)
            promises.push(DBRequest)

            if (that.inputType != "再訪") {
              const kintoneRequest = requestFunc.apiRequestExecution(kintoneInsertApi, kintoneData, that.key)
              promises.push(kintoneRequest)
            }

            async function calRequestFunction() {
              try {
                const response = await requestFunc.apiRequestExecution(calUpadteApi, calData, that.key)
                if (response.status == 99) {
                  message = requestFunc.createNegoCalFailureApplyMessage(messageURL)
                  postToSlackErrorMessage(message)
                  alert('商談報告カレンダーへの変更に失敗しました。')
                }
              } catch (e) {
                let message = ""
                if (e.name === 'AbortError') {
                  message = requestFunc.createCalTimeOutMessage(messageURL)
                  postToSlackErrorMessage(message)
                  alert('商談報告カレンダーへの変更中にタイムアウトしました。')
                } else {
                  message = requestFunc.createNegoCalFailureApplyMessage(messageURL)
                  postToSlackErrorMessage(message)
                  alert('商談報告カレンダーへの変更に失敗しました。')
                }
              }
            }

            async function postToSlackErrorMessage(message) {
              const data = {
                "channel": that.calendarFailureApplySlackChannelID, 
                "username": fullname,
                "text":  "【" + that.groupTitle + "】" + message, 
                "token": that.slackChannel.token,
                "icon_url": that.slackChannel.iconURL
              }

              try {
                await fetch(that.slackChannel.postURL, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  body: `token=${encodeURIComponent(data.token)}&channel=${encodeURIComponent(data.channel)}&username=${encodeURIComponent(data.username)}&text=${encodeURIComponent(data.text)}&icon_url=${data.icon_url}`
                })
              } catch (error) {
                console.error('Slackへの投稿に失敗しました', error)
              }

            }

            if (that.inputSubjectId != "sungrove123" && calData != null) {
              promises.push(calRequestFunction())
            }

            Promise.all(promises)
            .then(function (response) {
              // Loading非表示
              that.vueObj.viewLoader()
              console.log('シート登録完了', response)
            })
            .catch((e) => {
              console.error(e.stack, e.message)
            })
        
            // 完了画面表示
            const params = {
              groupTitle: that.groupTitle,
              groupURL: that.groupURL,
              searchURL: messageURL,
              calURL: '',
              message: '',
              completeTitle: '投稿完了'
            }
            that.vueObj.viewComplete(params)

         })()
        }
      }
    }
  }
}

const formFunction = new formFuncClass.formFunctions(moment, $, sha256)

import RequestBody from "./requestBody.class"
import ForwardPost from "../../assets/js/forwardPost.class"
import OfficeDatas  from "./officeData"
import $ from 'jquery'
import moment from "moment"
import sha256 from 'crypto-js/sha256'
import formFuncClass  from "./formFunctions.class"
import formHoliday  from './formholiday.class'

export default {
  apoReport
}