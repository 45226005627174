/*****
  アポ獲得報告の投稿スケジュールチェック
******/
import axios from 'axios';

export default class {

  /******************************
    @param
      hook: string
      suggetion: string
      bussiness_format: string
      sheet: object
      mtg_add: string
      ap_doing_date: date
      ap_start_time: time
      raisha_area: string
      outlet_array: array
      loader: function
      today: date
  *******************************/
  constructor(param) {
    this.param = param
console.log(this.param)
    this.itzai_ng_outlet = ['②','⑥','⑦','⑧','⑪','⑯'];
    this.shukyaku_ng_outlet = ['②','⑦','⑧','⑪'];
    this.online_ng_outlet = ['⑨','⑩','⑬','⑭'];
    this.taimen_ng_outlet = ['②','⑦','⑧','⑪'];

    this.prefectures = [
      '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県',
      '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県',
      '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県',
      '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県',
      '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県',
      '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県',
      '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'
    ];
    this.area = {
      '関東': ['東京都','千葉県','神奈川県','埼玉県','栃木県','群馬県','茨城県'],
      '関西': ['大阪府','奈良県','滋賀県','京都府','兵庫県','和歌山県'],
      '東海': ['愛知県','岐阜県','三重県','静岡県'],
      '九州': ['福岡県','佐賀県'],
    }

    // 当日は 0 当日を起点に+1していく
    this.sheet_range = [
      {
        date: [4,0],
        'イツザイ': [
          { start: [5, 3], end: [5, 4], ng_outlet_1: [5, 5], ng_outlet_2: [5, 6] },
          { start: [6, 3], end: [6, 4], ng_outlet_1: [6, 5], ng_outlet_2: [6, 6] },
          { start: [7, 3], end: [7, 4], ng_outlet_1: [7, 5], ng_outlet_2: [7, 6] }
        ],
        '集客': [
          { start: [8, 3], end: [8, 4], ng_outlet_1: [8, 5], ng_outlet_2: [8, 6] },
          { start: [9, 3], end: [9, 4], ng_outlet_1: [9, 5], ng_outlet_2: [9, 6] }
        ],
        'Eaiser_イーウェル': [
          { start: [10, 3], end: [10, 4], ng_outlet_1: [10, 5], ng_outlet_2: [10, 6] },
          { start: [11, 3], end: [11, 4], ng_outlet_1: [11, 5], ng_outlet_2: [11, 6] }
        ],
        'Eaiser_WEB直販': [
          { start: [12, 3], end: [12, 4], ng_outlet_1: [12, 5], ng_outlet_2: [12, 6] },
          { start: [13, 3], end: [13, 4], ng_outlet_1: [13, 5], ng_outlet_2: [13, 6] }
        ],
      },
      {
        date: [15,0],
        'イツザイ': [
          { start: [16, 3], end: [16, 4], ng_outlet_1: [16, 5], ng_outlet_2: [16, 6] },
          { start: [17, 3], end: [17, 4], ng_outlet_1: [17, 5], ng_outlet_2: [17, 6] },
          { start: [18, 3], end: [18, 4], ng_outlet_1: [18, 5], ng_outlet_2: [18, 6] }
        ],
        '集客': [
          { start: [19, 3], end: [19, 4], ng_outlet_1: [19, 5], ng_outlet_2: [19, 6] },
          { start: [20, 3], end: [20, 4], ng_outlet_1: [20, 5], ng_outlet_2: [20, 6] }
        ],
        'Eaiser_イーウェル': [
          { start: [21, 3], end: [21, 4], ng_outlet_1: [21, 5], ng_outlet_2: [21, 6] },
          { start: [22, 3], end: [22, 4], ng_outlet_1: [22, 5], ng_outlet_2: [22, 6] }
        ],
        'Eaiser_WEB直販': [
          { start: [23, 3], end: [23, 4], ng_outlet_1: [23, 5], ng_outlet_2: [23, 6] },
          { start: [24, 3], end: [24, 4], ng_outlet_1: [24, 5], ng_outlet_2: [24, 6] }
        ],
      },
      {
        date: [26,0],
        'イツザイ': [
          { start: [27, 3], end: [27, 4], ng_outlet_1: [27, 5], ng_outlet_2: [27, 6] },
          { start: [28, 3], end: [28, 4], ng_outlet_1: [28, 5], ng_outlet_2: [28, 6] },
          { start: [29, 3], end: [29, 4], ng_outlet_1: [29, 5], ng_outlet_2: [29, 6] }
        ],
        '集客': [
          { start: [30, 3], end: [30, 4], ng_outlet_1: [30, 5], ng_outlet_2: [30, 6] },
          { start: [31, 3], end: [31, 4], ng_outlet_1: [31, 5], ng_outlet_2: [31, 6] }
        ],
        'Eaiser_イーウェル': [
          { start: [32, 3], end: [32, 4], ng_outlet_1: [32, 5], ng_outlet_2: [32, 6] },
          { start: [33, 3], end: [33, 4], ng_outlet_1: [33, 5], ng_outlet_2: [33, 6] }
        ],
        'Eaiser_WEB直販': [
          { start: [34, 3], end: [34, 4], ng_outlet_1: [34, 5], ng_outlet_2: [34, 6] },
          { start: [35, 3], end: [35, 4], ng_outlet_1: [35, 5], ng_outlet_2: [35, 6] }
        ],
      },
      {
        date: [37,0],
        'イツザイ': [
          { start: [38, 3], end: [38, 4], ng_outlet_1: [38, 5], ng_outlet_2: [38, 6] },
          { start: [39, 3], end: [39, 4], ng_outlet_1: [39, 5], ng_outlet_2: [39, 6] },
          { start: [40, 3], end: [40, 4], ng_outlet_1: [40, 5], ng_outlet_2: [40, 6] }
        ],
        '集客': [
          { start: [41, 3], end: [41, 4], ng_outlet_1: [41, 5], ng_outlet_2: [41, 6] },
          { start: [42, 3], end: [42, 4], ng_outlet_1: [42, 5], ng_outlet_2: [42, 6] }
        ],
        'Eaiser_イーウェル': [
          { start: [43, 3], end: [43, 4], ng_outlet_1: [43, 5], ng_outlet_2: [43, 6] },
          { start: [44, 3], end: [44, 4], ng_outlet_1: [44, 5], ng_outlet_2: [44, 6] }
        ],
        'Eaiser_WEB直販': [
          { start: [45, 3], end: [45, 4], ng_outlet_1: [45, 5], ng_outlet_2: [45, 6] },
          { start: [46, 3], end: [46, 4], ng_outlet_1: [46, 5], ng_outlet_2: [46, 6] }
        ],
      },
      {
        date: [48,0],
        'イツザイ': [
          { start: [49, 3], end: [49, 4], ng_outlet_1: [49, 5], ng_outlet_2: [49, 6] },
          { start: [50, 3], end: [50, 4], ng_outlet_1: [50, 5], ng_outlet_2: [50, 6] },
          { start: [51, 3], end: [51, 4], ng_outlet_1: [51, 5], ng_outlet_2: [51, 6] }
        ],
        '集客': [
          { start: [52, 3], end: [52, 4], ng_outlet_1: [52, 5], ng_outlet_2: [52, 6] },
          { start: [53, 3], end: [53, 4], ng_outlet_1: [53, 5], ng_outlet_2: [53, 6] }
        ],
        'Eaiser_イーウェル': [
          { start: [54, 3], end: [54, 4], ng_outlet_1: [54, 5], ng_outlet_2: [54, 6] },
          { start: [55, 3], end: [55, 4], ng_outlet_1: [55, 5], ng_outlet_2: [55, 6] }
        ],
        'Eaiser_WEB直販': [
          { start: [56, 3], end: [56, 4], ng_outlet_1: [56, 5], ng_outlet_2: [56, 6] },
          { start: [57, 3], end: [57, 4], ng_outlet_1: [57, 5], ng_outlet_2: [57, 6] }
        ],
      },
      {
        date: [59,0],
        'イツザイ': [
          { start: [60, 3], end: [60, 4], ng_outlet_1: [60, 5], ng_outlet_2: [60, 6] },
          { start: [61, 3], end: [61, 4], ng_outlet_1: [61, 5], ng_outlet_2: [61, 6] },
          { start: [62, 3], end: [62, 4], ng_outlet_1: [62, 5], ng_outlet_2: [62, 6] }
        ],
        '集客': [
          { start: [63, 3], end: [63, 4], ng_outlet_1: [63, 5], ng_outlet_2: [63, 6] },
          { start: [64, 3], end: [64, 4], ng_outlet_1: [64, 5], ng_outlet_2: [64, 6] }
        ],
        'Eaiser_イーウェル': [
          { start: [65, 3], end: [65, 4], ng_outlet_1: [65, 5], ng_outlet_2: [65, 6] },
          { start: [66, 3], end: [66, 4], ng_outlet_1: [66, 5], ng_outlet_2: [66, 6] }
        ],
        'Eaiser_WEB直販': [
          { start: [67, 3], end: [67, 4], ng_outlet_1: [67, 5], ng_outlet_2: [67, 6] },
          { start: [68, 3], end: [68, 4], ng_outlet_1: [68, 5], ng_outlet_2: [68, 6] }
        ],
      },
      {
        date: [70,0],
        'イツザイ': [
          { start: [71, 3], end: [71, 4], ng_outlet_1: [71, 5], ng_outlet_2: [71, 6] },
          { start: [72, 3], end: [72, 4], ng_outlet_1: [72, 5], ng_outlet_2: [72, 6] },
          { start: [73, 3], end: [73, 4], ng_outlet_1: [73, 5], ng_outlet_2: [73, 6] }
        ],
        '集客': [
          { start: [74, 3], end: [74, 4], ng_outlet_1: [74, 5], ng_outlet_2: [74, 6] },
          { start: [75, 3], end: [75, 4], ng_outlet_1: [75, 5], ng_outlet_2: [75, 6] }
        ],
        'Eaiser_イーウェル': [
          { start: [76, 3], end: [76, 4], ng_outlet_1: [76, 5], ng_outlet_2: [76, 6] },
          { start: [77, 3], end: [77, 4], ng_outlet_1: [77, 5], ng_outlet_2: [77, 6] }
        ],
        'Eaiser_WEB直販': [
          { start: [78, 3], end: [78, 4], ng_outlet_1: [78, 5], ng_outlet_2: [78, 6] },
          { start: [79, 3], end: [79, 4], ng_outlet_1: [79, 5], ng_outlet_2: [79, 6] }
        ],
      },
    ]

    return this.checkProccess();
  }

  async checkProccess() {
    this.param.loader('営業スケジュールチェック開始');

    this.setSheetURL();

    let result = await this.getSheetData();
  
    if(result.status == 'error') {
      this.loadingEnd(result.message);
      return result;
    }

    result = this.checkDateSet();
    console.log(result)
    if(result.status == 'error' || result.status == 'attention') {
      this.loadingEnd(result.message);
      return result;
    }

    result = this.checkBussinessHour();
    console.log(result)
    if(result.status == 'error' || result.status == 'warning') {
      this.loadingEnd(result.message);
      return result;
    }

    result = this.checkOutlet();
    console.log(result)
    if(result.status == 'error') {
      this.loadingEnd(result.message);
      return result;
    }

    return result;
  }

  setSheetURL() {
    let name = '';

    if( this.param.bussiness_format !== 'オンライン' ) {
      if( this.param.bussiness_format.indexOf('来社') > -1 ) {
        name = this.param.raisha_area;

        switch(name) {
          case '東京':
          case '秋葉原': {
            name = '東京都';
            break;
          }
          case '大阪': {
            name = name + '府';
            break;
          }
          case '広島':
          case '福岡': {
            name = name + '県';
            break;
          }
          case '名古屋': {
            name = '愛知県';
            break;
          }
          case '横浜': {
            name = '神奈川県';
            break;
          }
          case '札幌': {
            name = '北海道';
            break;
          }
          case '仙台': {
            name = '宮城県';
            break;
          }
        }

        this.param.mtg_add = name;
        name = this.getAreaName();

        if(!name) this.param.bussiness_format = 'オンライン';
      }

      if( this.param.bussiness_format.match(/カフェ|訪問/)) {
        name = this.getAreaName();
      }

      name = this.param.sheet.names.filter(s_name => s_name.indexOf(name) > -1);
    }

    if( this.param.bussiness_format == 'オンライン' ) {
      name = this.param.sheet.names.filter(s_name => s_name.indexOf('オンライン') > -1);
    }

    this.sheet_name = name;
  }

  async getSheetData() {
    try {
      const spreadID = this.param.prod ? this.param.sheet.id : this.param.sheet.demo_id
      const spreadUpdateRequestBody = {
        "spread": {
          "id": spreadID,
          "sheet_name": this.sheet_name[0]
        },
        "all_records": {}
      }

      await fetch(this.param.api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'X-Cal-St-Api': this.param.key
        },
        body: JSON.stringify(spreadUpdateRequestBody)
      })
      .then(res => res.json())
      .then(data => {
        this.sheet_data = data
      })
      .catch(err => {
        console.log(err);
        throw err;
      });

      return {
        status: 'ok',
        message: '営業スケジュール取得完了'
      };

    } catch (e) {
      console.error(e);
      return {
        status: 'error',
        message: '営業スケジュール取得失敗\nお手数ですが再投稿してください'
      };
    }
  }

  getAreaName() {
    const regex = new RegExp(this.prefectures.join('|'), 'g');
    const match = this.param.mtg_add.match(regex);
    
    if (match) {
      const prefecture = match[0];
      console.log('都道府県名:', prefecture);
    
      const matching_key = Object.keys(this.area).find(key => this.area[key].includes(prefecture));

      if (matching_key) {
        console.log('マッチするキー:', matching_key);
        return matching_key;
      } else {
        console.log('都道府県名がエリアに見つかりませんでした');
        return false;
      }
    } else {
      console.log('都道府県名が見つかりませんでした');
    }
  }

  checkDateSet() {
    // イージアの場合
    this.date_set = [];

    let sheet_range = this.sheet_range;

    let check_date = '';
    if( this.param.today == this.param.ap_doing_date ) { 
      check_date = '当アポ';
    } else {
      check_date = this.param.ap_doing_date.match(/[0-9]{2}$/)[0].replace(/^0/,'') + '日';
    }

    try {
      this.date_set = sheet_range.filter((range, i) => {
        
        let row = range.date[0];
        let col = range.date[1];

        if(this.sheet_data[row][col].indexOf(check_date) > -1) {
          return sheet_range[i];
        }
  
        return false;
      });

      if(this.date_set.length == 0) {
        return {
          status: 'attention',
          message: '該当する日付が営業スケジュールにありませんが\n処理を続行します'
        }
      } else {
        return {
          status: 'ok',
          message: '選択した日付に該当する営業スケジュールを取得しました'
        }
      }

    } catch(e) {
      console.error(e);
      return {
        status: 'error',
        message: '日付の指定に失敗しました。\n営業スケジュールシートのレイアウトを\n確認してください。'
      }
    }
  }

  checkBussinessHour() {
    let check_hour_set = [];
    this.ng_outlet_1 = '';
    this.ng_outlet_2 = '';
    
    if(this.date_set.length > 0 ) {
      if( this.param.suggestion == 'Easier' ) {
        if( this.param.hook == 'W' ) {
          check_hour_set = this.date_set[0]['Eaiser_イーウェル'];
        } else {
          check_hour_set = this.date_set[0]['Eaiser_WEB直販'];
        }
      } else {
        if( this.param.hook == 'イツザイ' || this.param.suggestion == 'イツザイ' ) {
          check_hour_set = this.date_set[0]['イツザイ'];
        } else {
          check_hour_set = this.date_set[0]['集客'];
        }
      }
    } else {
      return {
        status: 'warning',
        message: 'アポ日付が営業スケジュールの範囲外ですが処理を続行します'
      }
    }

    if(check_hour_set.length > 0) {        
      let result = 0;
      for(let i in check_hour_set) {

        const start = this.sheet_data[check_hour_set[i].start[0]][check_hour_set[i].start[1]]
        const end = this.sheet_data[check_hour_set[i].end[0]][check_hour_set[i].end[1]]

        if(start && end) {
          const check_time = this.checkTime(this.param.ap_start_time, start, end);

          if(check_time == 1) {
            this.ng_outlet_1 = this.sheet_data[check_hour_set[i].ng_outlet_1[0]][check_hour_set[i].ng_outlet_1[1]]
            this.ng_outlet_2 = this.sheet_data[check_hour_set[i].ng_outlet_2[0]][check_hour_set[i].ng_outlet_2[1]]
          }

          result += check_time;
        }
      }

      if(result == 1) {
        return {
          status: 'ok',
          message: '営業時間内もしくは指定なし'
        }
      } else {
        document.querySelector('.schedule_permit_button').style.display = 'flex'
        return {
          status: 'error',
          message: '営業時間外です。日程を確認してください。\n\n上長に許可をいただいた場合は\n投稿ボタン上の許可済みボタンにチェックしてください。'
        }
      }
    }
  }

  checkTime(target, start, end) {
    if(start == 'CLOSE' || end == 'CLOSE') {
      document.querySelector('.schedule_permit_button').style.display = 'flex'
      return {
        status: 'error',
        message: '営業時間外です。日程を確認してください。\n\n上長に許可をいただいた場合は\n投稿ボタン上の許可済みボタンにチェックしてください。'
      }
    }

    this.timeToDate = (time) => {
      let date = new Date();
      let [hour, minute, second = 0] = time.split(":").map(Number);
      date.setHours(hour, minute, second, 0);
      return date;
    }

    start = this.timeToDate(start).getTime();
    end = this.timeToDate(end).getTime();
    target = this.timeToDate(target).getTime();
  
    return start <= target && end >= target? 1: 0;
  }

  checkOutlet() {

    const outlets = [];
    for (let i = 0; i < this.param.outlet_array.length; i++ ) {
      if( this.param.outlet_array[i].checked ) outlets.push(this.param.outlet_array[i].value);
    }

    if((this.ng_outlet_1 || this.ng_outlet_2) && outlets.length > 0) {
      const match = [];
      for ( let i = 0; i < outlets.length; i++) {
        const outlet_number = outlets[i];

        if (this.ng_outlet_1.indexOf(outlet_number) > -1 || this.ng_outlet_2.indexOf(outlet_number) > -1) {
          match.push(outlet_number);
        }
      }

      if(match.length > 0) {
        document.querySelector('.schedule_permit_button').style.display = 'flex'
        return {
          status: 'error',
          message: `NGアウトレットが選択されています\n${match.join(' ')}\n営業スケジュールを確認してください。\n\n上長に許可をいただいた場合は\n投稿ボタン上の許可済みボタンにチェックしてください。`
        } 
      } else {
        return {
          status: 'ok',
          message: '選択したアウトレットに問題はありません'
        }
      }
    } else {
      return {
        status: 'ok',
        message: 'NGアウトレットなし'
      }
    }
  }

  loadingEnd(message) {
    alert(message);
    this.param.loader('営業スケジュールチェック完了');
  }
}

