/*
  申込書報告のフォーム用のデータ取得
*/

import formDataStore from "../../formDataStore"
import RequestBody from "../../requestBody.class"
import func from "../../formFunctions.class"
import moment from "moment"
import sha256 from 'crypto-js/sha256'
//import dummy from './dummySheetData.js'

const infoTextContents = {
  '◎イツザイ': [
    {selector: '[name="movie_yesNo"]', label: "動画撮影", type: 'radio'},
    {selector: '[name="itzai_plan"]', label: "プラン", type: 'radio'},
    {selector: '[name="Brand_page_itzai"]', label: "ブランドページ", type: 'radio'},
    {selector: '#PR_pages_produced_itzai', label: "PRページ制作代行本数", type: 'number'},
    {selector: '#recruitment_requirements_produced_itzai', label: "募集要項制作代行数", type: 'number'},
    {selector: '#KV_produced_itzai', label: "KV追加制作代行数", type: 'number'},
    {selector: '[name="industry_names"] option', label: "業界", type: 'selectbox'},
    {selector: '#rctJobCate', label: "募集職種名", type: 'text'},
    {selector: '[name="compensation_yesNo"]', label: "補償オプション", type: 'radio'},
    {selector: '#otherOp', label: "その他オプション", type: 'text'},
    {selector: '[name="shoot_yesNo"]', label: "撮影有無", type: 'radio'},
    {selector: '#reasonNotShoot', label: "撮影無し理由", type: 'text'},
    {selector: '[name="motive_signing_contract"]', label: "契約動機", type: 'checkbox'},
    {selector: '#contractReason', label: "契約動機詳細", type: 'text'},
    {selector: '[name="persnality"]', label: "顧客人柄", type: 'checkbox'},
    {selector: '#conv', label: "お客様との会話内容", type: 'textarea'},
    {selector: '[name="promise_insertion"]', label: "イツザイに載せる内容について話した内容", type: 'checkbox'},
    {selector: '#convItzai', label: "上記の詳細", type: 'textarea'},
    {selector: '[name="promise_contents"]', label: "サービス内容について提案した内容", type: 'checkbox'},
    {selector: '#convService', label: "上記の詳細", type: 'textarea'},
    {selector: '#shootDate', label: "掲載希望日時", type: 'text'},
    {selector: '#rctBackground', label: "求人背景", type: 'text'},
    {selector: '#kind_person_Hope', label: "どういう人が取りたいか", type: 'textarea'},
    {selector: '#otherHope', label: "その他顧客希望", type: 'textarea'},
    {selector: '#contact_itzai', label: "連絡先情報備考（主要窓口）", type: 'textarea'},
    {selector: '[name="main_contact_itzai_presence"]', label: "商談同席有無", type: 'radio'},
    {selector: '[name="guide_job_offer_campaign"]', label: "求人ボックスキャンペーン対応", type: 'radio'},
  ],
  '◎連絡先情報': [
    {selector: '[name="mainContact"]', label: "主要連絡先", type: 'radio'},
    {selector: '#contactComName', label: "社名", type: 'text'},
    {selector: '#companyAddress', label: "本社住所", type: 'text'},
    {selector: '#contactNameCEO', label: "代表取締役者名", type: 'text'},
    {selector: '#contactNameCEOCana', label: "代表取締役者名（カナ）", type: 'text'},
    {selector: '#contactMailCEO', label: "代表メールアドレス", type: 'text'},
    {selector: '#contactTelNumCEO', label: "代表電話番号（固定）", type: 'text'},
    {selector: '#contactTelephoneCEO', label: "代表電話番号（携帯）", type: 'text'},
    {selector: '[name="canOnlineMtgCEO_yesNo"]', label: "WEB打ち合わせ可否", type: 'radio'},
    {selector: '#specialMention', label: "代表連絡先情報備考", type: 'text'},
    {selector: '[name="progress_yesNo"]', label: "進行可否", type: 'radio'},
    {selector: '#contactNameStaff', label: "担当者名", type: 'text'},
    {selector: '#contactNameStaffCana', label: "担当者名（カナ）", type: 'text'},
    {selector: '#contactMailStaff', label: "担当メールアドレス", type: 'text'},
    {selector: '#contactTelNumStaff', label: "担当電話番号（固定）", type: 'text'},
    {selector: '#contactTelephoneStaff', label: "担当電話番号（携帯）", type: 'text'},
    {selector: '[name="canOnlineMtgStaff_yesNo"]', label: "WEB打ち合わせ可否", type: 'radio'},
    {selector: '[name="canMtgStaff_yesNo"]', label: "担当者の商談同席有無", type: 'radio'},
    {selector: '#contactStaffRemarks', label: "担当連絡先情報備考", type: 'text'},
  ],
  '◎イツザイ ライト': [
    {selector: '[name="movie_yesNo_lite"]', label: "動画撮影", type: 'radio'},
    {selector: '[name="itzai_lite_plan"]', label: "プラン", type: 'radio'},
    {selector: '[name="Brand_page_itzai_lite"]', label: "ブランドページ", type: 'radio'},
    {selector: '#PR_pages_produced_itzai_lite', label: "PRページ制作代行本数", type: 'number'},
    {selector: '#recruitment_requirements_produced_itzai_lite', label: "募集要項制作代行数", type: 'number'},
    {selector: '#KV_produced_itzai_lite', label: "KV追加制作代行数", type: 'number'},
    {selector: '[name="industry_names_lite"] option', label: "業界", type: 'selectbox'},
    {selector: '#rctJobCate_lite', label: "募集職種名", type: 'text'},
    {selector: '[name="compensation_yesNo_lite"]', label: "補償オプション", type: 'radio'},
    {selector: '#otherOp_lite', label: "その他オプション", type: 'text'},
    {selector: '[name="shoot_yesNo_lite"]', label: "撮影有無", type: 'radio'},
    {selector: '#reasonNotShoot_lite', label: "撮影無し理由", type: 'text'},
    {selector: '[name="motive_signing_contract_lite"]', label: "契約動機", type: 'checkbox'},
    {selector: '#contractReasonLite', label: "契約動機詳細", type: 'text'},
    {selector: '[name="persnality_lite"]', label: "顧客人柄", type: 'checkbox'},
    {selector: '#conv_lite', label: "お客様との会話内容", type: 'textarea'},
    {selector: '[name="promise_insertion_lite"]', label: "イツザイに載せる内容について話した内容", type: 'checkbox'},
    {selector: '#convItzaiLite', label: "上記の詳細", type: 'textarea'},
    {selector: '[name="promise_contents_lite"]', label: "サービス内容について提案した内容", type: 'checkbox'},
    {selector: '#convServiceLite', label: "上記の詳細", type: 'textarea'},
    {selector: '#shootDate_lite', label: "掲載希望日時", type: 'text'},
    {selector: '#rctBackground_lite', label: "求人背景", type: 'text'},
    {selector: '#kind_person_Hope_lite', label: "どういう人が取りたいか", type: 'textarea'},
    {selector: '#otherHope_lite', label: "その他顧客希望", type: 'textarea'},
    {selector: '#contact_itzai_lite', label: "連絡先情報備考（主要窓口）", type: 'textarea'},
    {selector: '[name="main_contact_itzai_lite_presence"]', label: "商談同席有無", type: 'radio'},
    {selector: '[name="guide_job_offer_campaign_lite"]', label: "求人ボックスキャンペーン対応", type: 'radio'},
  ],
  '◎EC': [
    {selector: '#ecVersion', label: "型番", type: 'text'},
    {selector: '#sharedMatters_ec', label: "お客様共有事項", type: 'text'},
    {selector: '#contractReason_ec', label: "契約動機", type: 'textarea'},
    {selector: '#persnality_ec', label: "顧客人柄", type: 'text'},
    {selector: '#conv_ec', label: "お客様との会話内容", type: 'textarea'},
    {selector: '#hope_ec', label: "顧客希望", type: 'textarea'},
    {selector: '#productsNum', label: "商品登録点数", type: 'number'},
    {selector: '#ecSiteProductsNum', label: "サイト制作数", type: 'number'},
    {selector: '#shootDate_ec', label: "掲載希望日時", type: 'text'},
    {selector: '[name="shoot_yesNo_ec"]', label: "商品撮影", type: 'radio'},
    {selector: '#ecProductsNum', label: "撮影商品数", type: 'number'},
    {selector: '#ecCutNum', label: "撮影カット数", type: 'number'},
    {selector: '[name="shootLoca"]', label: "撮影場所", type: 'radio'},
    {selector: '[name="ecPaymentMethod"]', label: "EC導入決済(コンビニ or 定期利用)", type: 'radio'},
    {selector: '#contact_ec', label: "連絡先情報備考", type: 'textarea'},
  ],
  '◎WEL': [
    {selector: '#welCateNum', label: "カテゴリ数", type: 'text'},
    {selector: '#welCate', label: "カテゴリ", type: 'text'},
    {selector: '#welBenefits', label: "特典", type: 'text'},
    {selector: '#welOtherShared', label: "備考欄・特記事項", type: 'textarea'},
  ],
  '◎リロ': [
    {selector: '#reloCateNum', label: "カテゴリ数", type: 'text'},
    {selector: '#reloCate', label: "カテゴリ", type: 'text'},
    {selector: '#reloBenefits', label: "特典", type: 'text'},
    {selector: '#reloOtherShared', label: "備考欄・特記事項：", type: 'textarea'},
  ],
  '◎SEO': [
    {selector: '#seoWordNum', label: "ワード数", type: 'text'},
    {selector: '.seoWords', label: "ワード", type: 'addbox_text'},
    {selector: '#seoURL', label: "SEO対象URL", type: 'text'},
    {selector: '[name="seo_train"]', label: "対策期間", type: 'radio'},
  ],
  '◎MEO': [
    {selector: '#meoURL', label: "店舗URL", type: 'text'},
    {selector: '[name="meo_train"]', label: "対策期間", type: 'radio'},
    {selector: '#meoWordNum', label: "ワード数", type: 'text'},
    {selector: '.meoWords', label: "ワード", type: 'addbox_text'},
    {selector: '.meoWordAreas', label: "対象範囲", type: 'addbox_text'},
    {selector: '.meoWordAdds', label: "中心地点住所", type: 'addbox_text'},
  ],
  '◎CMS': [
    {selector: '[name="cms_should_movie"]', label: "動画撮影", type: 'radio'},
    {selector: '#cmsProductsNum', label: "サイト制作数", type: 'text'},
    {selector: '#shootDate_cms', label: "掲載希望日時", type: 'text'},
    {selector: '#cmsVersion', label: "型番", type: 'text'},
    {selector: '#sharedMatters_cms', label: "お客様共有事項", type: 'text'},
    {selector: '[name="shoot_yesNo_cms"]', label: "撮影有無", type: 'radio'},
    {selector: '#reasonNotShoot_cms', label: "撮影無し理由", type: 'text'},
    {selector: '#contractReason_cms', label: "契約動機", type: 'text'},
    {selector: '#persnality_cms', label: "顧客人柄", type: 'text'},
    {selector: '#conv_cms', label: "お客様との会話内容", type: 'text'},
    {selector: '#hope_cms', label: "顧客希望", type: 'text'},
    {selector: '#contact_cms', label: "連絡先情報備考", type: 'textarea'},
  ],
  '◎CMS（宿泊型番）': [
    {selector: '[name="cms_lodging_should_movie"]', label: "動画撮影", type: 'radio'},
    {selector: '#cmsLodgingProductsNum', label: "サイト制作数", type: 'text'},
    {selector: '#shootDate_cms_lodging', label: "掲載希望日時", type: 'text'},
    {selector: '#cmsLodgingVersion', label: "型番", type: 'text'},
    {selector: '#sharedMatters_cms_lodging', label: "お客様共有事項", type: 'text'},
    {selector: '[name="shoot_yesNo_cms_lodging"]', label: "撮影有無", type: 'radio'},
    {selector: '#reasonNotShoot_cms_lodging', label: "撮影無し理由", type: 'text'},
    {selector: '#contractReason_cms_lodging', label: "契約動機", type: 'text'},
    {selector: '#persnality_cms_lodging', label: "顧客人柄", type: 'text'},
    {selector: '#conv_cms_lodging', label: "お客様との会話内容", type: 'text'},
    {selector: '#hope_cms_lodging', label: "顧客希望", type: 'text'},
    {selector: '#contact_cms_lodging', label: "連絡先情報備考", type: 'textarea'},
  ],
  '◎Easier': [
    {selector: '[name="easier_should_movie"]', label: "動画撮影", type: 'radio'},
    {selector: '[name="easier_plan"]', label: "プラン", type: 'radio'},
    {selector: '[name="Brand_page_production_easier"]', label: "ブランドページ", type: 'radio'},
    {selector: '#PR_pages_produced_easier', label: "PRページ制作代行数", type: 'text'},
    {selector: '[name="MEO_measures_easier"]', label: "MEO対策", type: 'radio'},
    {selector: '#word_number_easier', label: "MEO対策ワード数", type: 'text'},
    {selector: '[name="advertising_substitution_easier"]', label: "有料広告運用代行サービス", type: 'radio'},
    {selector: '#paid_advertising_expenses_easier', label: "有料広告費用", type: 'text'},
    {selector: '#industry_names_easier', label: "募集業界", type: 'text'},
    {selector: '[name="shoot_yesNo_easier"]', label: "撮影有無", type: 'radio'},
    {selector: '#none_shoot_reason', label: "撮影無し理由", type: 'text'},
    {selector: '[name="contractReason_easier"]', label: "契約動機", type: 'checkbox'},
    {selector: '#contract_reason_easier_other', label: "契約動機詳細", type: 'textarea'},
    {selector: '[name="persnality_easier"]', label: "顧客人柄", type: 'checkbox'},
    {selector: '#conv_easier', label: "お客様との会話内容", type: 'textarea'},
    {selector: '[name="promise_insertion_easier"]', label: "Easierに載せる内容について話した内容", type: 'checkbox'},
    {selector: '#promise_insertion_easier_detail', label: "上記の詳細", type: 'textarea'},
    {selector: '[name="promise_service_easier"]', label: "サービス内容について提案した内容", type: 'checkbox'},
    {selector: '#promise_service_easier_detail', label: "上記の詳細", type: 'textarea'},
    {selector: '#date_publication_easier', label: "掲載希望日時", type: 'text'},
    {selector: '#background_customers_easier', label: "他媒体効果", type: 'textarea'},
    {selector: '#sharing_operators_easier', label: "その他顧客希望", type: 'textarea'},
    {selector: '[name="test_service_easier_yesNo"]', label: "テストマーケのMEO無料運用サービス", type: 'radio'},
  ],
  '◎助成金': [
    {selector: '[name="subsidy"]', label: "対象助成金",type: 'checkbox'},
    {selector: '.documents input[type="checkbox"]', label: "未回収必須書類",type: 'checkbox'},
    {selector: '#recruitment_status', label: "直近の採用状況", type: 'textarea'},
    {selector: '#concern', label: "申請時の懸念点", type: 'textarea'},
  ],
  '◎補償オプション': [
    {selector: '[name="com_option"]', label: "補償オプション", type: 'radio'},
  ],
  '◎動画制作': [
    {selector: '[name="video_production"]', label: "プラン", type: 'radio'},
  ],
  '◎イツザイエージェント': [
    {selector: '[name="agent_plan"]', label: "受注プラン", type: 'radio'},
    {selector: '#agent_initialCost', label: "初期費用", type: 'text'},
    {selector: '#agent_basicServiceFee', label: "基本サービス料金", type: 'text'},
    {selector: '[name="agent_selection_people_option"]', label: "同時選考人数追加オプション", type: 'radio'},
    {selector: '#agent_selection_people', label: "人数", type: 'text'},
    {selector: '#agent_additional_amounts', label: "追加金額", type: 'text'},
    {selector: '[name="agent_warranty_plans"]', label: "保証用プラン", type: 'radio'},
    {selector: '#agent_warranty_expenses', label: "保証用費用", type: 'text'},
    {selector: '[name="agent_motive_signing_contract"]', label: "契約動機", type: 'checkbox'},
    {selector: '#agent_contract_reason', label: "契約動機詳細", type: 'textarea'},
    {selector: '[name="agent_persnality"]', label: "顧客人柄", type: 'checkbox'},
    {selector: '#agent_conversation_content', label: "お客様との会話内容", type: 'textarea'},
    {selector: '[name="agent_promise_insertion"]', label: "イツザイエージェントに載せる内容について話した内容", type: 'checkbox'},
    {selector: '#conv_agent', label: "上記の詳細", type: 'textarea'},
    {selector: '[name="agent_promise_contents"]', label: "サービス内容について提案した内容", type: 'checkbox'},
    {selector: '#agent_conv_service', label: "上記の詳細", type: 'textarea'},
    {selector: '#agent_adoption_start_date', label: "採用活動開始希望日時（ヒアアポ～の期間・掲載期間を伝えた上で）", type: 'text'},
    {selector: '#agent_rct_background', label: "求人背景", type: 'text'},
    {selector: '#agent_kind_person_Hope', label: "どういう人が取りたいか", type: 'textarea'},
  ],
  '◎オプション': [
    {selector: '#optionVal', label: "オプション", type: 'textarea'},
  ],
}


export default class {
  constructor(obj) {

    this.formTypeForRequestBody = 'applications'

    this.parentObj = obj
    this.$ = this.parentObj.parentObj.$
    this.postID = obj.postID
    this.subjectsID = obj.subjectsID
    this.params = obj.params
    this.abortController = new AbortController()
    this.isProd = this.parentObj.isProd
    this.slackChannelID = this.postID.split("_")[0]
    this.demo = this.isProd ? "" : "Demo"
    this.key = formDataStore.state.apis[`loginKey${this.demo}`]

    // モデル
    this.editableItems = this.parentObj.parentObj.vueObj.editableItems
    
    // フォーム情報
    this.thisFormParams = this.parentObj.parentObj.vueObj
    .thisFormParams
    //this.groupURL = this.thisFormParams[1] // talknoteグループURL
    //this.groupID = this.groupURL.split('feed/')[1].split('/')[0] // 

    // 各種API
    this.spreadsheetInsertApi = formDataStore.state.apis[`spreadsheet${this.demo}`]
    this.spreadsheetUpdateApi = formDataStore.state.apis[`spreadsheet${this.demo}`].replace('insert', 'update')
    this.spreadsheetGetApi = formDataStore.state.apis[`spreadsheet${this.demo}`].replace('insert', 'get')
    this.DBInsertApi = formDataStore.state.apis[`dbInsert${this.demo}`]
    this.DBUpdateApi = formDataStore.state.apis[`dbUpdate${this.demo}`]
    this.kintoneInsertApi = formDataStore.state.apis[`kintoneInsert${this.demo}`]
    this.kintoneUpdateApi = formDataStore.state.apis[`kintoneUpdate${this.demo}`]
    this.appID = this.isProd ? 345 : 343 // キントーンのアプリID

    // 削除した残額格納
    this.delBalanceInfoID = []

    // SEOのデータ
    this.SEOWords = {}

    // MEOのデータ
    this.MEOWords = {}

    //リクエストボディ構築用
    this.requestFunc = new RequestBody.requestBody()
    this.nowTime = this.requestFunc.nowTime()
    this.createdAt = this.requestFunc.nowTime('created_at')

    // スプレッドシート用
    this.spreadData = this.requestFunc.setSpreadData(this.formTypeForRequestBody, this.isProd)
    this.spreadData.id = this.spreadData.spreadsheetID
    delete this.spreadData.spreadsheetID
    delete this.spreadData.is_prod

    // 共通関数
    this.func = new func.formFunctions(moment, this.$, sha256)
  

  }


  /**
   * データ取得
   */
  async getData() {
    // DBから取得　フォームに適用するのはこのデータ
    this.DBData = await this.getDBData()

    if(!this.DBData) {
      return false
    }

    // シートから取得　内容を
    this.insertSheetData = await this.getSheetData()
    console.log(this.DBData, this.insertSheetData)

    return true

  }

  /**
   * アポ獲得報告で編集対象のデータをDBから取得 
   */
  async getDBData() {

    /*this.applications = dummy.applications[0]
    this.balance_payment_methods = dummy.balance_payment_methods
    this.application_hooks = dummy.application_hooks[0]
    this.application_suggestions = dummy.application_suggestions[0]*/

    // DB取得
    {
      const query = `SELECT * FROM applications WHERE post_id = '${this.postID}' AND deleted_at IS NULL`

      this.params.main_data = {
        tables:["applications"],
        query: query
      }

      this.applications = await fetch(formDataStore.state.apis[`dbSelect${this.demo}`], {
        signal: this.abortController.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Cal-St-Api': this.key
        },
        body: JSON.stringify(this.params)
      })
      .then(response => response.json())
      .then(response => {
        response = (response && response.length > 0)? response[0]: false
        return response 
      })
    
      if(!this.applications) return false
    }

    // 残額
    {
      const query = `SELECT * FROM balance_payment_methods WHERE applications_id = '${this.applications.id}' AND deleted_at IS NULL`

      this.params.main_data = {
        tables:["balance_payment_methods"],
        query: query
      }

      this.balance_payment_methods = await fetch(formDataStore.state.apis[`dbSelect${this.demo}`], {
        signal: this.abortController.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Cal-St-Api': this.key
        },
        body: JSON.stringify(this.params)
      })
      .then(response => response.json())
      .then(response => {
        response = (response && response.length > 0)? response: false
        return response 
      })
    }

    // フック取得
    {
      const query = `SELECT * FROM application_hooks WHERE applications_id = '${this.applications.id}' AND deleted_at IS NULL`

      this.params.main_data = {
        tables:["application_hooks"],
        query: query
      }

      this.application_hooks = await fetch(formDataStore.state.apis[`dbSelect${this.demo}`], {
        signal: this.abortController.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Cal-St-Api': this.key
        },
        body: JSON.stringify(this.params)
      })
      .then(response => response.json())
      .then(response => {
        response = (response && response.length > 0)? response: false
        return response 
      })
    }

    // 提案内容取得
    {
      const query = `SELECT * FROM application_suggestions WHERE applications_id = '${this.applications.id}' AND deleted_at IS NULL`

      this.params.main_data = {
        tables:["application_suggestions"],
        query: query
      }

      this.application_suggestions = await fetch(formDataStore.state.apis[`dbSelect${this.demo}`], {
        signal: this.abortController.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Cal-St-Api': this.key
        },
        body: JSON.stringify(this.params)
      })
      .then(response => response.json())
      .then(response => {
        response = (response && response.length > 0)? response: false
        return response 
      })
    }

    return true
  }

  /**
   * 申込書報告で編集対象のデータをスプレッドシートから取得 
   */
  async getSheetData() {   
  
    this.insertSheetData = {}

    this.getSheetDataQuery = {
      "spread": this.spreadData,
      "target_lines": [
        {"column": '投稿ID', "value": this.postID},
        {"column": '削除日時', "value": ''},
      ],
    }
    /*
    this.insertSheetDataTmp = dummy['470397_tn-post-4d7086bb'][0]
    for(let key in this.insertSheetDataTmp) {
      this.insertSheetData[key] = {"id": "", "val": this.insertSheetDataTmp[key]}
    }*/

    await fetch(this.spreadsheetGetApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.getSheetDataQuery)
    })
    .then((res) => res.json())
    .then((res) => {

      if(res.length > 0) {
        this.insertSheetDataTmp = res[0]
        console.log('シートデータ取得成功', this.insertSheetDataTmp )

        for(let key in this.insertSheetDataTmp) {
          this.insertSheetData[key] = {"id": "", "val": this.insertSheetDataTmp[key]}
        }
      } else {
        alert('該当するシートデータがありません。\nリロードします')
        console.log('該当するシートデータがありません')
        //location.reload()
        return false
      }
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

    return this.insertSheetData
  }

  
   /**
   * フォームにデータ適用 
   */
  applyDataToForm() {
    const $ = this.$

    $('.autoG, .item, .form_wrap').slideDown()
    
    // 案件ID
    this.subjectsID = this.applications.subjects_id
    this.parentObj.parentObj.addSubjectsIDBox(this.applications.subjects_id)
    this.editableItems.subjectID = this.subjectsID

    // 決済進捗
    document.querySelector(`#paymentStatus [data-id="${this.applications.payment_progress_id}"]`).selected = true

      // 決済進捗プルダウンに紐づけられたイベント発火

      //let changeEvent = new Event('change')
      $(`#paymentStatus`).trigger('change')
      //elm.dispatchEvent(changeEvent)
      console.log('change')

      // 受注金額
      this.editableItems.salesPrice = this.applications.sold_price.toLocaleString()

      // 決済済み金額
      this.editableItems.paid = this.applications.paid_amount.toLocaleString()
    
    // 残額
    if(this.balance_payment_methods.length > 0) {

      const deadlines = this.insertSheetData['残高期日'].val.split(',')
      let addDelPayment = $('.addDelPayment')

      // ボックス追加
      if(this.balance_payment_methods.length >= 1) {
        for(let i = 1, n = this.balance_payment_methods.length; i < n; i++) {
          addDelPayment.find('[name="add"]').click()
        }
      }

      // データ反映
      addDelPayment = $('.addDelPayment')
      this.balance_payment_methods.forEach((val, i) => {
        console.log(val)
        addDelPayment.eq(i).attr('data-id', val.id)
        addDelPayment.eq(i).find('.balance').val(val.balance)
        addDelPayment.eq(i).find('.balanceDeadline').val(deadlines[i].split('/').join('-'))
        addDelPayment.eq(i).find(`.paymentMethod option[data-id="${val.payment_methods_id}"]`).prop("selected", true)
      })

      $('#balanceDeadlineReason').val(this.insertSheetData['残高期日理由'].val)

      const use_credit_yesNo = this.insertSheetData['ビジクレ使用可否'].val
      if (use_credit_yesNo == "可能") {
        $('#use_credit_yes').prop('checked', true)
      } else if (use_credit_yesNo == "不可") {
        $('#use_credit_no').prop('checked', true)
      }

      // 削除した残額ID格納
      $(document).off('click.addDelPayment').on('click.addDelPayment', '.addDelPayment [name="del"]', (e) => {
        const id = $(e.currentTarget).parent().parent().data('id')
        this.delBalanceInfoID.push(id)
      })
    }

    // 提案内容 infoTextContentをグローバルから読み込み
    {
      this.suggestionsVals = {
        'イツザイ': 'itzai',
        'イツザイライト': 'itzaiLite',
        'EC': 'ec',
        'WEL': 'wel',
        'リロ': 'relo',
        'SEO': 'seo',
        'MEO': 'meo',
        'CMS': 'cms',
        'CMS（宿泊型番）': 'cmsLodging',
        'Easier': 'easier',
        '助成金Lab': 'subsidy',
        '補償オプション': 'com_option',
        '動画制作': 'video_production',
        'オプション': 'option',
        '連絡先情報': 'contactInfo',
      } 
      // 現在選択中データ
      this.suggestionsData = this.insertSheetData['提案内容'].val.split('+')
      this.itszaiFlag = this.suggestionsData.indexOf('イツザイ') > -1 && this.suggestionsData.indexOf('イツザイ ライト') > -1

      this.suggestionsData.forEach((val) => {
        const elm = document.querySelector(`.select_checkbox[data-name="${val}"]`)
        elm.click()
      })

      //setTimeout(this.itszaiLiteContactInfoToggle, 100)
/*
      // 提案内容チェックボックス監視
      const observeSuggestionSelect = (e) => {
        console.log(e.target)
      }
      document.querySelector(`.select_checkbox`).addEventListener('click', observeSuggestionSelect, false)*/

      // 全文をスクレイピング
      let infoText = this.insertSheetData['全文'].val.split('◎').filter(Boolean)
      let addBoxContent = {}

      setTimeout(() => {
        infoText.forEach((val, i) => {

          if(this.itszaiFlag && val.indexOf('連絡先情報-イツザイ ライト') > -1) return 

          const selectors = ["#convService", "#convServiceLite", "#promise_service_easier_detail", "#agent_conv_service"]
          
          for(let key in infoTextContents) {
            const reg = new RegExp('^' + key.replace('◎', '') + '\n')

            if(reg.test(val)) {

              for(let i = 0, n = infoTextContents[key].length; i < n; i++) {
                const cnt = infoTextContents[key][i]

                let label = cnt.label.replace('\(', '\\(')
                label = label.replace('\)', '\\)')

                const reg = new RegExp(`${label}：([^\\\n]*)`)
                const m = val.match(reg)
                if (!m) {
                  continue
                }

                if (selectors.includes(cnt.selector)) {
                  exceptionHandling(val, label, cnt)
                  continue
                }

                switch(cnt.type) {
                  case 'text':
                  case 'number': {
                    $(cnt.selector).val(m[1])
                    break
                  }
                  case 'radio': {
                    $(cnt.selector + `[value="${m[1]}"]`).click()
                    break
                  }
                  case 'checkbox': {
                    if(m) {
                      const vals = m[1].split(',')

                      if(val.length > 0) {
                        vals.forEach((val) => {
                          $(cnt.selector + `[value="${val}"]`).click()
                        })
                      }

                    }
                    break
                  }
                  case 'selectbox': {
                    $(cnt.selector + `[value="${m[1]}"]`).prop('selected', true)
                    break;
                  }
                  case 'textarea': {
                    const nextWord = infoTextContents[key][i + 1]? '\n' + infoTextContents[key][i + 1].label + '：' : '\n\n'

                    const reg = new RegExp(label + '：([\\s\\S]*?)' + nextWord)
                    const m = val.match(reg)

                    if(m) {
                      $(cnt.selector).text(m[1])
                    }
                    break
                  }
                  case 'addbox_text': {
                    const nextWord = infoTextContents[key][i + 1]? '\n' + infoTextContents[key][i + 1].label + '：' : '\n\n'

                    const reg = new RegExp(label + '：\n?([\\s\\S]*?)' + nextWord)
                    let m = val.match(reg)
                    m = m[1].replace(/（[0-9]）/g, '').split('\n')
                    
                    if(!addBoxContent[key]) addBoxContent[key] = []
                    addBoxContent[key].push({selector: cnt.selector, label: label, word: m})
     
                    break
                  }
                }
              }
            }
          }
        })

        // 「上記の詳細」というラベルが2つあり、正しく取得できないので例外処理
        function exceptionHandling(val, label, cnt){
          const msg = val.split("サービス内容について提案した内容")[1]
          const nextWord = cnt.selector == "#agent_conv_service" ? '\n採用活動開始希望日時（ヒアアポ～の期間・掲載期間を伝えた上で）：' : '\n掲載希望日時：'
          const reg = new RegExp(label + '：([\\s\\S]*?)' + nextWord)
          const m = msg.match(reg)

          if(m) {
            $(cnt.selector).text(m[1])
          }
        }

        //主要連絡先情報が異なっているかどうかを全文には記録していないので、主要連絡先情報備考が入っているか否かでラジオボタン切替
        const contactVary = $('#contact_itzai').val()
        if (contactVary != undefined) {
          if ($('#contact_itzai').val().length == 0) {
            document.getElementById("main_contact_itzai_no").checked = true
            $("#is_contact_itzai_concurrent, #is_contact_itzai_presence").slideUp()
            $("#contact_itzai, [name='main_contact_itzai_presence']").removeClass("required")
          } else {
            document.getElementById("main_contact_itzai_yes").checked = true
          }
        }

        const contactVaryLite = $('#contact_itzai_lite').val()
        if (contactVaryLite != undefined) {
          if ($('#contact_itzai_lite').val().length == 0) {
            document.getElementById("main_contact_itzai_lite_no").checked = true
            $("#is_contact_itzai_lite_concurrent, #is_contact_itzai_lite_presence").slideUp()
            $("#contact_itzai_lite, [name='main_contact_itzai_lite_presence']").removeClass("required")
          } else {
            document.getElementById("main_contact_itzai_lite_yes").checked = true
          }
        }

        // SEOとMEOを動的追加
        if(Object.keys(addBoxContent).length > 0) {
          for(let key in addBoxContent) {
            const contents = addBoxContent[key]
            let count = 0 

            if(key.indexOf('SEO') > -1) {
              // 登録単語数
              count = contents[0].word.length
              // 単語数分クリックしてボックス追加
              for(let i = 1; i < count; i++) $(contents[0].selector).parent().find('[name="add"]').click()

              contents.forEach((content) => {
                if(content.word) {
                  content.word.forEach((word) => {
                    $(content.selector).val(word)
                  })
                }
              })
              
            }

            if(key.indexOf('MEO') > -1) {
              // 登録単語数
              count = contents[0].word.length

              for(let i = 1; i < count; i++) $(contents[0].selector).parent().parent().find('[name="add"]').click()

              contents.forEach((content) => {
                if(content.word) {
                  content.word.forEach((word) => {
                    $(content.selector).val(word.replace('㎞', ''))
                  })
                }
              })
            
            }
          }
        }

        // 提案内容　削除と追加
        {
          this.suggestToDB = {}

          const elms = document.querySelectorAll(`.select_checkbox`)
          
          elms.forEach((elm, i) => {
            this.suggestToDB[elm.dataset.name] = {
              name: elm.dataset.name,
              suggestion_id: elm.dataset.suggestionId? elm.dataset.suggestionId: '',
              hook_id: elm.dataset.hookId? elm.dataset.hookId: '',
              initData: elm.checked,
              addFlag: 0,
              deletedFlag: 0
            }

            elm.addEventListener('change', (elm) => {
              const suggest = elm.target.dataset.name

              // 選択：true 既存なし
              if(elm.target.checked) { 
                this.suggestToDB[suggest].deletedFlag = 0
                this.suggestToDB[suggest].addFlag = 1

                if(elm.target.dataset.suggestionId == 5) {
                  subsidyAddDelete()
                }

                if(
                  !this.suggestToDB['イツザイ'].deletedFlag && !this.suggestToDB['イツザイ ライト'].deletedFlag
                ) {
                  this.itszaiFlag = true
                  //setTimeout(this.itszaiLiteContactInfoToggle, 100)
                }
                
              }

              // 選択：false 既存あり
              if(!elm.target.checked) { 
                this.suggestToDB[suggest].deletedFlag = 1
                this.suggestToDB[suggest].addFlag = 0

                if(elm.target.dataset.suggestionId - 0 == 5) {
                  for(let i in this.currentSubsidys) {      
                    this.currentSubsidys[i].deletedFlag = 1
                  }
                }

                if(this.itszaiFlag && (suggest == 'イツザイ' || suggest == 'イツザイ ライト')) {
                  this.itszaiFlag = false
                  //this.itszaiLiteContactInfoToggle()
                }
              }

              console.log(this.suggestToDB, this.currentSubsidys)
            })
          })
  
        }

        // 助成金 削除と追加
        this.currentSubsidys = []
        let subsidyAddDelete = () => {

          const subsidyElms = document.querySelectorAll(`[name="subsidy"]`)
          
          subsidyElms.forEach((subsidyElm, i) => {

            if(!this.currentSubsidys[subsidyElm.value]) {
              let currentSubsidy = {id: subsidyElm.dataset.id, name: subsidyElm.value, initData: 0, deletedFlag: 0, addFlag: 0} 

              currentSubsidy.initData = subsidyElm.checked

              this.currentSubsidys[subsidyElm.value] = currentSubsidy
            }

            subsidyElm.addEventListener('change', (elm) => {
              const subsidy = elm.target.value

              // 選択：true 既存なし
              if(elm.target.checked) {
                this.currentSubsidys[subsidy].addFlag = 1
                this.currentSubsidys[subsidy].deletedFlag = 0
              }

              // 選択：false 既存あり
              if(!elm.target.checked) {
                this.currentSubsidys[subsidy].addFlag = 0
                this.currentSubsidys[subsidy].deletedFlag = 1
              }

            })
          })
        }

        const post_prefix =  this.subjectsID.slice(0, 2)
        if(post_prefix == "YD" || post_prefix == "KS") {
          $('.test_marketing_authority').show()
          $(`[name="agent_plan"][value="${this.insertSheetData['テストマーケ_受注プラン'].val}"]`).prop('checked', true)
          $('#agent_initialCost').val(this.insertSheetData['テストマーケ_初期費用（税込）'].val.replace('円', ''))
          $('#agent_basicServiceFee').val(this.insertSheetData['テストマーケ_基本サービス料金（税込）'].val.replace('円', ''))
          $(`[name="agent_selection_people_option"][value="${this.insertSheetData['テストマーケ_同時選考人数追加オプション'].val}"]`).prop('checked', true)
          if (this.insertSheetData['テストマーケ_同時選考人数追加オプション'].val == 'あり') {
            $('.agent_selection_people_option_display').show()
            $('#agent_selection_people').val(this.insertSheetData['テストマーケ_オプション人数'].val.replace('人', ''))
            $('#agent_additional_amounts').val(this.insertSheetData['テストマーケ_オプション追加料金'].val.replace('円', ''))
          } else {
            $('.agent_selection_people_option_display').hide()
          }

          $(`[name="agent_warranty_plans"][value="${this.insertSheetData['テストマーケ_保証プラン'].val}"]`).prop('checked', true)
          if (this.insertSheetData['テストマーケ_保証プラン'].val == 'あり') {
            $('.agent_warranty_plans_display').show()
            $('#agent_warranty_expenses').val(this.insertSheetData['テストマーケ_保証用費用'].val.replace('円', ''))
          } else {
            $('.agent_warranty_plans_display').hide()
          }
        }

        subsidyAddDelete()
      }, 1000)
    }

  }


  /**
   * クエリ構築
   */
  buildQuery() {
    const $ = this.$


    try {
      // 案件ID
      let editedSubjectID = document.getElementById('editSubjectsID')
      editedSubjectID = editedSubjectID.value

      // 残額クエリ構築
      this.buildBalanceInfoQuery()
      console.log('buildBalanceInfoQuery')

      // 提案内容クエリ構築
      this.buildSuggestionsQuery()
      console.log('buildSuggestionsQuery')

      // SEOデータ取得 シート用
      if(document.querySelector('[name="select_form"]#formSEOSelect').checked) this.getSEOWords()

      // MEOデータ取得 シート用
      if(document.querySelector('[name="select_form"]#formMEOSelect').checked) this.getMEOWords()

      // DB更新用クエリ用
      let sold_price = parseInt(this.editableItems.salesPrice.replace(/,/g, ''))
      let paid_amount = parseInt(document.getElementById('paid').value.replace(/,/g, ''))

      let updateQueryObj = [
        {
          "table_name": "applications",
          "non_sub_query": { 
            "set": {
              // 案件ID
              "subjects_id": editedSubjectID,
              // 決済進捗
              "payment_progress_id": this.parentObj.getOptionsDataset('#paymentStatus', 'id'),
              // 受注金額
              "sold_price": sold_price,
              // 決済済み金額
              "paid_amount": paid_amount,           
            },
            "where": {
              "post_id": this.postID
            }
          },
          "sub_query": {
          },
          "support_data": this.params.support_data,
          "authC": this.params.authC
        }, 
      ]

      // イツザイの業種取得
      this.jobID = ''
      if(
        document.querySelector('[name="select_form"]#formItzaiSelect').checked &&
        document.querySelector('[name="industry_names"]')
        ) {
        this.jobID = this.parentObj.getOptionsDataset('industry_names', 'id')
        updateQueryObj[0].non_sub_query.set.categories_id = this.jobID
      }

      updateQueryObj = [...updateQueryObj, ...this.balanceInfoQuery.update, ...this.suggestionsQuery.update]


      // 追加用クエリ
      const insertQueryObj = [...this.balanceInfoQuery.insert, ...this.suggestionsQuery.insert]
      
      console.log('updateQueryObj', updateQueryObj)
      console.log('insertQueryObj', insertQueryObj)



      // シート用
      {
        const formAp = document.getElementById('form_ap')
        const formApRadioList = formAp.elements

        this.insertSheetData['案件ID'].val = editedSubjectID

        const post_prefix =  this.subjectsID.slice(0, 2)

        if ((post_prefix == "YD" || post_prefix == "KS") && this.insertSheetData['提案内容'].val.includes('イツザイエージェント')) {
          this.insertSheetData['テストマーケ_受注プラン'].val = document.querySelector('[name="agent_plan"]:checked').value

          this.insertSheetData['テストマーケ_初期費用（税込）'].val = $('#agent_initialCost').val() ? $('#agent_initialCost').val() + '円' : ''

          this.insertSheetData['テストマーケ_基本サービス料金（税込）'].val = $('#agent_basicServiceFee').val() ? $('#agent_basicServiceFee').val() + '円' : ''

          this.insertSheetData['テストマーケ_同時選考人数追加オプション'].val = document.querySelector('[name="agent_selection_people_option"]:checked').value

          this.insertSheetData['テストマーケ_オプション人数'].val = $('#agent_selection_people').val() ? $('#agent_selection_people').val() + '人' : ''

          this.insertSheetData['テストマーケ_オプション追加料金'].val = $('#agent_additional_amounts').val() ? $('#agent_additional_amounts').val() + '円' : ''

          this.insertSheetData['テストマーケ_保証プラン'].val = document.querySelector('[name="agent_warranty_plans"]:checked').value

          this.insertSheetData['テストマーケ_保証用費用'].val = $('#agent_warranty_expenses').val() ? $('#agent_warranty_expenses').val() + '円' : ''
        }

        this.insertSheetData['全文'].val = this.rebuildInfomationText() // 全文を再構築

        this.insertSheetData['受注金額'].val = document.getElementById('salesPrice').value + '円'

        this.insertSheetData['決済進捗'].val = document.getElementById('paymentStatus').value

        this.insertSheetData['決済済み金額'].val = document.getElementById('paid').value + '円'

        let balanceTotal = 0
        let balanceEle = document.querySelectorAll('[name="balance"]')
        balanceEle.forEach(function(element) {
          let value = parseInt(element.value.replace(/,/g, ''))
          balanceTotal += value
        })
        this.insertSheetData['残額'].val = balanceTotal + '円'

        this.insertSheetData['残高期日'].val = this.balanceInfoQuery.sheet.deadline

        this.insertSheetData['支払い方法'].val = document.querySelectorAll('[name="paymentMethod"]')[0].value

        this.insertSheetData['残高期日理由'].val = document.getElementById('balanceDeadlineReason').value

        const contactComName =  document.getElementById('contactComName')
        this.insertSheetData['連絡先情報 - 社名'].val = (contactComName)? contactComName.value : ''

        const contactCEOName =  document.getElementById('contactNameCEO')
        this.insertSheetData['連絡先情報 - 代表者名'].val = (contactCEOName)? contactCEOName.value : ''

        const contactName = document.getElementById('contactNameStaff')
        this.insertSheetData['連絡先情報 - 担当者名'].val = (contactName)? contactName.value : ''

        const contactMail = document.getElementById('contactMailCEO')
        this.insertSheetData['連絡先情報 - メールアドレス'].val = (contactMail)? contactMail.value: ''

        const contactTelNum = document.getElementById('contactTelNumCEO')
        this.insertSheetData['連絡先情報 - 電話番号'].val = (contactTelNum)? contactTelNum.value: ''


        this.insertSheetData['SEO - 対象URL'].val = (this.SEOWords && this.SEOWords.seoURL)? this.SEOWords.seoURL: ''

        this.insertSheetData['SEO - ワード数'].val = (this.SEOWords && this.SEOWords.seoWordNum)? this.SEOWords.seoWordNum: ''

        this.insertSheetData['SEO - ワード'].val = (this.SEOWords && this.SEOWords.words)? this.SEOWords.words: ''


        this.insertSheetData['MEO - 店舗URL'].val = (this.MEOWords && this.MEOWords.meoURL)? this.MEOWords.meoURL: ''

        this.insertSheetData['MEO - 店舗住所'].val = (this.MEOWords && this.MEOWords.meoWordAdd)? this.MEOWords.meoWordAdd: ''

        this.insertSheetData['MEO - ワード数'].val = (this.MEOWords && this.MEOWords.meoWordNum)? this.MEOWords.meoWordNum: ''

        this.insertSheetData['MEO - ワード&範囲'].val = (this.MEOWords && this.MEOWords.words)? this.MEOWords.words: ''

        this.insertSheetData['提案内容'].val = (this.suggestionsQuery.sheet && this.suggestionsQuery.sheet.suggestions)? this.suggestionsQuery.sheet.suggestions: ''


        const welBenefits = document.getElementById('welBenefits')
        this.insertSheetData['WEL-特典'].val = (welBenefits)? welBenefits.value: ''

        const welCate = document.getElementById('welCate')
        this.insertSheetData['WEL-カテゴリ'].val = (welCate)? welCate.value: ''


        const reloBenefits = document.getElementById('reloBenefits')
        this.insertSheetData['リロ-特典'].val = (reloBenefits)? reloBenefits.value: ''

        const reloCate = document.getElementById('reloCate')
        this.insertSheetData['リロ-カテゴリ'].val = (reloCate)? reloCate.value: ''


        this.insertSheetData['業界ID'].val = (this.jobID)? this.jobID: ''

        this.insertSheetData['対象助成金'].val = (this.subsidyText)? this.subsidyText: ''

        this.insertSheetData['未回収必要書類'].val = (this.requireDdocumentsText)? this.requireDdocumentsText: ''

        const com_option = formApRadioList['com_option']
        this.insertSheetData['補償オプション'].val = (com_option)? com_option.value: ''
        
        const video_production = formApRadioList['video_production']
        this.insertSheetData['動画制作'].val = (video_production)? video_production.value: ''

        this.insertSheetData['進行可否'].val = $('input[name="progress_yesNo"]:checked').val() ? $('input[name="progress_yesNo"]:checked').val() : ""

        this.insertSheetData['ビジクレ使用可否'].val = $('input[name="use_credit_yesNo"]:checked').val() ? $('input[name="use_credit_yesNo"]:checked').val() : ""

        // シート格納用にクエリ生成
        const sheetData = this.requestFunc.createBody(this.formTypeForRequestBody, this.isProd, [this.insertSheetData]) 

        this.query = {
          toDBUpdate: updateQueryObj,
          toDBInsert: insertQueryObj,
          toSheetUpdate: {
            'spread': sheetData.spread,
            'target_lines': [
              {"column": "投稿ID", "value": this.postID}
            ],
            "update_cells": [
              {"column": "削除日時", "value": this.createdAt},
            ]
          },
          toSheetInsert: sheetData 
        }
      }
    console.log(this.query)
      return false

    } catch(e) {
      console.error(e)
      return
    }
    
  }



  /**
   * 全文用に残額テキスト構築 
   */
  getBalanceInfoForInformationText() {
    const $ = this.$
    let balanceInfo_len = $('.balanceInfo').length
    let $balanceInfo_balance = $('.balanceInfo_balance')
    let $balanceInfo_date = $('.balanceInfo_date')
    let $balanceInfo_paymentMethod = $('.balanceInfo_paymentMethod')

    let balanceNum = 0
    let balanceInfoMsg = ''
    let Info_balance = 0
    let Info_date = ''
    let Info_paymentMethod = 0
    let isInputFlag = 1
    
    for(let i = 0; i < balanceInfo_len; i++) {
      // 入力チェック
      $balanceInfo_balance[i].value == "" ? isInputFlag *= 0 : Info_balance = $balanceInfo_balance[i].value

      $balanceInfo_date[i].value == "" ? isInputFlag *= 0 : Info_date = $balanceInfo_date[i].value

      $balanceInfo_paymentMethod[i].value == "" ? Info_paymentMethod *= 0 : Info_paymentMethod = $balanceInfo_paymentMethod[i].value

      //残額・残額期日・支払い方法の3つの入力された時
      if( isInputFlag == 1 ) { 

        Info_date = moment(Info_date.split('/').join('-')).format('YYYY/MM/DD（ddd）')

        balanceNum += 1
        balanceInfoMsg +=
        '(' + balanceNum + ')' + '\n' +
        '残額：' + Info_balance + '円' +'\n' +
        '残額期日：' + Info_date + '\n' +
        '支払い方法：' + Info_paymentMethod + '\n'
      }
    }

    return balanceInfoMsg.trim()
  }

  /**
   * 残額登録用クエリ構築
   */
  buildBalanceInfoQuery() {
    const $ = this.$

    let queries = {
      update: [],
      insert: [],
      sheet: {}
    } 
  
    // 削除
    if( this.delBalanceInfoID.length > 0 ) {

      this.delBalanceInfoID.forEach((val) => {
        if(val) {
          let del = {
            "table_name": "balance_payment_methods",
            "non_sub_query": { 
              "set": {
                "deleted_at": this.createdAt,
              },
              "where": {
                "id": val
              }
            },
            "sub_query": {
            },
            "support_data": this.params.support_data,
            "authC": this.params.authC,
          }

          queries.update.push(del)
        }
      })
    }

    // 更新と追加
    let balances = 0
    let deadlines = []
    const elms = document.querySelectorAll('.addDelPayment')
 
    elms.forEach((elm, i) => {
      const deadlineDate = document.querySelectorAll('[name="balanceDeadline"]')[i].value
      let balancePrice = document.querySelectorAll('[name="balance"]')[i].value
      balancePrice = parseInt(balancePrice.replace(/,/g, ''))

      if(deadlineDate) deadlines.push(deadlineDate)
      if(balancePrice) balances += balancePrice - 0

      const id = this.parentObj.getOptionsDataset('.paymentMethod', 'id')
      
      if(elm.dataset.id) {
        const query = {
          "table_name": "balance_payment_methods",
          "non_sub_query": { 
            "set": {
              "applications_id": this.applications.id,
              "balance": balancePrice,
              //"balanceDeadline": deadlineDate,
              "payment_methods_id": id,
              "created_at": this.createdAt,
            },
            "where": {
              "id": elm.dataset.id
            }
          },
          "sub_query": {
          },
          "support_data": this.params.support_data,
          "authC": this.params.authC,
        }
        queries.update.push(query)

      } else if(balancePrice) {

        const query = {
          "table_name": "balance_payment_methods",
          "form": {
            "non_sub_query": {
              "balance": balancePrice,
              "applications_id": this.applications.id,
              //"balanceDeadline": deadlineDate,
              "payment_methods_id": id,
              "created_at": this.createdAt,
            },
            "sub_query": {
              "users": false,
            }
          },
          "support_data": this.params.support_data,
          "authC": this.params.authC,
        }

        queries.insert.push(query)
      }

    })

    queries.sheet.deadline = deadlines.join(',')
    queries.sheet.balance = balances
    this.balanceInfoQuery = queries
  }

  /**
   * 提案内容クエリ構築
   */
  buildSuggestionsQuery() {

    let queries = {
      update: [],
      insert: [],
      sheet: {}
    } 
    console.log(this.suggestToDB)
  
    for(let i in this.suggestToDB) {
      const suggestItem = this.suggestToDB[i]

      // 削除　初期データがある場合のみ
      if(suggestItem.deletedFlag == 1) {
        // フック削除
        if(suggestItem.hook_id) {
          let del = {
            "table_name": "application_hooks",
            "non_sub_query": { 
              "set": {
                "deleted_at": this.createdAt,
              },
              "where": {
                "hooks_id": suggestItem.hook_id - 0,
                "applications_id": this.applications.id - 0, 
              },
            },
            "sub_query": {
            },
            "support_data": this.params.support_data,
            "authC": this.params.authC,
          }

          queries.update.push(del)
        }

        // 提案内容削除
        let del = {
          "table_name": "application_suggestions",
          "non_sub_query": { 
            "set": {
              "deleted_at": this.createdAt,
            },
            "where": {
              "suggestions_id": '',
              "applications_id": this.applications.id - 0, 
            },
          },
          "sub_query": {
          },
          "support_data": this.params.support_data,
          "authC": this.params.authC,
        }

        if(suggestItem.suggestion_id && suggestItem.suggestion_id - 0 != 5) {
          let query = del
          query.non_sub_query.where.suggestions_id = suggestItem.suggestion_id - 0
          console.log(query)
          queries.update.push(query)
        }
      }

      // 追加
      if(suggestItem.addFlag == 1) {
        // フック追加
        if(suggestItem.hook_id) {
          let add = {
            "table_name": "application_hooks",
            "form": {
              "non_sub_query": {
                "applications_id": this.applications.id - 0,
                "hooks_id": suggestItem.hook_id - 0,
                "created_at": this.createdAt,
              },
              "sub_query": {
                "users": false,
              }
            },
            "sub_query": {
            },
            "support_data": this.params.support_data,
            "authC": this.params.authC,
          }

          queries.insert.push(add)
        }

        // 提案内容追加
        if(suggestItem.suggestion_id) {
          let add = {
            "table_name": "application_suggestions",
            "form": {
              "non_sub_query": {
                "applications_id": this.applications.id - 0,
                "suggestions_id": suggestItem.suggestion_id - 0,
                "created_at": this.createdAt,
              },
              "sub_query": {
                "users": false,
              }
            },
            "sub_query": {
            },
            "support_data": this.params.support_data,
            "authC": this.params.authC,
          }

          if(suggestItem.suggestion_id - 0 == 5) {
            for(let i in this.currentSubsidys) {
              const subsidy = this.currentSubsidys[i]
              //助成金追加 初期データがない場合のみ
              if(subsidy.addFlag == 1 && !subsidy.initData) {    
                add.form.non_sub_query.suggestions_id = subsidy.id - 0
                queries.insert.push(add)
              }
            }
          } else {
            queries.insert.push(add)
          } 
        }
      }
    }

    // 助成金削除追加
    for(let i in this.currentSubsidys) {
      const subsidy = this.currentSubsidys[i]

      //助成金削除 
      {
        let del = {
          "table_name": "application_suggestions",
          "non_sub_query": { 
            "set": {
              "deleted_at": this.createdAt,
            },
            "where": {
              "suggestions_id": 5,
              "applications_id": this.applications.id - 0, 
            },
          },
          "sub_query": {
          },
          "support_data": this.params.support_data,
          "authC": this.params.authC,
        }
        

        if(subsidy.deletedFlag == 1) {
          del.non_sub_query.where.subsidys_id = subsidy.id - 0
          queries.update.push(del)
        }
      }

      //助成金追加
      {
        let add = {
          "table_name": "application_suggestions",
          "form": {
            "non_sub_query": {
              "applications_id": this.applications.id - 0,
              "suggestions_id": 5,
              "created_at": this.createdAt,
            },
            "sub_query": {
              "users": false,
            }
          },
          "sub_query": {
          },
          "support_data": this.params.support_data,
          "authC": this.params.authC,
        }

        //助成金追加 初期データがない場合のみ
        if(subsidy.addFlag == 1 && !subsidy.initData) {    
          add.form.non_sub_query.subsidy_id = subsidy.id - 0
          queries.insert.push(add)
        }
      }
    }


    const suggestionsElms = document.querySelectorAll(`.select_checkbox:checked`)
    let suggestionsToSheet = []
    suggestionsElms.forEach((elm) => {
      suggestionsToSheet.push(elm.dataset.name)
    })

    const documentsElms = document.querySelectorAll(`[name="require_documents"]:checked`)
    let documentsToSheet = []
    documentsElms.forEach((elm) => {
      documentsToSheet.push(elm.value)
    })

    queries.sheet.suggestions = suggestionsToSheet.join('+')
    queries.sheet.documents = documentsToSheet.join(',')
    this.suggestionsQuery = queries
    console.log(this.suggestionsQuery)
  }

  /**
   * SEOワード取得 
   */
  getSEOWords() {
    const $ = this.$
    let words = ''
    let seoTrain = ''
    const seoURL = document.getElementById('seoURL').value
    const seoWordNum = document.getElementById('seoWordNum').value
    const seoWords = document.querySelectorAll('.seoWords')
    if(seoWords.length > 0) {
      let tmp = []
      seoWords.forEach((elm) => {
        tmp.push(elm.value)
      })
      words = tmp.join(',')
    }

    const seoTrains = document.querySelectorAll('[name="seo_train"]')
    seoTrains.forEach((elm) => {
      if(elm.checked) seoTrain = elm.value
    })

    this.SEOWords = {
      seoURL: seoURL,
      seoWordNum: seoWordNum,
      words: words,
      seoTrain: seoTrain
    }
  }

  /**
   * MEOワード取得 
   */
  getMEOWords() {
    const $ = this.$
    let meoWord = ''
    let meoWordAdd = ''
    let meoTrain = ''

    const meoURL = document.getElementById('meoURL').value
    const meoWordNum = document.getElementById('meoWordNum').value
    const meoWords = document.querySelectorAll('.meoWords')
    const meoWordAreas = document.querySelectorAll('.meoWordAreas')
    const meoWordAdds = document.querySelectorAll('.meoWordAdds')

    if(meoWords.length > 0) {
      let meoWordtmp = []
      let meoWordAddtmp = []
      meoWords.forEach((elm, i) => {
        meoWordtmp.push(elm.value + '/' + meoWordAreas[i].value + 'km')
        meoWordAddtmp.push(meoWordAdds[i].value)
      })
      meoWord = meoWordtmp.join(',')
      meoWordAdd = meoWordAddtmp.join(',')
    }

    const meoTrains = document.querySelectorAll('[name="meo_train"]')
    meoTrains.forEach((elm) => {
      if(elm.checked) meoTrain = elm.value
    })

    this.MEOWords = {
      meoURL: meoURL,
      meoWordNum: meoWordNum,
      meoWords: meoWord,
      meoWordAdd: meoWordAdd,
      meoTrain: meoTrain
    }
  }

  /*
  * 全文データ再構築
  */
  rebuildInfomationText() {
    const formAp = document.getElementById('form_ap')
    const formApRadioList = formAp.elements
    let text = ''
 
    {
      const apoUser = this.insertSheetData['全文'].val.match(/アポ担当：([^\\\n]+)/)
      const balanceInfoText = this.getBalanceInfoForInformationText()  
      let balance_reason = this.insertSheetData['残高期日理由'].val ? this.insertSheetData['残高期日理由'].val : ""
      let use_credit_yesNo = this.insertSheetData['ビジクレ使用可否'].val ? this.insertSheetData['ビジクレ使用可否'].val : ""
      const changeData = document.getElementById('subjectInfo')

      if (changeData.value.includes("案件ID: ") && changeData.value.includes("案件名: ")) {
        this.subjectsID = changeData.value.split("案件ID: ")[1].split("\n")[0]
        this.insertSheetData['案件名'].val = changeData.value.split("案件名: ")[1].split("\n")[0]
      }

      const givingNameMatch = this.insertSheetData['全文'].val.match(/名乗り：([^\\\n]+)/)
      const givingName = givingNameMatch ? givingNameMatch[1] : ""

      const salesPrice = document.getElementById('salesPrice').value ? document.getElementById('salesPrice').value + "円" : ""
      const paid = document.getElementById('paid').value ? document.getElementById('paid').value + "円" : ""

      text = `◎案件情報[${formDataStore.state.version}]
案件ID：${this.subjectsID}
案件名：${this.insertSheetData['案件名'].val}
アポ担当：${apoUser[1]}

◎入金内容
受注内容：${this.suggestionsQuery.sheet.suggestions}
受注金額：${salesPrice}
決済進捗：${document.getElementById('paymentStatus').value}
決済済み金額：${paid}
${balanceInfoText}
残額期日理由：${balance_reason}
ビジクレ使用可否：${use_credit_yesNo}
補足：${this.insertSheetData['補足'].val}
`

      const post_prefix =  this.subjectsID.slice(0, 2)
      if(post_prefix == "YD" || post_prefix == "KS") {
        text += `
受注プラン：${this.insertSheetData['テストマーケ_受注プラン'].val}
初期費用：${this.insertSheetData['テストマーケ_初期費用（税込）'].val}
基本サービス料金：${this.insertSheetData['テストマーケ_基本サービス料金（税込）'].val}
同時選考人数追加オプション：${this.insertSheetData['テストマーケ_同時選考人数追加オプション'].val}
人数：${this.insertSheetData['テストマーケ_オプション人数'].val}
追加金額：${this.insertSheetData['テストマーケ_オプション追加料金'].val}
保証用プラン：${this.insertSheetData['テストマーケ_保証プラン'].val}
保証用費用：${this.insertSheetData['テストマーケ_保証用費用'].val}
`
      }

      text += `
◎引き継ぎ情報
契約日：${this.insertSheetData['契約日'].val}
必要書類：${this.insertSheetData['必要書類'].val}
送付希望日：${this.insertSheetData['送付希望日付'].val}
名乗り：${givingName}
お礼電話日時：${this.insertSheetData['お礼電話日時'].val} 

`    
    }

    const is_checked_itzai = document.querySelector('[name="select_form"]#formItzaiSelect').checked
    const is_checked_itzai_lite = document.querySelector('[name="select_form"]#formItzaiLiteSelect').checked
    const is_checked_ec = document.querySelector('[name="select_form"]#formECSelect').checked
    const is_checked_cms = document.querySelector('[name="select_form"]#formCMSSelect').checked
    const is_checked_cms_lodging = document.querySelector('[name="select_form"]#formCMSLodgingSelect').checked
    const is_checked_easier = document.querySelector('[name="select_form"]#formEasierSelect').checked
    const is_checked_agent = document.querySelector('[name="select_form"]#formAgentSelect').checked

    // イツザイ
    const motiveSigningContractValue = Array.from(
      document.querySelectorAll('input[name="motive_signing_contract"]:checked')
    ).map(input => input.value)

    const persnalityValue = Array.from(
      document.querySelectorAll('input[name="persnality"]:checked')
    ).map(input => input.value)

    const promiseInsertionValue = Array.from(
      document.querySelectorAll('input[name="promise_insertion"]:checked')
    ).map(input => input.value)

    const promiseContentsValue = Array.from(
      document.querySelectorAll('input[name="promise_contents"]:checked')
    ).map(input => input.value)

    const brandPage = document.querySelector('[name="Brand_page_itzai"]:checked')

    if(is_checked_itzai) {
      text += `◎イツザイ
動画撮影：${formApRadioList['movie_yesNo'].value}
プラン：${document.querySelector('[name="itzai_plan"]:checked').value}
ブランドページ：${brandPage ? brandPage.value : ""}
PRページ制作代行本数：${document.getElementById('PR_pages_produced_itzai').value}
募集要項制作代行数：${document.getElementById('recruitment_requirements_produced_itzai').value}
KV追加制作代行数：${document.getElementById('KV_produced_itzai').value}
補償オプション：${formApRadioList['compensation_yesNo'].value}
その他オプション：${document.getElementById('otherOp').value}
募集業界：${document.getElementById('industry_names').value}
募集職種名：${document.getElementById('rctJobCate').value}
撮影有無：${formApRadioList['shoot_yesNo'].value}
撮影無し理由：${document.getElementById('reasonNotShoot').value}
契約動機：${motiveSigningContractValue}
契約動機詳細：${document.getElementById('contractReason').value}
顧客人柄：${persnalityValue}
お客様との会話内容：${document.getElementById('conv').value}
イツザイに載せる内容について話した内容：${promiseInsertionValue}
上記の詳細：${document.getElementById('convItzai').value}
サービス内容について提案した内容：${promiseContentsValue}
上記の詳細：${document.getElementById('convService').value}
掲載希望日時：${document.getElementById('shootDate').value}
求人背景：${document.getElementById('rctBackground').value}
どういう人が取りたいか：${document.getElementById('kind_person_Hope').value}
その他顧客希望：${document.getElementById('otherHope').value}
`

    const main_contact_yes_no = document.querySelector('input[name="main_contact_itzai"]:checked')
    if (main_contact_yes_no.value == "はい") {
      text = text + `
連絡先情報備考（主要窓口）：${document.getElementById('contact_itzai').value}
商談同席有無：${document.querySelector('[name="main_contact_itzai_presence"]').value}
`
    }

    text = text + `
求人ボックスキャンペーン対応：${formApRadioList['guide_job_offer_campaign'].value}

`

    }

    // イツザイ ライト
    const motiveSigningContractValueLite = Array.from(
      document.querySelectorAll('input[name="motive_signing_contract_lite"]:checked')
    ).map(input => input.value)

    const persnalityValueLite = Array.from(
      document.querySelectorAll('input[name="persnality_lite"]:checked')
    ).map(input => input.value)

    const promiseInsertionValueLite = Array.from(
      document.querySelectorAll('input[name="promise_insertion_lite"]:checked')
    ).map(input => input.value)

    const promiseContentsValueLite = Array.from(
      document.querySelectorAll('input[name="promise_contents_lite"]:checked')
    ).map(input => input.value)

    const brandPageLite = document.querySelector('[name="Brand_page_itzai_lite"]:checked')

    if(is_checked_itzai_lite) {
      text += `◎イツザイ ライト
動画撮影：${formApRadioList['movie_yesNo_lite'].value}
プラン：${document.querySelector('[name="itzai_lite_plan"]:checked').value}
ブランドページ：${brandPageLite ? brandPageLite.value : ""}
PRページ制作代行本数：${document.getElementById('PR_pages_produced_itzai_lite').value}
募集要項制作代行数：${document.getElementById('recruitment_requirements_produced_itzai_lite').value}
KV追加制作代行数：${document.getElementById('KV_produced_itzai_lite').value}
補償オプション：${formApRadioList['compensation_yesNo_lite'].value}
その他オプション：${document.getElementById('otherOp_lite').value}
募集業界：${document.getElementById('industry_names_lite').value}
募集職種名：${document.getElementById('rctJobCate_lite').value}
撮影有無：${formApRadioList['shoot_yesNo_lite'].value}
撮影無し理由：${document.getElementById('reasonNotShoot_lite').value}
契約動機：${motiveSigningContractValueLite}
契約動機詳細：${document.getElementById('contractReasonLite').value}
顧客人柄：${persnalityValueLite}
お客様との会話内容：${document.getElementById('conv_lite').value}
イツザイに載せる内容について話した内容：${promiseInsertionValueLite}
上記の詳細：${document.getElementById('convItzaiLite').value}
サービス内容について提案した内容：${promiseContentsValueLite}
上記の詳細：${document.getElementById('convServiceLite').value}
掲載希望日時：${document.getElementById('shootDate_lite').value}
求人背景：${document.getElementById('rctBackground_lite').value}
どういう人が取りたいか：${document.getElementById('kind_person_Hope_lite').value}
その他顧客希望：${document.getElementById('otherHope_lite').value}
`

    const main_contact_yes_no = document.querySelector('input[name="main_contact_itzai_lite"]:checked')
    if (main_contact_yes_no.value == "はい") {
      text = text + `
連絡先情報備考（主要窓口）：${document.getElementById('contact_itzai_lite').value}
商談同席有無：${document.querySelector('[name="main_contact_itzai_lite_presence"]').value}
`
    }

    text = text + `
求人ボックスキャンペーン対応：${formApRadioList['guide_job_offer_campaign_lite'].value}

`
    }

    // EC
    if(is_checked_ec) {
      let contact = document.getElementById('contact_ec').value

      text += `◎EC
型番：${document.getElementById('ecVersion').value}
お客様共有事項：${document.getElementById('sharedMatters_ec').value}
契約動機：${document.getElementById('contractReason_ec').value}
顧客人柄：${document.getElementById('persnality_ec').value}
お客様との会話内容：${document.getElementById('conv_ec').value}
顧客希望：${document.getElementById('hope_ec').value}
商品登録点数：${document.getElementById('productsNum').value}
サイト制作数：${document.getElementById('ecSiteProductsNum').value}
掲載希望日時：${document.getElementById('shootDate_ec').value}
商品撮影：${formApRadioList['shoot_yesNo_ec'].value}
撮影商品数：${document.getElementById('ecProductsNum').value}
撮影カット数：${document.getElementById('ecCutNum').value}
撮影場所：${formApRadioList['shootLoca'].value}
EC導入決済(コンビニ or 定期利用)：${formApRadioList['ecPaymentMethod'].value}
連絡先情報備考：${contact}

`
    }

    // WEL
    if(document.querySelector('[name="select_form"]#formWELSelect').checked) {

      text += `◎WEL
カテゴリ数：${document.getElementById('welCateNum').value}
カテゴリ：${document.getElementById('welCate').value}
特典：${document.getElementById('welBenefits').value}
備考欄・特記事項：${document.getElementById('welOtherShared').value}

`
    }

    // リロ
    if(document.querySelector('[name="select_form"]#formReloSelect').checked) {

      text +=  `◎リロ
カテゴリ数：${document.getElementById('reloCateNum').value}
カテゴリ：${document.getElementById('reloCate').value}
特典：${document.getElementById('reloBenefits').value}
備考欄・特記事項：${document.getElementById('reloOtherShared').value}

`
    }

    // SEO
    if(document.querySelector('[name="select_form"]#formSEOSelect').checked) {
      let $seoWords = document.querySelectorAll('.seoWords')
      let inputSeoWords = ''
      let seoWordsAry = []
  
      for(let i = 0; i < $seoWords.length; i++ ) { 
        if($seoWords[i].value !== "") {
          seoWordsAry.push($seoWords[i].value)
          inputSeoWords += '\n' + '（' + (i+1) + '）' + $seoWords[i].value
        }
      }

      text +=  `◎SEO
ワード数：${document.getElementById('seoWordNum').value}
ワード：${inputSeoWords}
SEO対象URL：${document.getElementById('seoURL').value}
対策期間：${formApRadioList['seo_train'].value}

`
    }

    // MEO
    if(document.querySelector('[name="select_form"]#formMEOSelect').checked) {
      let $meoWords = document.querySelectorAll('.meoWords')
      let $meoAreas = document.querySelectorAll('.meoWordAreas')
      let $meoAdds = document.querySelectorAll('.meoWordAdds')
  
      let meoWords = ''
      let meoAreas = ''
      let meoAddress = ''
      let meoWordsAry = []
  
      for (let i = 0; i < $meoWords.length; i++) {
        let word = $meoWords[i].value
        let area = $meoAreas[i].value
        let address = $meoAdds[i].value
  
        if(word !== "" && area !== "" && address !== "") {
          meoWords += '\n' + '（' + (i+1) + '）' + word
          meoAreas += '\n' + '（' + (i+1) + '）' + area + '㎞'
          meoAddress += '\n' + '（' + (i+1) + '）' + address
          meoWordsAry[i] = {
            word: this.func.textEscape(word),
            km: Number(area),
            address: this.func.textEscape(address)
          }
        }
      }
  
      const inputMeoWord =
      `ワード：${this.func.textEscape(meoWords)}
対象範囲：${meoAreas}
中心地点住所：${this.func.textEscape(meoAddress)}`


      text +=  `◎MEO
店舗URL：${document.getElementById('meoURL').value}
対策期間：${formApRadioList['meo_train'].value}
ワード数：${document.getElementById('meoWordNum').value}
${inputMeoWord}

`
    }

    // CMS
    if(is_checked_cms) {
      let contact = document.getElementById('contact_cms').value

      text +=  `◎CMS
動画撮影：${formApRadioList['cms_should_movie'].value}
サイト制作数：${document.getElementById('cmsProductsNum').value}
掲載希望日時：${document.getElementById('shootDate_cms').value}
型番：${document.getElementById('cmsVersion').value}
お客様共有事項：${document.getElementById('sharedMatters_cms').value}
撮影有無：${formApRadioList['shoot_yesNo_cms'].value}
撮影無し理由：${document.getElementById('reasonNotShoot_cms').value}
契約動機：${document.getElementById('contractReason_cms').value}
顧客人柄：${document.getElementById('persnality_cms').value}
お客様との会話内容：${document.getElementById('conv_cms').value}
顧客希望：${document.getElementById('hope_cms').value}
連絡先情報備考：${contact}

`
    }

    // CMS（宿泊型番）
    if(is_checked_cms_lodging) {
      let contact = document.getElementById('contact_cms_lodging').value

      text +=  `◎CMS（宿泊型番）
動画撮影：${formApRadioList['cms_lodging_should_movie'].value}
サイト制作数：${document.getElementById('cmsLodgingProductsNum').value}
掲載希望日時：${document.getElementById('shootDate_cms_lodging').value}
型番：${document.getElementById('cmsLodgingVersion').value}
お客様共有事項：${document.getElementById('sharedMatters_cms_lodging').value}
撮影有無：${formApRadioList['shoot_yesNo_cms_lodging'].value}
撮影無し理由：${document.getElementById('reasonNotShoot_cms_lodging').value}
契約動機：${document.getElementById('contractReason_cms_lodging').value}
顧客人柄：${document.getElementById('persnality_cms_lodging').value}
お客様との会話内容：${document.getElementById('conv_cms_lodging').value}
顧客希望：${document.getElementById('hope_cms_lodging').value}
連絡先情報備考：${contact}

`
    }

    // Easier
    const contractReasonEasier = Array.from(
      document.querySelectorAll('input[name="contractReason_easier"]:checked')
    ).map(input => input.value)

    const persnalityValueEasier = Array.from(
      document.querySelectorAll('input[name="persnality_easier"]:checked')
    ).map(input => input.value)

    const promiseInsertionEasier = Array.from(
      document.querySelectorAll('input[name="promise_insertion_easier"]:checked')
    ).map(input => input.value)

    const promiseServiceEasier = Array.from(
      document.querySelectorAll('input[name="promise_service_easier"]:checked')
    ).map(input => input.value)

    if(is_checked_easier) {

      text +=  `◎Easier
動画撮影：${formApRadioList['easier_should_movie'].value}
プラン：${formApRadioList['easier_plan'].value}
ブランドページ：${formApRadioList['Brand_page_production_easier'].value}
PRページ制作代行数：${document.getElementById('PR_pages_produced_easier').value}
MEO対策：${formApRadioList['MEO_measures_easier'].value}
MEO対策ワード数：${document.getElementById('word_number_easier').value}
有料広告運用代行サービス：${formApRadioList['advertising_substitution_easier'].value}
有料広告費用：${document.getElementById('paid_advertising_expenses_easier').value}
募集業界：${document.getElementById('industry_names_easier').value}
撮影有無：${formApRadioList['shoot_yesNo_easier'].value}
撮影無し理由：${document.getElementById('none_shoot_reason').value}
契約動機：${contractReasonEasier.join(",")}
契約動機詳細：${document.getElementById('contract_reason_easier_other').value}
顧客人柄：${persnalityValueEasier.join(",")}
お客様との会話内容：${document.getElementById('conv_easier').value}
Easierに載せる内容について話した内容：${promiseInsertionEasier.join(",")}
上記の詳細：${document.getElementById('promise_insertion_easier_detail').value}
サービス内容について提案した内容：${promiseServiceEasier.join(",")}
上記の詳細：${document.getElementById('promise_service_easier_detail').value}
掲載希望日時：${document.getElementById('date_publication_easier').value}
他媒体効果：${document.getElementById('background_customers_easier').value}
その他顧客希望：${document.getElementById('sharing_operators_easier').value}
テストマーケのMEO無料運用サービス：${formApRadioList['test_service_easier_yesNo'].value}
`
    }

    // 助成金
    if(document.querySelector('[name="select_form"]#formSubsidySelect').checked) {
      const subsidies = document.querySelectorAll(`[name="subsidy"]:checked`)
      this.subsidyText = []
      subsidies.forEach((elm) => {
        this.subsidyText.push(elm.value)
      })
      this.subsidyText = this.subsidyText.join(',')

      const requireDdocuments = document.querySelectorAll(`[name="require_documents"]:checked`)
      this.requireDdocumentsText = []
      requireDdocuments.forEach((elm) => {
        this.requireDdocumentsText.push(elm.value)
      })
      this.requireDdocumentsText = this.requireDdocumentsText.join(',')

      text +=  `◎助成金
対象助成金：${this.subsidyText}
未回収必須書類：${this.requireDdocumentsText}
直近の採用状況：${document.getElementById('recruitment_status').value}
申請時の懸念点：${document.getElementById('concern').value}

`
    }

    // 補償オプション
    if(document.querySelector('[name="select_form"]#formComOptionSelect').checked) {

      text +=  `◎補償オプション
補償オプション：${formApRadioList['com_option'].value}

`
    }

    // 動画制作
    if(document.querySelector('[name="select_form"]#formVideoProductionSelect').checked) {

      text +=  `◎動画制作
プラン：${formApRadioList['video_production'].value}

`
    }

    // イツザイエージェント
    if(is_checked_agent) {
      const agentMotiveSigningContract = Array.from(
        document.querySelectorAll('input[name="agent_motive_signing_contract"]:checked')
      ).map(input => input.value)

      const agentPersnality = Array.from(
        document.querySelectorAll('input[name="agent_persnality"]:checked')
      ).map(input => input.value)

      const agentPromiseInsertion = Array.from(
        document.querySelectorAll('input[name="agent_promise_insertion"]:checked')
      ).map(input => input.value)

      const agentPromiseContents = Array.from(
        document.querySelectorAll('input[name="agent_promise_contents"]:checked')
      ).map(input => input.value)

      text +=  `◎イツザイエージェント
受注プラン：${formApRadioList['agent_plan'].value}

初期費用：${document.getElementById('agent_initialCost').value}
基本サービス料金：${document.getElementById('agent_basicServiceFee').value}

同時選考人数追加オプション：${formApRadioList['agent_selection_people_option'].value}
人数：${document.getElementById('agent_selection_people').value}
追加金額：${document.getElementById('agent_additional_amounts').value}

保証用プラン：${formApRadioList['agent_warranty_plans'].value}
保証用費用：${document.getElementById('agent_warranty_expenses').value}

契約動機：${agentMotiveSigningContract.join(",")}
契約動機詳細：${document.getElementById('agent_contract_reason').value}

顧客人柄：${agentPersnality.join(",")}
お客様との会話内容：${document.getElementById('agent_conversation_content').value}

イツザイエージェントに載せる内容について話した内容：${agentPromiseInsertion.join(",")}
上記の詳細：${document.getElementById('conv_agent').value}

サービス内容について提案した内容：${agentPromiseContents.join(",")}
上記の詳細：${document.getElementById('agent_conv_service').value}

採用活動開始希望日時（ヒアアポ～の期間・掲載期間を伝えた上で）：${document.getElementById('agent_adoption_start_date').value}
求人背景：${document.getElementById('agent_rct_background').value}
どういう人が取りたいか：${document.getElementById('agent_kind_person_Hope').value}

`
    }

    // オプション
    if(document.querySelector('[name="select_form"]#formOptionSelect').checked) {
      text +=  `◎オプション
オプション：${document.getElementById('optionVal').value}

`
    }

    //連絡先情報
      text += `◎連絡先情報
主要連絡先：${formApRadioList['mainContact'].value}
社名：${document.getElementById('contactComName').value}
本社住所：${document.getElementById('companyAddress').value}
代表取締役者名：${document.getElementById('contactNameCEO').value}
代表取締役者名（カナ）：${document.getElementById('contactNameCEOCana').value}
代表メールアドレス：${document.getElementById('contactMailCEO').value}
代表電話番号（固定）：${document.getElementById('contactTelNumCEO').value}
代表電話番号（携帯）：${document.getElementById('contactTelephoneCEO').value}
WEB打ち合わせ可否：${formApRadioList['canOnlineMtgCEO_yesNo'].value}
代表連絡先情報備考：${document.getElementById('specialMention').value}

`
    if (formApRadioList['mainContact'].value != "代表のみ") {
      text +=`
担当者名：${document.getElementById('contactNameStaff').value}
担当者名（カナ）：${document.getElementById('contactNameStaffCana').value}
担当メールアドレス：${document.getElementById('contactMailStaff').value}
担当電話番号（固定）：${document.getElementById('contactTelNumStaff').value}
担当電話番号（携帯）：${document.getElementById('contactTelephoneStaff').value}
WEB打ち合わせ可否：${formApRadioList['canOnlineMtgStaff_yesNo'].value}
担当者の商談同席有無：${formApRadioList['canMtgStaff_yesNo'].value}
担当連絡先情報備考：${document.getElementById('contactStaffRemarks').value}

`
    }

      text +=  `
進行可否：${formApRadioList['progress_yesNo'].value}
-----------------------------
POST ID：${this.postID}
こちらのIDはSP投稿画面からの投稿IDです。",
`

    return text
  }


  

  /**
   * 論理削除用クエリ構築
   */
  async buildDeleteQuery() {
    // DB用
    const tables = ["applications", "application_hooks", "application_suggestions", "balance_payment_methods"]
    this.delQueries = []

    tables.forEach((val) => {
      let query = {
        "table_name": val,
        "non_sub_query": { 
          "set": {
            "deleted_at": this.createdAt,
          },
          "where": {
            "applications_id": this.applications.id,
          }
        },
        "sub_query": {
        },
        "support_data": this.params.support_data,
        "authC": this.params.authC,
      }

      if(val == "applications") {
        delete query.non_sub_query.where.applications_id
        query.non_sub_query.where.id = this.applications.id
      }

      this.delQueries.push(query)
    })

    // スプレッドシート用
    this.delSheetQuery = {
      'spread': this.spreadData,
      'target_lines': [
        {"column": "投稿ID", "value": this.postID},
        {"column": "削除日時", "value": ''},
      ],
      "update_cells": [
        {"column": "削除日時", "value": this.createdAt},
      ]
    }

    console.log(this.delQueries, this.delSheetQuery)
  }

  /*
    DBをアップデート
  */
  async updateDB() {

    return fetch(this.DBUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toDBUpdate)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'DBアップデート失敗 Discordメッセージ確認'
      }

      console.log('DBアップデート成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }

  /*
    DBに挿入
  */
  async insertDB() {

    if(this.query.toDBInsert.length == 0) return true

    return fetch(this.DBInsertApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toDBInsert)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'DB追加失敗 Discordメッセージ確認'
      }

      console.log('DB追加成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }

  /*
    シートをアップデート
  */
  async updateSheet() {

    if(this.query.toSheetUpdate.length == 0) return true

    return fetch(this.spreadsheetUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toSheetUpdate)
    })
    .then((res) => res.text())
    .then((res) => {

      if(res - 0 == 99) {
        throw 'シート更新失敗 Discordメッセージ確認'
      }

      console.log('シート更新成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

  }

  /*
    シートに挿入
  */
  async insertSheet() {

    if(this.query.toSheetInsert.length == 0) return true

    return fetch(this.spreadsheetInsertApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toSheetInsert)
    })
    .then((res) => res.text())
    .then((res) => {

      if(res - 0 == 99) {
        throw 'シート追加失敗 Discordメッセージ確認'
      }

      console.log('シート追加成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

  }

  /*
    DBのデータを論理削除
  */
  async deleteDBData() {

    return fetch(this.DBUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.delQueries)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'DBデータ削除日時セット失敗 Discordメッセージ確認'
      }

      console.log('DBデータ削除日時セット成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

  }

  /*
    シートのデータを論理削除
  */
  async deleteSheetData() {

    return fetch(this.spreadsheetUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.delSheetQuery)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'シートデータ削除日時セット失敗 Discordメッセージ確認'
      }

      console.log('シートデータ削除日時セット成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }

  // kintoneデータを格納
  async insertKintoneData() {
    const kintoneInsertData = this.getFormKintoneData()

    return fetch(this.kintoneInsertApi, {
      method: "POST",
      headers: {
        "Content-Type" : "application/json",
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(kintoneInsertData)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'kintoneデータ格納失敗'
      }

      console.log('kintoneデータ格納成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }

  // kintoneデータを論理削除
  async deleteKintoneData() {
    const $ = this.$
    const kintoneUpdateData = {
      "app": this.appID,
      "query": `案件ID = "${$("#editSubjectsID").val()}" and レコード無効フラグ in ("有効")`,
      "record": {"レコード無効フラグ": {"value": "無効"}},
    }

    return fetch(this.kintoneUpdateApi, {
      method: "POST",
      headers: {
        "Content-Type" : "application/json",
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(kintoneUpdateData)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'kintoneデータ削除失敗'
      }

      console.log('kintoneデータ削除成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }

  getFormKintoneData() {
    const $ = this.$

    const givingNameMatch = this.insertSheetData['全文'].val.match(/名乗り：([^\\\n]+)/)
    let givingName = givingNameMatch ? givingNameMatch[1] : ""
    givingName = givingName == "サングローブ" ? ["SG"] : [givingName]

    const desiredSendingDate = this.insertSheetData['送付希望日付'].val.replace(/\//g, "-").split("（")[0]
    const thankCallDate = this.insertSheetData['お礼電話日時'].val.replace(/\//g, "-").split("（")[0]
    const contractDate = this.insertSheetData['契約日'].val.replace(/\//g, "-").split("（")[0]

    // 下記は編集できない項目なので先にkintoneからデータ取得する必要あり
    const fieldCodeUneditDate = {
      "案件名": {"value": this.insertSheetData['案件名'].val},
      "必要書類": {"value": this.insertSheetData['必要書類'].val},
      "必要書類送付希望日": {"value": desiredSendingDate},
      "御礼電話日時": {"value": thankCallDate},
      "アポ名乗り": {"value": givingName},
      "契約日": {"value": contractDate},
    }

    const selectedBalanceDeadline = Array.from(
      document.querySelectorAll("[name='balanceDeadline']")
    ).map(input => input.value)

    const selectedPaymentMethods = Array.from(
      document.querySelectorAll("[name='paymentMethod']")
    ).map(select => select.value)

    const selectedBalance = Array.from(
      document.querySelectorAll("[name='balance']")
    ).map(input => input.value)

    const selectedForm = Array.from(
      document.querySelectorAll("input[name='select_form']:checked")
    ).map(input => input.dataset.name)

    const tableValue = []
    if ($("#paymentStatus").val() != "決済完了") {
      for (let i = 0; i < selectedPaymentMethods.length; i++) {
        const value = {
          "id": null,
          "value": {
            "残額期日": {"value": selectedBalanceDeadline[i]},
            "支払い方法": {"value": selectedPaymentMethods[i]},
            "残額": {"value": parseInt(selectedBalance[i].replace(/,/g, ""), 10)}
          }
        }
        tableValue.push(value)
      }
    }

    const fieldCodeBasis = {
      "案件ID": {"value": $("#editSubjectsID").val()},
      "レコード無効フラグ": {"value": "有効"},
      "受注金額_税込": {"value": parseInt($("#salesPrice").val().replace(/,/g, ""), 10)},
      "決済済み金額": {"value": parseInt($("#paid").val().replace(/,/g, ""), 10)},
      "決済進捗": {"value": $("#paymentStatus").val()},
      "ビジクレ使用可否": {"value": $('input[name="use_credit_yesNo"]:checked').val() ? [$('input[name="use_credit_yesNo"]:checked').val()] : ""},
      "残額支払予定": {"value": tableValue.length == 0 ? "" : tableValue},
      "残額期日理由": {"value": $("#balanceDeadlineReason").val() ? $("#balanceDeadlineReason").val() : ""},
      "受注内容": {"value": selectedForm}
    }

    const fieldCodeContactInfoBasis = {
      "会社名": {"value": $("#contactComName").val() ? $("#contactComName").val() : ""},
      "主要連絡先": {"value": $('input[name="mainContact"]:checked').val() ? $('input[name="mainContact"]:checked').val() : ""},
      "代表取締役名": {"value": $("#contactNameCEO").val() ? $("#contactNameCEO").val() : ""},
      "代表取締役名_カナ": {"value": $("#contactNameCEOCana").val() ? $("#contactNameCEOCana").val() : ""},
      "代表メールアドレス": {"value": $("#contactMailCEO").val() ? $("#contactMailCEO").val() : ""},
      "代表電話番号_固定": {"value": $("#contactTelNumCEO").val() ? $("#contactTelNumCEO").val() : ""},
      "代表電話番号_携帯": {"value": $("#contactTelephoneCEO").val() ? $("#contactTelephoneCEO").val() : ""},
      "WEB打ち合わせ_代表": {"value": $('input[name="canOnlineMtgCEO_yesNo"]:checked').val() ? [$('input[name="canOnlineMtgCEO_yesNo"]:checked').val()] : ""},
      "代表連絡先情報備考": {"value": $("#specialMention").val() ? $("#specialMention").val() : ""},
      "進行可否": {"value": $('input[name="progress_yesNo"]:checked').val() ? $('input[name="progress_yesNo"]:checked').val() : ""},
    }

    const fieldCodeContactInfoStaff = {
      "担当者名": {"value": $("#contactNameStaff").val() ? $("#contactNameStaff").val() : ""},
      "担当者名_カナ": {"value": $("#contactNameStaffCana").val() ? $("#contactNameStaffCana").val() : ""},
      "担当メールアドレス": {"value": $("#contactMailStaff").val() ? $("#contactMailStaff").val() : ""},
      "担当電話番号_固定": {"value": $("#contactTelNumStaff").val() ? $("#contactTelNumStaff").val() : ""},
      "担当電話番号_携帯": {"value": $("#contactTelephoneStaff").val() ? $("#contactTelephoneStaff").val() : ""},
      "WEB打ち合わせ_担当": {"value": $('input[name="canOnlineMtgStaff_yesNo"]:checked').val() ? [$('input[name="canOnlineMtgStaff_yesNo"]:checked').val()] : ""},
      "担当連絡先情報備考": {"value": $("#contactStaffRemarks").val() ? $("#contactStaffRemarks").val() : ""},
      "担当者の商談同席有無": {"value": $('input[name="canMtgStaff_yesNo"]:checked').val() ? [$('input[name="canMtgStaff_yesNo"]:checked').val()] : ""},
    }

    const selectedContractReason = Array.from(
      document.querySelectorAll("input[name='contractReason_easier']:checked")
    ).map(input => input.value)

    const selectedPersnality = Array.from(
      document.querySelectorAll("input[name='persnality_easier']:checked")
    ).map(input => input.value)

    const selectedPromiseInsertion = Array.from(
      document.querySelectorAll("input[name='promise_insertion_easier']:checked")
    ).map(input => input.value)

    const selectedPromiseService = Array.from(
      document.querySelectorAll("input[name='promise_service_easier']:checked")
    ).map(input => input.value)

    const fieldCodeContactEasier = {
      "動画撮影_Easier": {"value": $('input[name="easier_should_movie"]:checked').val() ? $('input[name="easier_should_movie"]:checked').val() : ""},
      "Easierプラン": {"value": $('input[name="easier_plan"]:checked').val() ? [$('input[name="easier_plan"]:checked').val()] : ""},
      "ブランドページ_Easier": {"value": $('input[name="Brand_page_production_easier"]:checked').val() ? $('input[name="Brand_page_production_easier"]:checked').val() : ""},
      "PRページ制作代行数_Easier": {"value":  $("#PR_pages_produced_easier").val() ? parseInt($("#PR_pages_produced_easier").val()) : ""},
      "MEO対策_Easier": {"value": $('input[name="MEO_measures_easier"]:checked').val() ? $('input[name="MEO_measures_easier"]:checked').val() : ""},
      "MEO対策ワード数_Easier": {"value": $("#word_number_easier").val() ? parseInt($("#word_number_easier").val()) : ""},
      "テストマーケ_MEO無料運用サービス_Easier": {"value": $('input[name="test_service_easier_yesNo"]:checked').val() ? $('input[name="test_service_easier_yesNo"]:checked').val() : ""},
      "有料広告運用代行サービス_Easier": {"value": $('input[name="advertising_substitution_easier"]:checked').val() ? $('input[name="advertising_substitution_easier"]:checked').val() : "" },
      "有料広告費_Easier": {"value": $("#paid_advertising_expenses_easier").val() ? parseInt($("#paid_advertising_expenses_easier").val()) : ""},
      "業界_Easier": {"value": $("#industry_names_easier").val() ? $("#industry_names_easier").val() : ""},
      "撮影有無_Easier": {"value": $('input[name="shoot_yesNo_easier"]:checked').val() ? $('input[name="shoot_yesNo_easier"]:checked').val() : ""},
      "撮影無し理由_Easier": {"value": $("#none_shoot_reason").val() ? $("#none_shoot_reason").val() : ""},
      "契約動機_Easier": {"value": selectedContractReason ? selectedContractReason.join(",") : ""},
      "契約動機詳細_Easier": {"value": $("#contract_reason_easier_other").val() ? $("#contract_reason_easier_other").val() : ""},
      "顧客人柄_Easier": {"value": selectedPersnality ? selectedPersnality.join(",") : ""},
      "お客様との会話内容_Easier": {"value": $("#conv_easier").val() ? $("#conv_easier").val() : ""},
      "Easier制作物": {"value": selectedPromiseInsertion ? selectedPromiseInsertion.join(",") : ""},
      "Easier制作物_詳細": {"value": $("#promise_insertion_easier_detail").val() ? $("#promise_insertion_easier_detail").val() : ""},
      "Easierサービス内容": {"value": selectedPromiseService ? selectedPromiseService.join(",") : ""},
      "Easierサービス内容_詳細": {"value": $("#promise_service_easier_detail").val() ? $("#promise_service_easier_detail").val() : ""},
      "掲載希望日時_Easier": {"value": $("#date_publication_easier").val() ? $("#date_publication_easier").val() : ""},
      "他媒体効果_Easier": {"value": $("#background_customers_easier").val() ? $("#background_customers_easier").val() : ""},
      "その他顧客希望_Easier": {"value": $("#sharing_operators_easier").val() ? $("#sharing_operators_easier").val() : ""},
    }

    const mergedFieldCodeContact = {
      ...fieldCodeUneditDate,
      ...fieldCodeBasis,
      ...fieldCodeContactInfoBasis,
      ...fieldCodeContactInfoStaff,
      ...fieldCodeContactEasier
    }

    Object.keys(mergedFieldCodeContact).forEach((key) => {
      if (mergedFieldCodeContact[key].value == "") {
        delete mergedFieldCodeContact[key]
      }
    })

    const pureData = JSON.parse(JSON.stringify(mergedFieldCodeContact))

    const kintoneInsertData = {
      "app": this.appID,
      "record": {}
    }

    Object.keys(pureData).forEach(key => {
      kintoneInsertData.record[key] = pureData[key]
    })

    return kintoneInsertData
  }
}