
/*
* ぽちボタンクリック後の処理
*/ 

class afrPostAgent {
  constructor(that) {
    this.that = that
    this.formName = "イツザイエージェント"
    const $ = this.that.$
    const store = this.that.store

    if(store.state.industryNamesData.length == 0) {
      this.that.vueObj.viewLoader('業界リスト読み込み中')
    }

    let i = 0
    const sit = setInterval(() => {

      if(store.state.industryNamesData.length > 0) {
        clearInterval(sit)
        this.that.vueObj.viewLoader()

        // データ出力先有無をチェック
        if($('#industry_names')[0]) {
          const industry_records = store.state.industryNamesData
          
          for(let i = 0; i < industry_records.length; i++) {
            let record = industry_records[i]
            let option = $('<option>')

            option.attr({
              id: 'jobsCategoriesID' + record.id,
              value: record.name,
              'data-id': record.id
            }).text(record.name)
            
            $('#industry_names').append(option).show()
          }

        }

      } else {
        i++
        if(i >= 200) {
          clearInterval(sit)
          alert('業界リストを取得できませんでした\nリロードします')
          window.location.reload()
        }
      }

    }, 50)


    this.that.$('.search_form_wrap').show()
    // this.actionInit()
  }

  returnFormName() {
    return this.formName
  }

  getInputData () {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const func = this.that.func

    const motiveSigningContractValue = Array.from(
      document.querySelectorAll('input[name="agent_motive_signing_contract"]:checked')
    ).map(input => input.value)

    const persnalityValue = Array.from(
      document.querySelectorAll('input[name="agent_persnality"]:checked')
    ).map(input => input.value)

    const promiseInsertionValue = Array.from(
      document.querySelectorAll('input[name="agent_promise_insertion"]:checked')
    ).map(input => input.value)

    const promiseContentsValue = Array.from(
      document.querySelectorAll('input[name="agent_promise_contents"]:checked')
    ).map(input => input.value)

    // 値取得：制作前共有
    store.commit('setValue', ['agent', 'inputPlan', $('[name="agent_plan"]:checked').val(), ''])
    store.commit('setValue', ['agent', 'inputInitialCost', $('#agent_initialCost').val(), 'initial_cost'])
    store.commit('setValue', ['agent', 'inputBasicServiceFee', $('#agent_basicServiceFee').val(), 'basic_service_fee'])
    store.commit('setValue', ['agent', 'inputSelectionPeopleOption', $('[name="agent_selection_people_option"]:checked').val(), 'selection_people_option'])
    store.commit('setValue', ['agent', 'inputSelectionPeople', $('#agent_selection_people').val(), 'selection_people'])
    store.commit('setValue', ['agent', 'inputAdditionalAmounts', $('#agent_additional_amounts').val(), 'additional_amounts'])
    store.commit('setValue', ['agent', 'inputWarrantyPlans', $('[name="agent_warranty_plans"]:checked').val(), 'warranty_plans'])
    store.commit('setValue', ['agent', 'inputWarrantyExpenses', $('#agent_warranty_expenses').val(), 'warranty_expenses'])
    store.commit('setValue', ['agent', 'inputMotiveSigningContract', motiveSigningContractValue, ''])
    store.commit('setValue', ['agent', 'inputAgentContractReason', func.textEscape($('#agent_contract_reason').val()), ''])
    store.commit('setValue', ['agent', 'inputAgentPersnality', persnalityValue, ''])
    store.commit('setValue', ['agent', 'inputAgentConversationContent', func.textEscape($('#agent_conversation_content').val()), ''])
    store.commit('setValue', ['agent', 'inputPromiseInsertion', promiseInsertionValue, ''])
    store.commit('setValue', ['agent', 'inputConvAgent', func.textEscape($('#conv_agent').val()), ''])
    store.commit('setValue', ['agent', 'inputPromiseContents', promiseContentsValue, ''])
    store.commit('setValue', ['agent', 'inputAgentConvService', func.textEscape($('#agent_conv_service').val()), ''])
    store.commit('setValue', ['agent', 'inputAgentAdoptionStartDate', func.textEscape($('#agent_adoption_start_date').val()), ''])
    store.commit('setValue', ['agent', 'inputAgentRctBackground', func.textEscape($('#agent_rct_background').val()), ''])
    store.commit('setValue', ['agent', 'inputAgentKindPersonHope', func.textEscape($('#agent_kind_person_Hope').val()), ''])

  }

  setVal (balanceInfoMsg) {
    const store = this.that.store

    let sendVal = 
`
◎イツザイエージェント
受注プラン：${store.state.formData.agent.inputPlan}

初期費用：${store.state.formData.agent.inputInitialCost ? store.state.formData.agent.inputInitialCost + '円' : ''}
基本サービス料金：${store.state.formData.agent.inputBasicServiceFee ? store.state.formData.agent.inputBasicServiceFee + '円' : ''}

同時選考人数追加オプション：${store.state.formData.agent.inputSelectionPeopleOption}
人数：${store.state.formData.agent.inputSelectionPeople ? store.state.formData.agent.inputSelectionPeople + '人' : ''}
追加金額：${store.state.formData.agent.inputAdditionalAmounts ? store.state.formData.agent.inputAdditionalAmounts + '円' : ''}

保証用プラン：${store.state.formData.agent.inputWarrantyPlans}
保証用費用：${store.state.formData.agent.inputWarrantyExpenses ? store.state.formData.agent.inputWarrantyExpenses + '円' : ''}

契約動機：${store.state.formData.agent.inputMotiveSigningContract}
契約動機詳細：${store.state.formData.agent.inputAgentContractReason}

顧客人柄：${store.state.formData.agent.inputAgentPersnality}
お客様との会話内容：${store.state.formData.agent.inputAgentConversationContent}

イツザイエージェントに載せる内容について話した内容：${store.state.formData.agent.inputPromiseInsertion}
上記の詳細：${store.state.formData.agent.inputConvAgent}

サービス内容について提案した内容：${store.state.formData.agent.inputPromiseContents}
上記の詳細：${store.state.formData.agent.inputAgentConvService}

採用活動開始希望日時（ヒアアポ～の期間・掲載期間を伝えた上で）：${store.state.formData.agent.inputAgentAdoptionStartDate}
求人背景：${store.state.formData.agent.inputAgentRctBackground}
どういう人が取りたいか：${store.state.formData.agent.inputAgentKindPersonHope}
`

    store.commit('setTalknoteMessage', ['agent', sendVal])
  }

}

export default {
  afrPostAgent 
}