/*
* ぽちボタンクリック後の処理
*/ 

class afrPostEasier {
  constructor(that) {
    this.that = that
    this.formName = "Easier"    
    const $ = this.that.$
    const store = this.that.store
    
    if(store.state.industryNamesData.length == 0) {
      this.that.vueObj.viewLoader('業界リスト読み込み中')
    }
    
    let i = 0
    const sit = setInterval(() => {
      
      if(store.state.industryNamesData.length > 0) {
        clearInterval(sit)
        this.that.vueObj.viewLoader()

        // データ出力先有無をチェック
        if($('#industry_names_easier')[0]) {
          const industry_records = store.state.industryNamesData
          
          for(let i = 0; i < industry_records.length; i++) {
            let record = industry_records[i]
            let option = $('<option>')

            option.attr({
              id: 'jobsCategoriesID' + record.id,
              value: record.name,
              'data-id': record.id
            }).text(record.name)
            
            $('#industry_names_easier').append(option).show()
          }

        }

      } else {
        i++
        if(i >= 200) {
          clearInterval(sit)
          alert('業界リストを取得できませんでした\nリロードします')
          window.location.reload()
        }
      }
      
    }, 50)

    this.that.$('.search_form_wrap').show()
    this.actionInit()
  }

  returnFormName() {
    return this.formName
  }

  getInputData() {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const func = this.that.func

    const selectedContractReason = Array.from(
      document.querySelectorAll("input[name='contractReason_easier']:checked")
    ).map(input => input.value)

    const selectedPersnality = Array.from(
      document.querySelectorAll("input[name='persnality_easier']:checked")
    ).map(input => input.value)

    const selectedPromiseInsertion = Array.from(
      document.querySelectorAll("input[name='promise_insertion_easier']:checked")
    ).map(input => input.value)

    const selectedPromiseService = Array.from(
      document.querySelectorAll("input[name='promise_service_easier']:checked")
    ).map(input => input.value)

    //制作前共有
    store.commit('setValue', ['easier', 'input_easier_should_movie', $('input[name="easier_should_movie"]:checked').val(), 'easierShouldMovie'])
    store.commit('setValue', ['easier', 'input_easier_plan', $('input[name="easier_plan"]:checked').val(), 'easierPlan'])
    store.commit('setValue', ['easier', 'input_Brand_page_production_easier', $('input[name="Brand_page_production_easier"]:checked').val(), 'BrandPageProductionEasier'])
    store.commit('setValue', ['easier', 'input_PR_pages_produced_easier', func.textEscape($('#PR_pages_produced_easier').val()), 'PRPagesProducedEasier'])
    store.commit('setValue', ['easier', 'input_MEO_measures_easier', $('input[name="MEO_measures_easier"]:checked').val(), 'MEOMeasuresEasier'])
    store.commit('setValue', ['easier', 'input_word_number_easier', func.textEscape($('#word_number_easier').val()), 'wordNumberEasier'])
    store.commit('setValue', ['easier', 'input_advertising_substitution_easier', $('input[name="advertising_substitution_easier"]:checked').val(), 'advertisingSubstitutionEasier'])
    store.commit('setValue', ['easier', 'input_paid_advertising_expenses_easier', func.textEscape($('#paid_advertising_expenses_easier').val()), 'paidAdvertisingExpensesEasier'])
    store.commit('setValue', ['easier', 'input_industry_names_easier', $('#industry_names_easier').val(), 'industry_names_easier'])
    store.commit('setValue', ['easier', 'input_shoot_yesNo_easier', $('input[name="shoot_yesNo_easier"]:checked').val(), 'shootYesNoEasier'])
    store.commit('setValue', ['easier', 'input_none_shoot_reason', func.textEscape($('#none_shoot_reason').val()), ''])
    store.commit('setValue', ['easier', 'inputShouldShootEasier', $('[name="shoot_yesNo_easier"]:checked').val(), ''])
    store.commit('setValue', ['easier', 'input_contractReason_easier', selectedContractReason, ''])
    store.commit('setValue', ['easier', 'input_contract_reason_easier_other', func.textEscape($('#contract_reason_easier_other').val()), ''])
    store.commit('setValue', ['easier', 'input_persnality_easier', selectedPersnality, ''])
    store.commit('setValue', ['easier', 'input_conv_easier', func.textEscape($('#conv_easier').val()), ''])
    store.commit('setValue', ['easier', 'input_promise_insertion_easier', selectedPromiseInsertion, ''])
    store.commit('setValue', ['easier', 'input_promise_insertion_easier_detail', func.textEscape($('#promise_insertion_easier_detail').val()), ''])
    store.commit('setValue', ['easier', 'input_promise_service_easier', selectedPromiseService, ''])
    store.commit('setValue', ['easier', 'input_promise_service_easier_detail', func.textEscape($('#promise_service_easier_detail').val()), ''])
    store.commit('setValue', ['easier', 'input_date_publication_easier', func.textEscape($('#date_publication_easier').val()), ''])
    store.commit('setValue', ['easier', 'input_background_customers_easier', func.textEscape($('#background_customers_easier').val()), ''])
    store.commit('setValue', ['easier', 'input_sharing_operators_easier', func.textEscape($('#sharing_operators_easier').val()), ''])
    store.commit('setValue', ['easier', 'input_test_service_easier_yesNo', $('[name="test_service_easier_yesNo"]:checked').val(), ''])
  }

  setVal (balanceInfoMsg) {
    const store = this.that.store

    let sendVal = 
`
◎Easier
動画撮影：${store.state.formData.easier.input_easier_should_movie}
プラン：${store.state.formData.easier.input_easier_plan}
ブランドページ：${store.state.formData.easier.input_Brand_page_production_easier}
PRページ制作代行数：${store.state.formData.easier.input_PR_pages_produced_easier}

MEO対策：${store.state.formData.easier.input_MEO_measures_easier}
MEO対策ワード数：${store.state.formData.easier.input_word_number_easier}

有料広告運用代行サービス：${store.state.formData.easier.input_advertising_substitution_easier}
有料広告費用：${store.state.formData.easier.input_paid_advertising_expenses_easier}

募集業界：${store.state.formData.easier.input_industry_names_easier}

撮影有無：${store.state.formData.easier.input_shoot_yesNo_easier}
撮影無し理由：${store.state.formData.easier.input_none_shoot_reason}

契約動機：${store.state.formData.easier.input_contractReason_easier}
契約動機詳細：${store.state.formData.easier.input_contract_reason_easier_other}

顧客人柄：${store.state.formData.easier.input_persnality_easier}
お客様との会話内容：${store.state.formData.easier.input_conv_easier}

Easierに載せる内容について話した内容：${store.state.formData.easier.input_promise_insertion_easier}
上記の詳細：${store.state.formData.easier.input_promise_insertion_easier_detail}

サービス内容について提案した内容：${store.state.formData.easier.input_promise_service_easier}
上記の詳細：${store.state.formData.easier.input_promise_service_easier_detail}

掲載希望日時：${store.state.formData.easier.input_date_publication_easier}
他媒体効果：${store.state.formData.easier.input_background_customers_easier}
その他顧客希望：${store.state.formData.easier.input_sharing_operators_easier}

テストマーケのMEO無料運用サービス：${store.state.formData.easier.input_test_service_easier_yesNo}
`

    store.commit('setTalknoteMessage', ['easier', sendVal])

    const fieldCodeContactEasier = {
      "動画撮影_Easier": {"value": store.state.formData.easier.input_easier_should_movie},
      "Easierプラン": {"value": [store.state.formData.easier.input_easier_plan]},
      "ブランドページ_Easier": {"value": store.state.formData.easier.input_Brand_page_production_easier},
      "PRページ制作代行数_Easier": {"value": parseInt(store.state.formData.easier.input_PR_pages_produced_easier)},
      "MEO対策_Easier": {"value": store.state.formData.easier.input_MEO_measures_easier},
      "MEO対策ワード数_Easier": {"value": parseInt(store.state.formData.easier.input_word_number_easier)},
      "テストマーケ_MEO無料運用サービス_Easier": {"value": store.state.formData.easier.input_test_service_easier_yesNo},
      "有料広告運用代行サービス_Easier": {"value": store.state.formData.easier.input_advertising_substitution_easier},
      "有料広告費_Easier": {"value": parseInt(store.state.formData.easier.input_paid_advertising_expenses_easier)},
      "業界_Easier": {"value": store.state.formData.easier.input_industry_names_easier},
      "撮影有無_Easier": {"value": store.state.formData.easier.input_shoot_yesNo_easier},
      "撮影無し理由_Easier": {"value": store.state.formData.easier.input_none_shoot_reason},
      "契約動機_Easier": {"value": store.state.formData.easier.input_contractReason_easier.join(",")},
      "契約動機詳細_Easier": {"value": store.state.formData.easier.input_contract_reason_easier_other},
      "顧客人柄_Easier": {"value": store.state.formData.easier.input_persnality_easier.join(",")},
      "お客様との会話内容_Easier": {"value": store.state.formData.easier.input_conv_easier},
      "Easier制作物": {"value": store.state.formData.easier.input_promise_insertion_easier.join(",")},
      "Easier制作物_詳細": {"value": store.state.formData.easier.input_promise_insertion_easier_detail},
      "Easierサービス内容": {"value": store.state.formData.easier.input_promise_service_easier.join(",")},
      "Easierサービス内容_詳細": {"value": store.state.formData.easier.input_promise_service_easier_detail},
      "掲載希望日時_Easier": {"value": store.state.formData.easier.input_date_publication_easier},
      "他媒体効果_Easier": {"value": store.state.formData.easier.input_background_customers_easier},
      "その他顧客希望_Easier": {"value": store.state.formData.easier.input_sharing_operators_easier},
    }

    store.commit('setValue', ['easier', 'easierFieldCode', fieldCodeContactEasier, ''])
  }

  actionInit() {
    const $ = this.that.$

    $("#form_ap").on("click", '[name="advertising_substitution_easier"]', function() {
      let thisVal = $(this).prop("id")

      switch (thisVal) {
        case "advertising_substitution_yes_easier":
          $("#paid_advertising_expenses_easier").val('').addClass("required")
          $('#paid_advertising_expenses_easier').prop('disabled', false)
          $("#paid_advertising_expenses_column_easier").slideDown()
          break

        case "advertising_substitution_no_easier":
          $("#paid_advertising_expenses_easier").val('').removeClass("required")
          $('#paid_advertising_expenses_easier').prop('disabled', true)
          $("#paid_advertising_expenses_column_easier").slideUp()
          break
      }
    })

    $("#form_ap").on("click", '[name="MEO_measures_easier"]', function() {
      let thisVal = $(this).prop("id")

      switch (thisVal) {
        case "MEO_measures_yes_easier":
          $("#word_number_easier_column").slideDown()
          $("#word_number_easier").addClass("required")
          break

        case "MEO_measures_no_easier":
          $("#word_number_easier_column").slideUp()
          $("#word_number_easier").removeClass("required")
          $("#word_number_easier").val("")
          break
      }
    })

    $("#form_ap").on("click", '[name="shoot_yesNo_easier"]', function() {
      let thisVal = $(this).prop("id")

      switch (thisVal) {
        case "shoot_yes_easier":
          $("#none_shoot_reason_column").slideUp()
          $("#none_shoot_reason").removeClass("required")
          $("#none_shoot_reason").val("")
          break

        case "shoot_no_easier":
          $("#none_shoot_reason_column").slideDown()
          $("#none_shoot_reason").addClass("required")
          break
      }
    })

  }
}

export default {
  afrPostEasier
}