/**
  ヘッダコンポーネント
*/
<template>
  <header class="g-header">
    <div class="g-header__inner">
      <div class="g-header__group">
        <h1 class="g-header__logo">
          <a @click="logoClick" class="g-header__logo-text">
            <img v-bind:src="logo" v-bind:alt="title" class="g-header__logo-img" height="40" width="90">
          </a>
          <span class="g-header__version-text" v-html="'v' + version"></span>
        </h1>
      </div>
      <div class="g-header__group">
        <button class="g-header__menu" aria-label="メニューを開く" :class="{ '-active': isShowMenu }" @click="isShowMenu = !isShowMenu"></button>
      </div>
    </div>
  </header>
  <nav class="g-menu" :class="{ '-active': isShowMenu }">
    <div class="g-menu__disabled" @click="isShowMenu = !isShowMenu"></div>
    <div class="g-menu__container">
      <Nav :groupList="groupList" :showSignOut="true" @changeForm="changeForm" :isTop="isTop"></Nav>
    </div>
  </nav>
</template>

<script>
  import { defineComponent } from 'vue';
  import formDataStore from "@/assets/js/formDataStore";

  // コンポーネントを読み込む
  import Nav from './nav.vue';

  export default defineComponent({
    components: {
      Nav
    },

    data() {
      return {
        isTop: false,
        title: formDataStore.state.siteName,
        logo: formDataStore.state.siteName.match(/develop|stg/)? "/sgpost-logo-dev.svg": "/sgpost-logo.svg",
        isShowMenu: false,
        formType: "",
        groupList: [],
        version: ''
      }
    },

    props: {
      isMain: Boolean,
      isLogin: Boolean,
      user: Object,
    },

    mounted: async function() {
      this.version = formDataStore.state.version;

      const sit = setInterval(() => {
        let n = 0;

        if(formDataStore.state.loadFlag.list) {
          clearInterval(sit);
          this.groupList = formDataStore.state.groupList;
        } else {
          n++;
          if(n > 100) {
            clearInterval(sit);
            console.log('リストデータ取得タイムアウト');
          }
        }
      }, 500);
    },

    methods: {
      changeForm() {
        this.isShowMenu = false;
      },

      logoClick() {
        location.href = '/';
      }
    }
  });
</script>

<style lang="scss" scoped>
.g-header {
  font-size: 0;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;

  &__inner {
    background: $color-primary;
    display: flex;
    justify-content: space-between;
  }

  &__group {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    transition: opacity $transition;
    position: relative;

    @include is-hover {
      opacity: .8;
    }

    &-text {
      @include fz(20);
      line-height: 1;
      color: $color-white;
      font-weight: $bold;
      display: block;
      padding: 6px 20px;
      cursor: pointer;
    }
  }

  &__version-text {
    position: absolute;
    color: #ffffff;
    @include fz(11);
    font-weight: 400;
    left: calc(100% - 10px);
    bottom: 4px;
    margin-top: -.6em;
  }

  &__menu {
    width: 50px;
    height: 100%;
    background: $color-blue-2;
    position: relative;
    transition: background $transition;

    @include is-hover {
      background: $color-blue-3;
    }

    &.-active {
      &::before,
      &::after {
        margin-left: 0;
        position: absolute;
        top: 50%;
        left: calc(50% - 12.5px);
      }

      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        width: 25px;
        transform: rotate(45deg);
      }
    }

    &::before,
    &::after {
      content: "";
      display: block;
      background: $color-white;
      height: 1px;
      margin-left: 12.5px;
      transition: width $transition, transform $transition;
    }

    &::before {
      width: 25px;
      margin-bottom: 5px;
    }

    &::after {
      width: 20px;
    }
  }
}

.g-menu {
  color: $color-white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 18;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity $transition;

  &.-active {
    opacity: 1;
    pointer-events: auto;
  }

  &__disabled {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: rgba($color-black, .5);
  }

  &__container {
    max-width: 320px;
    width: calc(100% - 50px);
    background: $color-blue-1;
    position: absolute;
    top: $height-header;
    right: 0;
    bottom: 0;
    transition: transform $transition;
    transform: translateX(20px);

    .-active & {
      transform: translateX(0);
      overflow-y: auto;
    }
  }
}
</style>