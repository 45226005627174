/*
* 各商材別申込書

content
├ application_main.js (メイン処理)
├ _common.js (共通関数)
├ _itzai.js
├ _ec.js
├ _wel.js(リロ・wel)
└ html
  ├ application_html.js(共通HTML)
  ├ _itzai.js
  ├ _ec.js
  └ _wel.js(リロ・wel)
*/ 
import applicationCommonClass from "./common.class"
import applicationItzaiClass from "./itzai.class";
import applicationItzaiLiteClass from "./itzaiLite.class";
import applicationECClass from "./ec.class";
import applicationWELClass from "./wel.class";
import applicationReloClass from "./relo.class";
import applicationSEOClass from "./seo.class";
import applicationMEOClass from "./meo.class";
import applicationCMSClass from "./cms.class";
import applicationCMSLodgingClass from "./cmsLodging.class";
import applicationEasierClass from "./easier.class";
import applicationSubsidyClass from "./subsidy.class";
import applicationComOptionClass from "./comoption.class";
import applicationVideoProductionClass from "./videoProduction.class";
import applicationAgentClass from "./agent.class";
import applicationOptionClass from "./option.class";
import applicationContactInfoClass from "./contactInfo.class";

class main {
  constructor(vueObj, $, func, moment, store) {

    try {
      // Vueインスタンスを取得
      this.vueObj = vueObj

      this.store = vueObj.store

      this.isProd = this.vueObj.isProd

      // jQueryインスタンスを取得
      this.$ = $

      // フォーム関連関数を取得
      this.func = func

      // momentインスタンスを取得
      this.moment = moment

      // propsを取得
      //this.props = this.vueObj.getProps()
      this.thisFormParams = vueObj.thisFormParams

      // 投稿先slackチャンネル情報
      this.slackChannel = vueObj.slackChannel
      this.demo = this.isProd ? "" : "Demo"
      this.key = this.store.state.apis[`loginKey${this.demo}`]

      // 案件ID検索とか理由カテゴリ取得するAPI
      this.getDataApiURL = this.store.state.apis.getDataApiURL

      // talknoteのグループURLからグループIDを取得
      //this.groupID = this.groupURL.split('feed/')[1].split('/')[0]

      // フォームタイトル
      this.groupTitle = this.thisFormParams[0].replace(/\n/gi,"").replace(/\t/gi,"")

      // talknoteの投稿先アドレス
      // this.talknoteGroupAddress = this.thisFormParams[2].replace(/\n/gi,"").replace(/\t/gi,"")

      // slackチャンネルURL
      this.groupURL = this.slackChannel.workSpaceURL + this.slackChannel.id 

      // axios
      this.axios = vueObj.axios

      this.formType = 0 // [1:イツザイ, 2:EC, 3:リロ・WEL]
      this.formTypeName = '' 
      this.appClassObj = {}

      // [本日]
      this.today = moment()
      this.today = this.today.format('YYYY-MM-DD')

      // store
      this.store = store

      this.appClassObj.common = new applicationCommonClass.commonFuncs(this)

      // 初期化
      this.init()

    } catch(e) {
      console.error(e.stack, e.message)

      let text = []

      if(!this.vueObj) {
        text.push('Vueインスタンスが取得できませんでした。')
      }

      if(!this.$) {
        text.push('jQueryが取得できませんでした。')
      }

      if(text.length == 0) {
        alert('予期せぬエラーが発生しました。')
      } else {
        alert(text.join('\n'))
      }

      return false
    }

  }

  init() {
    const $ = this.$;
    
    $('.search_form_wrap').show()
    $('.autoG,.gotAP, .item, .form_wrap').css('display', 'none')
    $('#sendDate, #callDate').addClass('required').removeAttr('style')
    $('select').prop('selectedIndex', 0)
    this.actionInit();

    this.getIndustryNamesData();
    $(".test_marketing_authority").hide()

  }

  actionInit() {
    const that = this
    const $ = this.$
    const moment = this.moment
    moment.locale("ja")


    $('.select_checkbox').on('change', async function() {
      const formId = $(this).val().replace('Select', '')

      let toggle = $(this).prop('checked')
      //that.vueObj.hideSelectAllForm()
      
      //if(!formId) $('.search_form_wrap').hide()
      await that.vueObj.toggleSelectForm(formId, toggle)
      

      if(!toggle) that.store.commit('delValue', formId)

      let elm = {}
      $('#formContactInfo .form_wrap').show()
      that.appClassObj.contactInfo = new applicationContactInfoClass.afrContactInfo(that)

      switch (formId) {

        case 'itzai':
          elm = $('#formItzai .form_wrap')

          if(toggle) {
            elm.show()
            that.appClassObj.itzai = new applicationItzaiClass.afrPostItzai(that)
          } else {
            elm.hide()
            that.appClassObj.itzai = null
          }
          
          break

        case 'itzaiLite':
          elm = $('#formItzaiLite .form_wrap')
          
          if(toggle) {
            elm.show()
            that.appClassObj.itzaiLite = new applicationItzaiLiteClass.afrPostItzaiLite(that)
          } else {
            elm.hide()
            that.appClassObj.itzaiLite = null
          }
          
          break

        case 'ec':
          elm = $('#formEC .form_wrap')
          
          if(toggle) {
            elm.show()
            that.appClassObj.ec = new applicationECClass.afrPostEC(that)
          } else {
            elm.hide()
            that.appClassObj.ec = null
          }
        
          break
        
        case 'wel':
          elm = $('#formWel .form_wrap')
          
          if(toggle) {
            elm.show()
            that.appClassObj.wel = new applicationWELClass.afrPostWel(that)
          } else {
            elm.hide()
            that.appClassObj.wel = null
          }
          
          break

        case 'relo':
          elm = $('#formRelo .form_wrap')
          
          if(toggle) {
            elm.show()
            that.appClassObj.relo = new applicationReloClass.afrPostRelo(that)
          } else {
            elm.hide()
            that.appClassObj.relo = null
          }
          
          break
        
        case 'seo':
          elm = $('#formSEO .form_wrap')
          
          if(toggle) {
            elm.show()
            that.appClassObj.seos = new applicationSEOClass.afrPostSEO(that)
          } else {
            elm.hide()
            that.appClassObj.seos = null
          }
          
          break
        
        case 'meo':
          elm = $('#formMEO .form_wrap')
          
          if(toggle) {
            elm.show()
            that.appClassObj.meos = new applicationMEOClass.afrPostMEO(that)
          } else {
            elm.hide()
            that.appClassObj.meos = null
          }
          
          break

        case 'cms':
          elm = $('#formCMS .form_wrap')
          
          if(toggle) {
            elm.show()
            that.appClassObj.cms = new applicationCMSClass.afrPostCMS(that)
          } else {
            elm.hide()
            that.appClassObj.cms = null
          }
          
          break

        case 'cms_lodging':
          elm = $('#formCMSLodging .form_wrap')
          
          if(toggle) {
            elm.show()
            that.appClassObj.cmsLodging = new applicationCMSLodgingClass.afrPostCMSLodging(that)
          } else {
            elm.hide()
            that.appClassObj.cmsLodging = null
          }
          
          break

        case 'easier':
          elm = $('#formEasier .form_wrap')
          
          if(toggle) {
            elm.show()
            that.appClassObj.easier = new applicationEasierClass.afrPostEasier(that)
          } else {
            elm.hide()
            that.appClassObj.easier = null
          }
          
          break

        case 'subsidy':
          elm = $('#formSubsidy .form_wrap')
          
          if(toggle) {
            elm.show()
            that.appClassObj.subsidy = new applicationSubsidyClass.afrPostSubsidy(that)
          } else {
            elm.hide()
            that.appClassObj.subsidy = null
          }
          
          break

        case 'com_option':
          elm = $('#formComOption .form_wrap')
          
          if(toggle) {
            elm.show()
            that.appClassObj.com_option = new applicationComOptionClass.afrPostComOption(that)
          } else {
            elm.hide()
            that.appClassObj.com_option = null
          }
          
          break

        case 'video_production':
          elm = $('#formVideoProduction .form_wrap')
          
          if(toggle) {
            elm.show()
            that.appClassObj.video_production = new applicationVideoProductionClass.afrPostVideoProduction(that)
          } else {
            elm.hide()
            that.appClassObj.video_production = null
          }

          break

        case 'agent':
          elm = $('#formAgent .form_wrap')

          if(toggle) {
            elm.show()
            that.appClassObj.agent = new applicationAgentClass.afrPostAgent(that)
          } else {
            elm.hide()
            that.appClassObj.agent = null
          }

          break

        case 'option':
          elm = $('#formOption .form_wrap')
          
          if(toggle) {
            elm.show()
            that.appClassObj.option = new applicationOptionClass.afrPostOption(that)
          } else {
            elm.hide()
            that.appClassObj.option = null
          }
          
          break

      }

      const paymentStatus = $('#paymentStatus option:selected').data('status')

      switch(paymentStatus) {
        case 'done':
          $('.progress_display').show()
          $('.progress_display input[type="radio"]').addClass('required')
          break

        case 'doing':
          $('.progress_display').show()
          $('.progress_display input[type="radio"]').addClass('required')
          break

        case 'notYet':
          $('.progress_display').hide()
          $('.progress_display input[type="radio"]').removeClass('required').prop('checked', false)
          break
      }

      that.func.actionInit($);

      //$('#saledPlan').val(that.formTypeName)
      $('#loading_form').css('display', 'none')

      //$('select').css('display', 'block')

      //that.func.clearSelect($('#form_ap #paymentStatus'))
      //formDataStore.commit('setAppClassObj', 1);
      //console.log(formDataStore.state.appClassObj.test());
    })
    
    
    $('#btn').off('click.send').on('click.send', async function () {
      if($('#btn').hasClass('pulse')) {
        let balanceInfo_len = $('.balanceInfo').length
        let $balanceInfo_balance = $('.balanceInfo_balance')
        let $balanceInfo_date = $('.balanceInfo_date')
        let $balanceInfo_paymentMethod = $('.balanceInfo_paymentMethod')
        let paymentStatus = $('#paymentStatus option:selected').val()

        let balanceNum = 0
        let balanceInfoMsg = ''
        let Info_balance = 0
        let Info_date = ''
        let Info_paymentMethod = 0
        let isInputFlag = 1
        let inputBalances_ary = []
        let balanceInfoObj = {
          "残額": [],
          "残額期日": [],
          "支払い方法": []
        }
        
        for(let i = 0; i < balanceInfo_len; i++) {
          let obj = {}
          $balanceInfo_balance[i].value == "" ? isInputFlag *= 0 : Info_balance = $balanceInfo_balance[i].value

          $balanceInfo_date[i].value == "" ? isInputFlag *= 0 : Info_date = $balanceInfo_date[i].value

          $balanceInfo_paymentMethod[i].value == "" ? Info_paymentMethod *= 0 : Info_paymentMethod = $balanceInfo_paymentMethod[i].value
      
          if( isInputFlag == 1 ) { //残額・残額期日・支払い方法の3つの入力された時
            let totalInfo_balance = Number(that.store.state.totalInfo_balance) + that.func._removeComma(Info_balance)
            //that.store.commit('setValue',['totalInfo_balance', totalInfo_balance])

            Info_date = moment(Info_date.split('/').join('-')).format('YYYY/MM/DD（ddd）')

            obj.balance = Info_balance + '円'
            obj.paymentMethod = Info_paymentMethod
            obj.balanceDeadline = Info_date

            balanceNum += 1
            balanceInfoMsg +=
            '(' + balanceNum + ')' + '\n' +
            '残額：' + Info_balance + '円' +'\n' +
            '残額期日：' + Info_date + '\n' +
            '支払い方法：' + Info_paymentMethod + '\n'

            balanceInfoObj["残額"].push(Info_balance)
            balanceInfoObj["残額期日"].push(Info_date)
            balanceInfoObj["支払い方法"].push(Info_paymentMethod)
            that.store.commit('setValue', ['common', 'totalInfo_balance', totalInfo_balance, 'total_balance'])

          } else if(paymentStatus == '決済完了') {
            that.store.commit('setValue', ['common', 'totalInfo_balance', '0', 'total_balance'])
          }
          
          inputBalances_ary.push(obj)
        }
  
        that.store.commit('setValue', ['common', 'balance_Info', balanceInfoObj, 'total_balance'])
        that.store.commit('setValue',['common', 'balances', inputBalances_ary, 'balances'])

        balanceInfoMsg = balanceInfoMsg.slice(0, -1)

        // 「受注内容」構築
        let formTypeName = []
        let sorter = ['itzai','itzaiLite', 'ec', 'wel', 'relo', 'seos', 'meos', 'cms', 'cmsLodging', 'easier', 'subsidy', 'com_option', 'video_production', 'agent', 'option']
        for(let key in sorter) {
          if (that.appClassObj[sorter[key]]) { // クラスが宣言されている時
            if(key != "common") formTypeName.push(that.appClassObj[sorter[key]].returnFormName())
          }
        }

        that.store.commit('setValue',['common', 'formTypeName', formTypeName.join('+'), 'formTypeName'])

        // 「シート用」 「talknote用」のデータ構築
        for(let key in that.appClassObj) {
          if (that.appClassObj[key]) {
            that.appClassObj[key].getInputData()
            that.appClassObj[key].setVal(balanceInfoMsg)
          }
        }

        const msg = that.appClassObj.common.getDataToTalknote()
        const sheetdata = that.appClassObj.common.getDataToSheet()

        await that.appClassObj.common.sendFormData(msg, sheetdata)
        await that.appClassObj.common.clearAllOption()

        //$('.form_list').hide()
      }
    })

    console.log('main init');
  }

  //申込書イツザイ 業界名データ取得
  async getIndustryNamesData() {
    // メアドでtalknoteのユーザーID取得
    const getCategoriesApiURL = this.store.state.apis[`dbApiURL${this.demo}`] + 'db/select'
    
    this.abortController = new AbortController()

    const params = {
      authC: {
        password: "W!z|a~.x.T~Tzca8CBCm",
        mail: 'system@sungrove.co.jp'
      },
      main_data: {
        tables:["categories"],
        query: `SELECT id, name FROM categories c WHERE c.counterparts_id = 25 AND c.deleted_at is NULL` // イツザイの業種カテゴリを取得
      },
      support_data: {
        is_prod: 1 // 開発環境でも本番を参照
      }
    }

    fetch(getCategoriesApiURL, {
      signal: this.abortController.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(params)
    })
    .then(response => response.json())
    .then(res => {
      console.log(res);

      this.store.commit('setIndustryNamesData', res);
    })
    .catch(err => {
      alert('業界リストを取得できませんでした。');
      console.log(err);
    });
  }
}

export default {
  main
}
