/**
    申し込み用フォーム[イツザイエージェント]コンポーネント
*/
<template>
<div id="formAgent" class="form_group">
  <Section class="form_wrap form_list">
    <div class="rap1_boder" id="wrap3">
      <Section><h1 class="wrap_title pd_7">イツザイエージェント</h1></Section>
      <div class="formSharedComm">
        <Column class="pd3_parts formAgent">
          <label>受注プラン</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="agent_plan" value="6ヶ月">
                <span class="">6ヶ月</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="agent_plan" value="12ヶ月">
                <span class="">12ヶ月</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="agent_plan" value="24ヶ月">
                <span class="">24ヶ月</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts js-editable">
          <label>初期費用（税込）</label>
          <Flex class="display_yoko">
            <input type="text" class="form_txt required" inputmode="numeric" id="agent_initialCost" placeholder="半角数字で入力">
            <span class="chara_bottom">円</span>
          </Flex>
        </Column>
        <Column class="pd3_parts js-editable">
          <label>基本サービス料金（税込）</label>
          <Flex class="display_yoko">
            <input type="text" class="form_txt required" inputmode="numeric" id="agent_basicServiceFee" placeholder="半角数字で入力">
            <span class="chara_bottom">円</span>
          </Flex>
        </Column>
        <Column class="pd3_parts js-editable">
          <label>同時選考人数追加オプション</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="agent_selection_people_option" value="あり">
                <span class="">あり</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="agent_selection_people_option" value="なし">
                <span class="">なし</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts agent_selection_people_option_display js-editable">
          <label>人数</label>
          <Flex class="display_yoko">
            <input type="text" class="agent_selection_people_option_required form_txt" inputmode="numeric" id="agent_selection_people" placeholder="半角数字で入力">
            <span class="chara_bottom">人</span>
          </Flex>
        </Column>
        <Column class="pd3_parts agent_selection_people_option_display js-editable">
          <label>追加金額（税込）</label>
          <Flex class="display_yoko">
            <input type="text" class="agent_selection_people_option_required form_txt" inputmode="numeric" id="agent_additional_amounts" placeholder="半角数字で入力">
            <span class="chara_bottom">円</span>
          </Flex>
        </Column>
        <Column class="pd3_parts js-editable">
          <label>上長承認済み 保証プラン</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="agent_warranty_plans" value="あり">
                <span class="">あり</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="agent_warranty_plans" value="なし">
                <span class="">なし</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts agent_warranty_plans_display js-editable">
          <label>保証用費用（税込）</label>
          <Flex class="display_yoko">
            <input type="text" class="agent_warranty_plans_required form_txt" inputmode="numeric" id="agent_warranty_expenses" placeholder="半角数字で入力">
            <span class="chara_bottom">円</span>
          </Flex>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>契約動機</label>
          <label class="c-choice documents"><input type="checkbox" class="required" name="agent_motive_signing_contract" value="慢性的人員不足の解消"><span>慢性的人員不足の解消</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_motive_signing_contract" value="欠員"><span>欠員</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_motive_signing_contract" value="増員"><span>増員</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_motive_signing_contract" value="新店を増やすにあたってのスタートアップメンバーの募集"><span>新店を増やすにあたってのスタートアップメンバーの募集</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_motive_signing_contract" value="管理者・経験者・資格保有者などの採用難易度が高い採用をしたい"><span>管理者・経験者・資格保有者などの採用難易度が高い採用をしたい</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_motive_signing_contract" value="現場の若返りを図りたい"><span>現場の若返りを図りたい</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_motive_signing_contract" value="今よりも採用コストを下げていきたい"><span>今よりも採用コストを下げていきたい</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_motive_signing_contract" value="応募者の質を改善したい"><span>応募者の質を改善したい</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_motive_signing_contract" value="経営に専念するための人員確保"><span>経営に専念するための人員確保</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_motive_signing_contract" value="応募者情報を管理できる管理システムが欲しかった"><span>応募者情報を管理できる管理システムが欲しかった</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_motive_signing_contract" value="イツザイ導入企業から実績を聞いての紹介"><span>イツザイ導入企業から実績を聞いての紹介</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_motive_signing_contract" value="その他"><span>その他</span></label>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>契約動機（上記選択では共有に足りない内容がある場合やその他選択をした場合に共有ください）</label>
          <div><textarea id="agent_contract_reason" class="form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>顧客人柄</label>
          <p class="-attention">※カバーしなければいけない要素を確認させていただきたいです。担当のアサインの際に活用します。</p>
          <label class="c-choice documents"><input type="checkbox" class="required" name="agent_persnality" value="採用リテラシー高い"><span>採用リテラシー高い</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_persnality" value="採用リテラシー低い"><span>採用リテラシー低い</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_persnality" value="機械操作が苦手"><span>機械操作が苦手</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_persnality" value="非協力的"><span>非協力的</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_persnality" value="こだわり強い"><span>こだわり強い</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_persnality" value="こだわりなし＆任せたい"><span>こだわりなし＆任せたい</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_persnality" value="こだわりなし＆教えてほしい"><span>こだわりなし＆教えてほしい</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_persnality" value="プライド高い・相手を馬鹿にする"><span>プライド高い・相手を馬鹿にする</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_persnality" value="極端な考え方をする・思い込み激しい"><span>極端な考え方をする・思い込み激しい</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_persnality" value="反応薄い"><span>反応薄い</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_persnality" value="高齢・前の話を忘れてしまう"><span>高齢・前の話を忘れてしまう</span></label>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>お客様との会話内容（世間話を含めて）</label>
          <p class="-attention">※こういう話題してもらった方がアイスブレイクになるなどがあれば教えてほしいです！<br>
            （例）<br>
            車好きの代表です。外車の話をしたらすごく盛り上がりました。<br>
            リテラシーが高いので知見提供し続けたり業界あるある話をして信用を得ると本音を引き出しやすくなりました。など
          </p>
          <div><textarea id="agent_conversation_content" class="form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>イツザイエージェントに掲載する内容について何かお客様とお約束したり、お約束していなくても提案して温度感があがったことはありますか？</label>
          <label class="c-choice documents"><input type="checkbox" name="agent_promise_insertion" value="訴求方針について"><span>訴求方針について</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_promise_insertion" value="運用方針について"><span>運用方針について</span></label>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>上記の詳細</label>
          <div><textarea id="conv_agent" class="form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>イツザイエージェントのサービス内容について何かお客様とお約束したり、お約束していなくても提案して温度感があがったことはありますか？</label>
          <label class="c-choice documents"><input type="checkbox" name="agent_promise_contents" value="RAのサポート体制について"><span>RAのサポート体制について</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_promise_contents" value="開始時期について"><span>開始時期について</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_promise_contents" value="キャンペーンについて"><span>キャンペーンについて</span></label>
          <label class="c-choice documents"><input type="checkbox" name="agent_promise_contents" value="追加費用について"><span>追加費用について</span></label>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>上記の詳細</label>
          <div><textarea id="agent_conv_service" class="form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>採用活動開始希望日時（ヒアアポ～の期間・掲載期間を伝えた上で）</label>
          <div class="width60"><input type="date" id="agent_adoption_start_date" class="required form_txt"></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>求人背景（他媒体効果など）</label>
          <div class="width60"><input type="text" id="agent_rct_background" class="form_txt"></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>どういう人が取りたいか</label>
          <div><textarea id="agent_kind_person_Hope" class="form_txt"></textarea></div>
        </Column>
      </div>
    </div>
  </Section>
</div>
</template>


<script>
import { defineComponent } from 'vue'

// コンポーネントを読み込む
import Variables from '../../../molecules/variables.vue';
import Choices from '../../../molecules/choices.vue';
import Box from '../../../molecules/box.vue';
import Section from '../../../molecules/section.vue';
import Column from '../../../molecules/column.vue';
import Group from '../../../molecules/group.vue';
import Flex from '../../../molecules/flex.vue';
import Block from '../../../molecules/block.vue';

import Description from '../../../atoms/description.vue';
import Label from '../../../atoms/label.vue';
import Input from '../../../atoms/input.vue';
import Select from '../../../atoms/select.vue';
import Textarea from '../../../atoms/textarea.vue';
import Button from '../../../atoms/button.vue';
import IconAdd from '../../../atoms/icon-add.vue';
import IconRemove from '../../../atoms/icon-remove.vue';

export default defineComponent({
  name: 'formApplicationAgent',

  components: {
    Variables,
    Choices,
    Box,
    Section,
    Column,
    Group,
    Flex,
    Block,

    Description,
    Label,
    Input,
    Select,
    Textarea,
    Button,
    IconAdd,
    IconRemove,
  },

  methods: {
  },

  mounted() {
  },

  setup(_, context) {
    return {}
  }

})
</script>