<template>
  <div v-if="isVisible" class="confirm-modal-overlay">
    <div class="confirm-modal-content">
      <div class="confirm-modal-message">{{ message }}</div>
      <div class="confirm-modal-buttons">
        <button class="confirm-modal-button ok" @click="$emit('ok')">OK</button>
        <button class="confirm-modal-button cancel" @click="$emit('cancel')">キャンセル</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.confirm-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirm-modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  min-width: 600px;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.confirm-modal-message {
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 20px;
  white-space: pre-wrap;
}

.confirm-modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.confirm-modal-button {
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.confirm-modal-button.ok {
  background-color: #2071af;
  color: white;
}

.confirm-modal-button.ok:hover {
  background-color: #0D4B7C;
}

.confirm-modal-button.cancel {
  background-color: #f44336;
  color: white;
}

.confirm-modal-button.cancel:hover {
  background-color: #da190b;
}
</style> 