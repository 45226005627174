/*
* フォームに投稿済みデータを編集する機能を組み込むクラス
**/
import formDataStore from "../formDataStore"
import getDataForEdit from './getDataForEdit.class'
import '../../scss/global/_dataEdit.scss'

export default class {

  constructor(obj) { 
    this.$ = obj.$
    this.vueObj = obj.vueObj
    this.subjectsID = ''
    this.formTypeVariation = this.vueObj.formType
    this.changeModeAction = this.changeMode.bind(this)
    this.sendDataAction = this.sendData.bind(this)
    this.deleteDataAction = this.deleteData.bind(this)
    this.checkSubjectsIDAction = this.checkSubjectsID.bind(this)
    //this.confirmSubjectsIDAction = this.confirmSubjectsID.bind(this)

    this.formTypeCheck()
  }

  /*
   フォームタイプチェック

   以下の4タイプが編集対象
    apgain アポ獲得報告
    aporeport 商談報告
    applications 申込書報告
    juchuumaekaitou 受注前回答
  **/
  formTypeCheck() {
    const formTypeReg = new RegExp('apgain|aporeport|applications|juchuumaekaitou')
    this.formType = location.href.match(formTypeReg) || false
    this.formType = location.href.indexOf('運用') > -1? false: this.formType
    if(!this.formType) return false 

    this.formType = this.formType? this.formType[0]: this.formType

    if(this.formTypeVariation == "アポイント運用") {
      this.formType = false
      return
    }

    this.formAp = document.querySelector('#form_ap')

    try { 
      const sit = setInterval(() => {

        if(this.formAp) {

          this.checkIsAdmin()
          
          this.formAp.addEventListener('transitionend', () => {
            this.formAp.style.maxHeight = this.mode == 'edit' || this.isGetResult? '': '0px'
          }, false)
          clearInterval(sit)
        }
      }, 50)
    } catch(e) {
      alert('DOM生成エラー')
      location.reload()
    }
  }

  /*
   管理権限チェック
  **/
  checkIsAdmin() {

    if(formDataStore.state.userData.isAdministrator == '0' || !this.formType) {
      this.vueObj.isAdmin = false
      return
    }

    this.vueObj.isAdmin = true
    // 管理者権限持っていたら
    this.createChangeModeButton('edit')
  }

  /*
   編集モード切替
  **/
  changeMode() {
    const _this = this
    const $ = this.$
    this.isGetResult = false
    this.formClear()

    // 編集モード
    if(this.mode == 'edit') {
      const btnEditIcon = `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffffff">
      <g><rect fill="none" height="24" width="24"/></g><g><g/><g><path d="M17,19.22H5V7h7V5H5C3.9,5,3,5.9,3,7v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-7h-2V19.22z"/>
      <path d="M19,2h-2v3h-3c0.01,0.01,0,2,0,2h3v2.99c0.01,0.01,2,0,2,0V7h3V5h-3V2z"/><rect height="2" width="8" x="7" y="9"/><polygon points="7,12 7,14 15,14 15,12 12,12"/>
      <rect height="2" width="8" x="7" y="15"/></g></g></svg>`
      this.vueObj.modeChangeBtnLabel = btnEditIcon
      this.vueObj.modeChangeBtnText = 'クリックで投稿モード'
      this.vueObj.modeStatus = '今は編集モードです'
      this.vueObj.edit = false
      this.vueObj.post = true
      this.vueObj.editMessage = true
      this.mode = 'post'


      this.bottomMessageArea = document.createElement('div')
      this.bottomMessageArea.classList = 'bottom-message'
      this.bottomMessageArea.innerText = '編集モード'
      document.querySelector('body').append(this.bottomMessageArea)
      this.addSearchBox()

      this.notEditablElm = document.querySelectorAll('.js-notEditable')//.filter(elm => elm.add('-active'))

      this.notEditablElm.forEach(elm => elm.classList.add('is-active') )
      
      this.createSendButton()
 
       // 送信ボタン削除
      //document.getElementById('filedBtn').remove() 
      document.getElementById('filedBtn').style.display = 'none'

      formDataStore.commit('setEditMode', true)


      //[必須項目判定]

      $('#form_ap').off('change.checkRequired input.checkRequired click.checkRequired mousemove.checkRequired').on('change.editcheckRequired input.editcheckRequired click.editcheckRequired mousemove.editcheckRequired', function() {
        let resultRequiredArray = _this.checkRequired()
        let required_NG = 0
        let check_required = 0

        for(let key in resultRequiredArray) {
          resultRequiredArray[key] == 0 ? required_NG = 1 : check_required = 1
        }

        const jsModifyBtn = $('#jsModifyBtn') 
        if(check_required == 1 && required_NG == 0) {
          jsModifyBtn.removeClass('disabled').addClass('pulse').attr('disabled', false)
        } else {
          jsModifyBtn.addClass('disabled').removeClass('pulse').attr('disabled', true)
        }

      })

      return 
    }

    // 投稿モード
    if(this.mode == 'post') {
      formDataStore.commit('setEditMode', false)
      /*this.formAp.classList.remove('dataEditArea')
      this.formOpen()

      this.bottomMessageArea.remove()
      this.postIDSearchBoxArea.remove()
      //this.formAp.classList.remove('dataEditArea')
      this.notEditablElm.forEach(elm => elm.classList.remove('-active') )
      this.changeModeButton('edit', this.editButtonLabel)*/
      // 面倒なのでリロード
      location.reload()
      return 
    }
  }

  /*
   フォームオープン
  **/
  formOpen() {
    this.formAp.style.maxHeight = this.areaHeight + 'px'
  }

  /*
   モード切替ボタン
  **/
  createChangeModeButton(mode) {

    const btnEditIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="#ffffff"><path d="M686-132 444-376q-20 8-40.5 12t-43.5 4q-100 0-170-70t-70-170q0-36 10-68.5t28-61.5l146 146 72-72-146-146q29-18 61.5-28t68.5-10q100 0 170 70t70 170q0 23-4 43.5T584-516l244 242q12 12 12 29t-12 29l-84 84q-12 12-29 12t-29-12Zm29-85 27-27-256-256q18-20 26-46.5t8-53.5q0-60-38.5-104.5T386-758l74 74q12 12 12 28t-12 28L332-500q-12 12-28 12t-28-12l-74-74q9 57 53.5 95.5T360-440q26 0 52-8t47-25l256 256ZM472-488Z"/></svg>`

    this.mode = mode
    this.vueObj.modeChangeBtnLabel = btnEditIcon
    this.vueObj.modeChangeBtnText = 'クリックで編集モード'
    this.vueObj.modeStatus = '今は投稿モードです'
    this.vueObj.modeChange = this.changeModeAction

    //this.modeBtn = document.querySelector('.modeChangeBtn')
    //this.modeBtn.addEventListener('click', this.changeModeAction, false)
    /*this.modeBtn = document.querySelector('.modeChangeBtn')
    
    if(!this.modeBtn) {
      let h1 = document.querySelector('.l-wrapper h1')
      this.modeBtn = document.createElement('button')
      h1.after(this.modeBtn)
      this.modeBtn.addEventListener('click', this.changeModeAction, false)
    }

    this.mode = mode
    this.modeBtn.classList = 'c-button -' + this.mode
    this.modeBtn.innerText = btnText*/
  }

  /*
    送信ボタン
  **/
  createSendButton() {
    this.submitButtonLabel = 'データを更新する'

    this.sendBtn = document.querySelector('#jsModifyBtn')
    
    if(!this.sendBtn) {
      this.sendBtnArea = document.createElement('div')
      this.sendBtnArea.id = 'modifyBtnArea'

      this.sendBtn = document.createElement('button')
      this.sendBtn.id = 'jsModifyBtn'

      this.sendBtnArea.append(this.sendBtn)
      this.formAp.append(this.sendBtnArea)
      this.sendBtn.addEventListener('click', this.sendDataAction, false)
    }

    this.sendBtn.classList = 'c-button'
    this.sendBtn.innerText = this.submitButtonLabel
  }

  /*
    削除ボタン
  **/
  createDeleteButton() {
    this.delButtonLabel = 'この投稿データを削除する'

    this.delBtn = document.querySelector('#jsDeleteBtn')
    
    if(!this.delBtn) {
      this.delBtnArea = document.createElement('div')
      this.delBtnArea.id = 'deleteBtnArea'

      this.delBtn = document.createElement('button')
      this.delBtn.id = 'jsDeleteBtn'

      this.delBtnArea.append(this.delBtn)
      this.postIDSearchBoxArea.append(this.delBtnArea)
      this.delBtn.addEventListener('click', this.deleteDataAction, false)
    }

    this.delBtn.classList = 'c-button'
    this.delBtn.innerText = this.delButtonLabel
  }



  /*
   投稿ID検索フォーム
  **/
  addSearchBox() {
    this.postIDSearchBoxArea = document.querySelector('#postIDSearchBoxArea')
    
    if(!this.postIDSearchBoxArea) {
      this.areaHeight = this.formAp.scrollHeight
      this.formAp.style.maxHeight = this.areaHeight + 'px'

      this.postIDSearchBoxArea = document.createElement('div')
      this.postIDSearchBoxArea.classList = 'c-box'
      this.postIDSearchBoxArea.id = 'postIDSearchBoxArea'

      let postIDSearchBox = document.createElement('div')
      postIDSearchBox.id = 'postIDSearchBox'
      postIDSearchBox.classList = 'c-column'

      let label = document.createElement('label')
      label.for = 'postIDSearch'
      label.innerText = '投稿IDで編集対象のデータを検索'

      this.input = document.createElement('input')
      this.input.type = 'text'
      this.input.id = 'postIDSearch'
      this.input.name = 'postIDSearch'
      this.input.placeholder = 'POST IDを入力してください'
      this.input.dataset.postid = ''

      this.postIDSearchBoxArea.append(postIDSearchBox)
      postIDSearchBox.append(label)
      postIDSearchBox.append(this.input)

      this.postIDSearchBoxArea.append(postIDSearchBox)

      this.formAp.parentNode.insertBefore(this.postIDSearchBoxArea, this.formAp)
      //this.formAp.prepend(this.postIDSearchBoxArea)

      this.formAp.classList.add('data-edit-area')
  
      this.input.addEventListener('input', this.searchPOSTID.bind(this), false)

      setTimeout(() => {
        this.formAp.style.maxHeight = '0px'
      }, 50)
    }
  }

  /*
   案件IDチェックフォーム
  **/
  addSubjectsIDBox(subjectsID) {

    this.subjectsID = subjectsID
    this.subjectsIDBoxArea = document.querySelector('#subjectsIDBoxArea')
    
    if(!this.subjectsIDBoxArea) {

      // ボックス構築
      this.subjectsIDBoxArea = document.createElement('div')
      this.subjectsIDBoxArea.classList = 'c-box'
      this.subjectsIDBoxArea.id = 'subjectsIDBoxArea'

      let subjectsIDBox = document.createElement('div')
      subjectsIDBox.id = 'subjectsIDBox'
      subjectsIDBox.classList = 'c-column'

      let label = document.createElement('label')
      label.for = 'subjectsIDBox'
      label.innerText = '下記の投稿情報を別案件IDに変更する'

      let originID = document.createElement('p')
      originID.id = 'originSubjectsID'
      originID.innerText = '元のID: ' + this.subjectsID

      let message = document.createElement('p')
      message.classList = 'editSubjectsIDMessage c-notice'
      message.innerText = `変更する案件IDを入力後、"案件IDをチェックする"を必ずクリックしてください。`      

      // インプット構築
      this.subjectsIDInput = document.createElement('input')
      this.subjectsIDInput.type = 'text'
      this.subjectsIDInput.id = 'editSubjectsID'
      this.subjectsIDInput.name = 'editSubjectsID'
      this.subjectsIDInput.placeholder = '半角英数と_-で入力'
      this.subjectsIDInput.value = subjectsID

      // ボックスに追加
      subjectsIDBox.append(label)
 
      subjectsIDBox.append(this.subjectsIDInput)
      subjectsIDBox.append(originID)
      subjectsIDBox.append(message)

      this.subjectsIDBoxArea.append(subjectsIDBox)

      // ボタン構築
      let chkBtnArea = document.createElement('div')
      chkBtnArea.id = 'checkBtnArea'
      chkBtnArea.classList = 'c-column'

      let chkBtn = document.createElement('button')
      chkBtn.id = 'jsCheckBtn'
      chkBtn.classList = 'c-button'
      chkBtn.innerText = '案件IDをチェックする'
      chkBtn.addEventListener('click', this.checkSubjectsIDAction, false)
      chkBtnArea.append(chkBtn)

      // ボックスに追加
      this.subjectsIDBoxArea.append(chkBtnArea)


      // 案件情報表示ボックス構築
      this.subjectInfoBox = document.createElement('div')
      this.subjectInfoBox.classList = 'c-form'
      this.subjectInfoBox.id = 'subjectInfoBox'
      this.subjectInfoBox.style.display = 'none'

      let infoLabel = document.createElement('label')
      infoLabel.for = 'subjectInfoBox'
      infoLabel.innerText = '案件情報を確認'

      // 情報表示ボックス
      this.subjectInfo = document.createElement('textarea')
      this.subjectInfo.id = 'subjectInfo'
      this.subjectInfo.name = 'subjectInfo'
      this.subjectInfo.classList = 'c-textarea'
      this.subjectInfo.readOnly = true
      this.subjectInfo.style.minHeight = '210px'

      /*
      // ボタン構築
      let confirmBtnArea = document.createElement('div')
      confirmBtnArea.id = 'confirmBtnArea'

      let confirmBtn = document.createElement('button')
      confirmBtn.id = 'jsConfirmBtn'
      confirmBtn.classList = 'c-button'
      confirmBtn.innerText = '変更した案件IDで確定する'
      confirmBtn.addEventListener('click', this.confirmSubjectsIDAction, false)
      confirmBtnArea.append(confirmBtn)*/

      // ボックスに追加
      this.subjectInfoBox.append(infoLabel)
      this.subjectInfoBox.append(this.subjectInfo)
      //this.subjectInfoBox.append(confirmBtn)

      // ボックスに追加
      this.subjectsIDBoxArea.append(this.subjectInfoBox)

      // フォームに追加
      this.formAp.parentNode.insertBefore(this.subjectsIDBoxArea, this.formAp)
    }
  }

  /*
   投稿ID検索アクション
  **/
  async searchPOSTID(e) {
    const $ = this.$

    this.postID = this.input.value.trim()
    let before = this.input.dataset.postid || ''
    let textCount = 16
    let reg = '^[0-9]{6}_[0-9]{9}$'

    if(this.postID.indexOf('tn-post') > -1) {
      textCount = 23
      reg = '^[0-9]{6}_tn-post-[a-z0-9]{8}$'
    } 

    if(this.postID.indexOf('sg-post') > -1) {
      textCount = 27
      reg = '^[0-9A-Z]{11}_sg-post[a-z0-9]{8}$'
    }

    reg = new RegExp(reg)

    this.isGetResult = false

    if(this.postID.length < textCount || (before && before != this.postID) || (e.inputType && e.inputType.indexOf("delete") == 0)) {
      //$('#status_comment').text('')
      this.input.dataset.postID = ''
      before = ""

      this.formClear()
      this.input.value = this.postID
      if(this.subjectsIDBoxArea) this.subjectsIDBoxArea.remove()

      return false
    }

    const result = this.postID.match(reg)

    if(!result) return false

    this.isGetResult = true
    
    // 入力されたら
    this.vueObj.viewLoader('検索中...🔎 少々お待ちください😣')

    // 空白文字検知用
    const reg1 = /^[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]|[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]$/

    const reg2 = /[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]/g

    this.input.readonly = true

    if(reg1.test(this.postID)) {
      this.postID = this.postID.replace(reg2, "")
    }

    //document.querySelector('#submit').classList.add('disabled')

    // 案件ID検索APIに投げる
    console.log(new Date())
    
    this.formClearFlag = 1
    this.formAp.reset()
    this.input.value = this.postID

    // 各フォームに合わせたデータを取ってくる
    this.getDataForEditClass = new getDataForEdit(this.postID, this)
    const data = await this.getDataForEditClass.getDataProccess()

    if(!data || data.length == 0) {
      this.formClear()
      alert(this.postID + 'のデータがありません')
      this.vueObj.viewLoader()
      return
    }

    this.formOpen()
    
    this.createDeleteButton()

    this.getDataForEditClass.applyDataToForm()

    this.vueObj.viewLoader()
    
    return 
  }

  /*
   案件IDチェック
  **/
  async checkSubjectsID(e) {

    this.changedSubjectsID = this.subjectsIDInput.value
    this.vueObj.viewLoader('案件ID検索中...')
    const data = await this.getDataForEditClass.checkSubjectsID(this.changedSubjectsID)

    this.vueObj.viewLoader()

    // データなし
    if(data.length == 0) {
      this.subjectsIDInput.value = this.subjectsID
      alert(`案件ID「${this.changedSubjectsID}」に該当するデータは存在しませんでした。\n案件IDは元に戻されました。`)
      return false
    }

    // データあり
    // 曜日追加
    let WeekChars = [ "日", "月", "火", "水", "木", "金", "土" ]
    let negoDate = ''
    if(data[0].negotiation_date) {
      negoDate = data[0].negotiation_date + "（" + WeekChars[new Date(data[0].negotiation_date).getDay()]+"）"
    }

    const subjectInfoText = `案件ID: ${this.changedSubjectsID}
案件名: ${data[0].name}
住所: ${data[0].company_address}
フック: ${data[0].hook}
提案内容: ${data[0].suggestion}
業種カテゴリ: ${data[0].categories_name}
業種: ${data[0].sub_cates_name}
商談日時: ${negoDate}
開始時間: ${data[0].started_at}`

    this.subjectInfoBox.style.display = 'block'
    this.subjectInfo.value = subjectInfoText
  }

  /*
   データ送信アクション
  **/
  async sendData(e) {
    e.preventDefault()

    this.vueObj.viewLoader('データ送信処理中')
    console.log('データ送信処理中')

    let message = 'データ送信完了'
    let errorObj = []

    try {
      this.vueObj.viewLoader('クエリ構築中')
      await this.getDataForEditClass.buildQuery()
    } catch(e) {
      message = 'クエリ構築に失敗しました'
      errorObj.push({name: 'buildQuery', errorObj: e})
      alert(message)
      console.error(message, e)
      //location.reload()
      return false
    }

    try {
      this.vueObj.viewLoader('該当データ更新中')
      await this.getDataForEditClass.updateDB()
    } catch(e) {
      message = 'DB更新に失敗しました'
      errorObj.push({name: 'updateDB', errorObj: e})
      alert(message)
      console.error(message, e)
      //location.reload()
      return false
    }

    try {
      this.vueObj.viewLoader('該当データ登録中')
      await this.getDataForEditClass.insertDB()
    } catch(e) {
      message = 'DB追加に失敗しました'
      errorObj.push({name: 'insertDB', errorObj: e})
      alert(message)
      console.error(message, e)
      //location.reload()
      return false
    }

    try {
      this.vueObj.viewLoader('スプレッドシートの該当データ更新中')
      await this.getDataForEditClass.updateSheet()
    } catch(e) {
      message = 'スプレッドシート更新に失敗しました'
      errorObj.push({name: 'updateSheet', errorObj: e})
      alert(message)
      console.error(message, e)
      //location.reload()
      return false
    }

    try {
      this.vueObj.viewLoader('スプレッドシートに該当データ追加中')
      await this.getDataForEditClass.insertSheet()
    } catch(e) {
      message = 'スプレッドシート追加に失敗しました'
      errorObj.push({name: 'insertSheet', errorObj: e}) 
      alert(message)
      console.error(message, e)
      //location.reload()
      return false
    }

    try {
      if (this.vueObj.groupTitle == "申込書" || this.vueObj.groupTitle == "demo_申込") {
        await this.getDataForEditClass.deleteKintoneData()
        await this.getDataForEditClass.insertKintoneData()
      }
    } catch(e) {
      message = 'kintoneデータ更新に失敗しました'
      errorObj.push({name: 'updateKintoneData', errorObj: e}) 
    }

    this.vueObj.viewLoader(message)
    
    setTimeout(() => {

      this.vueObj.viewLoader()
      document.getElementById('jsModifyBtn').classList.remove('pulse')

      let completeTitle = ''
      if(errorObj.length > 0) {
        completeTitle = '更新失敗'
        message = 'エラーが発生しました'
      } else {
        completeTitle = '更新成功'
        message = `POST ID:「${this.postID}」の投稿データを更新しました`
      }

      // 完了画面表示
      const params = {
        groupTitle: this.vueObj.groupTitle,
        groupURL: '',
        searchURL: '',
        calURL: '',
        message: message,
        completeTitle: completeTitle
      }

      this.vueObj.viewComplete(params)

    }, 1000)

    console.log(message, errorObj)

    return false
  }

  /*
   データ削除アクション
  **/
  async deleteData() {
    let errorObj = []
    let message = 'データ削除完了'

    if(confirm(`投稿ID: ${this.postID}\nこのIDに関連するデータを削除しますか？`)) {
      this.vueObj.viewLoader('データ削除中')
      console.log('データ削除中')

      try {
        this.vueObj.viewLoader('クエリ構築中')
        await this.getDataForEditClass.buildDeleteQuery()
      } catch(e) {
        message = 'クエリ構築に失敗しました'
        errorObj.push({name: 'buildDeleteQuery', errorObj: e})
      }

      try {
        await this.getDataForEditClass.deleteDBData()
      } catch(e) {
        message = 'DBデータ削除に失敗しました'
        errorObj.push({name: 'deleteDBData', errorObj: e}) 
      }

      try {
        await this.getDataForEditClass.deleteSheetData()
      } catch(e) {
        message = 'スプレッドシートデータ削除に失敗しました'
        errorObj.push({name: 'deleteSheetData', errorObj: e}) 
      }

      try {
        if (this.vueObj.groupTitle == "申込書" || this.vueObj.groupTitle == "demo_申込") {
          await this.getDataForEditClass.deleteKintoneData()
        }
      } catch(e) {
        message = 'kintoneデータ削除に失敗しました'
        errorObj.push({name: 'deleteKintoneData', errorObj: e}) 
      }

      this.vueObj.viewLoader(message)
      
      let completeTitle = ''
      setTimeout(() => {
        this.vueObj.viewLoader()

        if(errorObj.length > 0) {
          completeTitle = '削除失敗'
          message = 'エラーが発生しました'
        } else {
          completeTitle = '削除成功'
          message = `POST ID:「${this.postID}」の投稿データを削除しました`
        }
        
        // 完了画面表示
        const params = {
          groupTitle: this.vueObj.groupTitle,
          groupURL: '',
          searchURL: '',
          calURL: '',
          message: message,
          completeTitle: completeTitle
        }
        this.vueObj.viewComplete(params)
      }, 1000)
      console.log(message, errorObj)
    }
  }

  /*
   フォームクリア
  **/
  formClear() {
    const $ = this.$

    this.formAp.style.maxHeight = '0px'
    //this.formAp.reset()

    this.vueObj.editableItems = {}

    $('[name="tel"]').val('')
    $('[name="input_other_tel"]').val('')
    $('[name="hp_url"]').val('')

    const copyEle = document.querySelectorAll('.copyEle, .copy_form_tel1')
    if(copyEle.length > 1) {
      copyEle.forEach((elm, i) => {
        $('#f_tel .btn_del').click()
      })
    } 

    const copyOtherEle = document.querySelectorAll('.copyEle, .copy_form_other_tel1')
    if(copyOtherEle.length > 1) {
      copyOtherEle.forEach((elm, i) => {
        $('#f_other_tel .btn_del').click()
      })
    } 
    
    const urls = document.querySelector('#add_del_input_url1')
    if(urls) urls.style.display = 'none'

    const noneDisplayElements = document.querySelectorAll('.noneDisplay')
    noneDisplayElements.forEach((element) => {
      element.style.display = 'none'
    })

    const errorMsgTel1 = document.querySelectorAll('.error_msg_tel1')
    if(errorMsgTel1.length > 1) errorMsgTel1.forEach(elm => elm.remove())

    const radio = document.querySelectorAll('input[type="radio"]')
    if(radio.length > 0) {
      radio.forEach((elm) => {
        elm.checked = false
      })
    }

    const chk = document.querySelectorAll('input[type="checkbox"]')
    if(chk.length > 0) {
      chk.forEach((elm) => {
        elm.checked = false
      })
    }

    if(this.delBtnArea) this.delBtnArea.remove()
  }

  // 必須項目設定
  checkRequired() {
    const $ = this.$

    let key = ""
    let requiredArray = {}
    const required_dom = $('.js-editable .required')
    const bg_color = '#ffcdd2'
    const color = '#212121'

    $('#jsModifyBtn').addClass('disabled').removeClass('pulse')  
    required_dom.css({
      'background-color': bg_color,
      'color': color
    })
    // [形式チェック：商談住所]
    let format_flags = ""

    if($('#MtgAdd').prop('required')) {
      const trigger = $('[name="pref_name"]')
      const label_class = '.error_msg_pref'
      const label_tag = '<label class="error_msg_pref">※ 都道府県名から入力してください</label>'
      const insertbfr = $('[name="pref_name"]')
      format_flags = this.format_check_pref(trigger, label_class, label_tag, insertbfr)
    }

    for(let num = 0; num < required_dom.length; num++) {

      if(required_dom[num].type == 'radio' || required_dom[num].type == 'checkbox') {
        key = required_dom[num].name
        requiredArray[key] = 0
        const name_dom = $('[name="' + key + '"]')

        name_dom.next().css({
          'background-color': bg_color,
          'color': color
        })

        if(this.checkedVal(name_dom) != '') {
          requiredArray[key] = 1
          name_dom.next().css({'background-color': 'transparent'})
        }

      } else if(required_dom[num].classList.contains('chip')) {
        key = "ResuCanReasonCate"
        requiredArray[key] = 0
        const name_dom = $('[name=' + key + ']')
        name_dom.css({
          'background-color': bg_color,
          'color': color
        })

        if(this.checked_chip($('div.teal.lighten-2')) != '') {
          requiredArray[key] = 1
          name_dom.css({'background-color': '#e0e0e0'})
        }

      } else {
        key = required_dom[num].id
        requiredArray[key] = 0

        if(required_dom[num].value != '') {
          requiredArray[key] = 1
          required_dom[num].style.backgroundColor = '#ffffff'
        }
      }
    }

    return requiredArray
  }

  //必須項目を設定を外す関数(param.id)
  removeRequiredOp(idName) {
    const $ = this.$
    $(`#${idName}`).removeClass("required")
  }

  // 値取得 - チェックボックス
  checkedVal(checkbox_op) {
    let input_txt = ""
    
    for (let i = 0; i < checkbox_op.length; i++) {
    
      if( checkbox_op[i].checked ) input_txt += checkbox_op[i].value+"/"
    
    }
    return input_txt.slice(0,-1)
  }
  
  format_check_pref(trigger,label_class,label_tag,insertbfr) {
    const $ = this.$

    let format_flag = "", format_flags = ""

    if(trigger.prev('label').length > 0) {
      trigger.prev('label').remove()
    }

    for(let i = 0; i < trigger.length; i++) { 
      if(trigger.get(i).validationMessage == "" && (trigger[i].value.startsWith("北海道") != -1 || trigger[i].value.startsWith("東京都") != -1 || trigger[i].value.startsWith("大阪府") != -1 || trigger[i].value.startsWith("京都府") != -1)) {
        format_flag += "1"
      } else if(trigger.get(i).validationMessage == "") {
        let pref_ken = trigger[i].value.split('県')
        let pref_list = ["青森", "岩手", "宮城", "秋田", "山形", "福島", "茨城", "栃木", "群馬", "埼玉", "千葉", "神奈川", "新潟", "富山", "石川", "福井", "山梨", "長野", "岐阜", "静岡", "愛知", "三重", "滋賀", "兵庫", "奈良", "和歌山", "鳥取", "島根", "岡山", "広島", "山口", "徳島", "香川", "愛媛", "高知", "福岡", "佐賀", "長崎", "熊本", "大分", "宮崎", "鹿児島", "沖縄"]

        if(pref_list.find((v) => v === pref_ken[0]) == undefined) {
          format_flag += "0"
          $(label_tag).insertBefore(insertbfr[i])
        } else {
          format_flag += "1"
        }

      } else {
        format_flag += "0"
        $(label_tag).insertBefore(insertbfr[i])
      }
    }

    if(format_flag.indexOf('0') == -1) {
      format_flags += "1"
    } else {
      format_flags += "0"
    }

    return format_flags
  }
}

