
/*
* ぽちボタンクリック後の処理
*/ 

class afrContactInfo {
  constructor(that) {
    this.that = that
    this.formName = "連絡先情報"
    this.that.$('.search_form_wrap').show()
    this.actionInit()
  }

  returnFormName() {
    return this.formName
  }

  getInputData () {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const func = this.that.func
    const progressYesNo = $('input[name="progress_yesNo"]:checked').val() ? $('input[name="progress_yesNo"]:checked').val() : ""

    // 値取得：連絡先情報
      store.commit('setValue', ['contactInfo', 'inputMainContact', $('[name="mainContact"]:checked').val(), ''])
      store.commit('setValue', ['contactInfo', 'inputContactComName', func.textEscape($('#contactComName').val()), 'contact_com_name'])
      store.commit('setValue', ['contactInfo', 'inputCompanyAddress', func.textEscape($('#companyAddress').val()), ''])
      store.commit('setValue', ['contactInfo', 'inputContactNameCEO', $("#contactNameCEO").val(), 'contact_ceo_name'])
      store.commit('setValue', ['contactInfo', 'inputContactNameCEOCana', $("#contactNameCEOCana").val(), 'contact_ceo_name'])
      store.commit('setValue', ['contactInfo', 'inputContactMailCEO', func.textEscape($('#contactMailCEO').val()), 'contact_mail'])
      store.commit('setValue', ['contactInfo', 'inputContactTelNumCEO', func.textEscape($('#contactTelNumCEO').val()), ''])
      store.commit('setValue', ['contactInfo', 'inputContactTelephoneCEO', func.textEscape($('#contactTelephoneCEO').val()), ''])
      store.commit('setValue', ['contactInfo', 'inputCanOnlineMtgCEO', $('[name="canOnlineMtgCEO_yesNo"]:checked').val(), ''])
      store.commit('setValue', ['contactInfo', 'inputSpecialMention', func.textEscape($('#specialMention').val()), ''])

      store.commit('setValue', ['contactInfo', 'inputContactNameStaff', func.textEscape($('#contactNameStaff').val()), 'contact_name'])
      store.commit('setValue', ['contactInfo', 'inputContactNameStaffCana', func.textEscape($('#contactNameStaffCana').val()), ''])
      store.commit('setValue', ['contactInfo', 'inputContactMailStaff', func.textEscape($('#contactMailStaff').val()), ''])
      store.commit('setValue', ['contactInfo', 'inputContactTelNumStaff', func.textEscape($('#contactTelNumStaff').val()), ''])
      store.commit('setValue', ['contactInfo', 'inputContactTelephoneStaff', func.textEscape($('#contactTelephoneStaff').val()), ''])
      store.commit('setValue', ['contactInfo', 'inputCanOnlineMtgStaffYesNo', $('[name="canOnlineMtgStaff_yesNo"]:checked').val(), ''])
      store.commit('setValue', ['contactInfo', 'inputCanMtgStaffYesNo', $('[name="canMtgStaff_yesNo"]:checked').val(), ''])
      store.commit('setValue', ['contactInfo', 'inputContactStaffRemarks', func.textEscape($('#contactStaffRemarks').val()), ''])

      store.commit('setValue', ['contactInfo', 'inputContactProgressYesNo', progressYesNo, 'progress_yesNo'])
  }

  setVal (balanceInfoMsg) {
    const $ = this.that.$
    const store = this.that.store
    const ProgressYesNoAddmessage = store.state.formData.contactInfo.inputContactProgressYesNo == "可能" ? "(進行スタート依頼の投稿は不要)" : ""
    let sendVal = 
`

◎連絡先情報
主要連絡先：${store.state.formData.contactInfo.inputMainContact}
社名：${store.state.formData.contactInfo.inputContactComName}
本社住所：${store.state.formData.contactInfo.inputCompanyAddress}
代表取締役者名：${store.state.formData.contactInfo.inputContactNameCEO}
代表取締役者名（カナ）：${store.state.formData.contactInfo.inputContactNameCEOCana}
代表メールアドレス：${store.state.formData.contactInfo.inputContactMailCEO}
代表電話番号（固定）：${store.state.formData.contactInfo.inputContactTelNumCEO}
代表電話番号（携帯）：${store.state.formData.contactInfo.inputContactTelephoneCEO}
WEB打ち合わせ可否：${store.state.formData.contactInfo.inputCanOnlineMtgCEO}
代表連絡先情報備考：${store.state.formData.contactInfo.inputSpecialMention}

`

    if ($("[name='mainContact']:checked").val() != "代表のみ") {
      sendVal += `
担当者名：${store.state.formData.contactInfo.inputContactNameStaff}
担当者名（カナ）：${store.state.formData.contactInfo.inputContactNameStaffCana}
担当メールアドレス：${store.state.formData.contactInfo.inputContactMailStaff}
担当電話番号（固定）：${store.state.formData.contactInfo.inputContactTelNumStaff}
担当電話番号（携帯）：${store.state.formData.contactInfo.inputContactTelephoneStaff}
WEB打ち合わせ可否：${store.state.formData.contactInfo.inputCanOnlineMtgStaffYesNo}
担当者の商談同席有無：${store.state.formData.contactInfo.inputCanMtgStaffYesNo}
担当連絡先情報備考：${store.state.formData.contactInfo.inputContactStaffRemarks}

`
    }

    sendVal += `
進行可否：${store.state.formData.contactInfo.inputContactProgressYesNo}
${ProgressYesNoAddmessage}
`

      store.commit('setTalknoteMessage', ['contactInfo', sendVal])

      const fieldCodeContactInfoBasis = {
        "会社名": {"value": store.state.formData.contactInfo.inputContactComName},
        "主要連絡先": {"value": store.state.formData.contactInfo.inputMainContact},
        "代表取締役名": {"value": store.state.formData.contactInfo.inputContactNameCEO},
        "代表取締役名_カナ": {"value": store.state.formData.contactInfo.inputContactNameCEOCana},
        "代表メールアドレス": {"value": store.state.formData.contactInfo.inputContactMailCEO},
        "代表電話番号_固定": {"value": store.state.formData.contactInfo.inputContactTelNumCEO},
        "代表電話番号_携帯": {"value": store.state.formData.contactInfo.inputContactTelephoneCEO},
        "WEB打ち合わせ_代表": {"value": [store.state.formData.contactInfo.inputCanOnlineMtgCEO]},
        "代表連絡先情報備考": {"value": store.state.formData.contactInfo.inputSpecialMention},
        "進行可否": {"value": store.state.formData.contactInfo.inputContactProgressYesNo},
      }

      const fieldCodeContactInfoStaff = {
        "担当者名": {"value": store.state.formData.contactInfo.inputContactNameStaff},
        "担当者名_カナ": {"value": store.state.formData.contactInfo.inputContactNameStaffCana},
        "担当メールアドレス": {"value": store.state.formData.contactInfo.inputContactMailStaff},
        "担当電話番号_固定": {"value": store.state.formData.contactInfo.inputContactTelNumStaff},
        "担当電話番号_携帯": {"value": store.state.formData.contactInfo.inputContactTelephoneStaff},
        "WEB打ち合わせ_担当": {"value": [store.state.formData.contactInfo.inputCanOnlineMtgStaffYesNo]},
        "担当連絡先情報備考": {"value": store.state.formData.contactInfo.inputContactStaffRemarks},
        "担当者の商談同席有無": {"value": [store.state.formData.contactInfo.inputCanMtgStaffYesNo]},
      }

      let fieldCodeContactInfo = { ...fieldCodeContactInfoBasis }

      if ($("[name='mainContact']:checked").val() != "代表のみ") {
        fieldCodeContactInfo = { ...fieldCodeContactInfo, ...fieldCodeContactInfoStaff }
      }

      store.commit('setValue', ['contactInfo', 'contactInfoFieldCode', fieldCodeContactInfo, ''])
  }

  actionInit () {
    const $ = this.that.$
    $("#form_ap").on("click", '[name="mainContact"]', function() {
      const selectMainContact = $(this).val()
      if (selectMainContact == "代表のみ") {
        $("#contactStaff").hide()
        $(".contactStaff").val("").removeClass("required").prop('checked', false)
        $(".contactStaff_radio").removeClass("required").prop('checked', false)
      } else {
        $("#contactStaff").show()
        $(".contactStaff, .contactStaff_radio").addClass("required")
      }
    })

    $("#form_ap").on("click", '[name="canOnlineMtgStaff_yesNo"]', function() {
      $("[name='canOnlineMtgStaff_yesNo']").removeClass("required")
      $(".canOnlineMtgStaffSpan").css("background-color", "transparent")
    })

    $("#form_ap").on("click", '[name="canMtgStaff_yesNo"]', function() {
      $("[name='canMtgStaff_yesNo']").removeClass("required")
      $(".canMtgStaffSpan").css("background-color", "transparent")
    })

  }

}

export default {
  afrContactInfo 
}